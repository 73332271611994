import { all, call, delay, spawn } from 'redux-saga/effects';
import {createCompanyFlow} from './sagas/company/createCompanyFlow';
import {registerNewCompanyFlow} from './sagas/company/registerNewCompanyFlow';
import {fetchCompanyListFlow} from './sagas/company/fetchCompanyListFlow';
import {assignCompanyEmployeeFlow} from './sagas/company/assignCompanyEmployeeFlow';
import {unassignCompanyEmployeeFlow} from './sagas/company/unassignCompanyEmployeeFlow';
import {updateBasicCompanyDataFlow} from './sagas/company/updateBasicCompanyDataFlow';
import {fetchSelfAssessmentFlow} from './sagas/selfAssessment/fetchSelfAssessmentFlow';
import {startSelfAssessmentFlow} from './sagas/selfAssessment/startSelfAssessmentFlow';
import {answerSelfAssessmentQuestionFlow} from './sagas/selfAssessment/answerSelfAssessmentQuestionFlow';
import {finalizeSelfAssessmentFlow} from './sagas/selfAssessment/finalizeSelfAssessmentFlow';
import {reopenSelfAssessmentFlow} from './sagas/selfAssessment/reopenSelfAssessmentFlow';
import {fetchWebsiteCheckFlow} from './sagas/websiteCheck/fetchWebsiteCheckFlow';
import {startWebsiteCheckFlow} from './sagas/websiteCheck/startWebsiteCheckFlow';
import {updateWebsiteCheckDataFlow} from './sagas/websiteCheck/updateWebsiteCheckDataFlow';
import {finalizeWebsiteCheckFlow} from './sagas/websiteCheck/finalizeWebsiteCheckFlow';
import {reopenWebsiteCheckFlow} from './sagas/websiteCheck/reopenWebsiteCheckFlow';
import {fetchMysteryTestFlow} from './sagas/mysteryTest/fetchMysteryTestFlow';
import {startMysteryTestFlow} from './sagas/mysteryTest/startMysteryTestFlow';
import {updateMysteryTestDataFlow} from './sagas/mysteryTest/updateMysteryTestDataFlow';
import {finalizeMysteryTestFlow} from './sagas/mysteryTest/finalizeMysteryTestFlow';
import {reopenMysteryTestFlow} from './sagas/mysteryTest/reopenMysteryTestFlow';
import {setCompanyTesterFlow} from './sagas/company/setCompanyTesterFlow';
import {fetchCompanyStatusListFlow} from './sagas/company/fetchCompanyStatusListFlow';
import {enqueueNotificationFlow} from './sagas/notification/enqueueNotificationFlow';
import {removeCompanyTesterFlow} from './sagas/company/removeCompanyTesterFlow';
import {setTrustYouScoreFlow} from './sagas/trustYou/setTrustYouScoreFlow';
import {updateTrustYouScoreFlow} from './sagas/trustYou/updateTrustYouScoreFlow';
import {fetchUserFlow} from './sagas/user/fetchUserFlow';
import {fetchTestListFlow} from './sagas/QuestionList/fetchTestListsFlow';
import {fetchQuestionConfigurationFlow} from './sagas/QuestionList/fetchQuestionConfigurationFlow';
import {submitMysteryTestHotelChangesFlow} from './sagas/QuestionList/submitMysteryTestHotelChangesFlow';
import {startKeepAliveFlow} from './sagas/user/startKeepAliveFlow';
import {stopKeepAliveFlow} from './sagas/user/stopKeepAliveFlow';
import {importMysteryTestQuestionFlow} from './sagas/QuestionList/importMysteryTestQuestionsFlow';
import {startNewMysteryTestAttemptFlow} from './sagas/mysteryTest/startNewMysteryTestAttemptFlow';

/**
 * Prevents the root saga from terminating entirely due to some error in another saga
 *
 * @param saga
 */
const makeRestartable = (saga: any) => {
    return function*() {
        yield spawn(function*() {
            while (true) {
                try {
                    yield call(saga);

                    /* eslint-disable-next-line no-console */
                    console.error(
                        'Unexpected root saga termination. The root sagas should live during the whole app lifetime!',
                        saga,
                    );
                } catch (e) {
                    /* eslint-disable-next-line no-console */
                    console.error('Saga error, the saga will be restarted', e);
                }
                yield delay(1000); // Workaround to avoid infinite error loops
            }
        });
    };
};

const sagas: any[] = [
    createCompanyFlow,
    registerNewCompanyFlow,
    fetchCompanyListFlow,
    fetchCompanyStatusListFlow,
    assignCompanyEmployeeFlow,
    unassignCompanyEmployeeFlow,
    updateBasicCompanyDataFlow,
    setCompanyTesterFlow,
    removeCompanyTesterFlow,

    startSelfAssessmentFlow,
    fetchSelfAssessmentFlow,
    answerSelfAssessmentQuestionFlow,
    finalizeSelfAssessmentFlow,
    reopenSelfAssessmentFlow,

    fetchWebsiteCheckFlow,
    startWebsiteCheckFlow,
    updateWebsiteCheckDataFlow,
    finalizeWebsiteCheckFlow,
    reopenWebsiteCheckFlow,

    fetchMysteryTestFlow,
    startMysteryTestFlow,
    updateMysteryTestDataFlow,
    finalizeMysteryTestFlow,
    reopenMysteryTestFlow,
    startNewMysteryTestAttemptFlow,

    setTrustYouScoreFlow,
    updateTrustYouScoreFlow,

    enqueueNotificationFlow,

    fetchUserFlow,

    fetchTestListFlow,
    fetchQuestionConfigurationFlow,
    submitMysteryTestHotelChangesFlow,
    importMysteryTestQuestionFlow,

    startKeepAliveFlow,
    stopKeepAliveFlow,

].map(makeRestartable);

export default function* rootSaga() {
    /* eslint-disable-next-line no-console */
    console.log('Root saga started');

    yield all(sagas.map((saga: any) => call(saga)));
};
