import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, ListItemText,
    MenuItem, OutlinedInput,
    Select, SelectChangeEvent, Switch,
    TextField, Tooltip,
    Typography,
} from '@mui/material';
import {theme} from '../../../mui/theme';
import {Answer, AnswerGroup} from '../../../util/dataTypes';
import AnswerEdit from './AnswerEdit';
import {useDispatch} from 'react-redux';
import Event from '../../../state/actions/event';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForever from '@mui/icons-material/DeleteForever';
import DeleteDialog from '../common/DeleteDialog';

interface QuestionEditProps {
    mainCategoryId: string;
    subCategoryId: string;
    questions: AnswerGroup[];
    question: AnswerGroup;
    type: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const locationTypes: string[] = [
    'restaurant',
    'countryGuesthouse',
    'excursionRestaurant',
    'pub',
    'cafe',
];

const locationTypesNames: any = {
    restaurant: 'Restaurant',
    countryGuesthouse: 'Landgasthof',
    excursionRestaurant: 'Ausflugslokal',
    pub: 'Kneipe / Bar',
    cafe: 'Café / Eisdiele',
    hotel: 'Hotel garni / Pension',
};

const styles = {
    wrapper: {
        boxSizing: 'border-box',
    },
    question: {
        headline: {
            fontStyle: 'bold',
        },
    },
    step: {
        stepLabel: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
                width: '80%',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
                width: '70%',
            },
        },
    },
    categoryWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionDividerQuestions: {
        marginTop: '20px',
        paddingTop: '20px',
        marginBottom: '10px',
        borderTop: '1px solid #cdcdcd',
        fontWeight: 'bold',
    },
    sectionDividerAnswers: {
        marginTop: '20px',
        marginBottom: 1,
        fontWeight: 'bold',
    },
    questionField: {
        marginTop: 2.5,
        width: 'calc(100% - 110px)',
    },
    formInputLabel: {
        backgroundColor: 'white',
        padding: '0 5px',
        position: 'absolute',
        left: '-5px',
    },
    answerType: {
        marginTop: 2.5,
        marginLeft: 2,
        width: '80px',
    },
    questionTitleWrapper: {
        position: 'relative',
        display: 'flex',
        gap: 'auto',
    },
    questionButtonWrapper: {
        position: 'absolute',
        right: '0px',
        display: 'flex',
    },
    iconButton: {
        width: '33%',
    },
    formGroup: {
        width: 'fit-content',
    },
    questionOptionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
};

const QuestionEdit = (props: QuestionEditProps) => {
    const dispatch = useDispatch();

    const [deleteInfoOpen, setDeleteInfoOpen] = useState<boolean>(false);
    const [questionVisibleFor, setQuestionVisibleFor] = React.useState<string[]>(
        props.question.answers![0] ? props.question.answers![0].visibleFor! :
            props.type === 'hotel' ? ['hotel'] : [],
    );

    const [question, setQuestion] = useState<string>(props.question.label);

    const deleteQuestion = () => {
        dispatch(Event.QuestionList.mysteryTestHotelQuestionDeleted({
            id: props.question._id,
            parentId: props.subCategoryId,
        }));
    };

    const moveQuestionUp = () => {
        dispatch(Event.QuestionList.mysteryTestHotelQuestionMovedUp({
            id: props.question._id,
            parentId: props.subCategoryId,
            parentParentId: props.mainCategoryId,
        }));
    };

    const moveQuestionDown = () => {
        dispatch(Event.QuestionList.mysteryTestHotelQuestionMovedDown({
            id: props.question._id,
            parentId: props.subCategoryId,
            parentParentId: props.mainCategoryId,
        }));
    };

    const addAnswer = () => {
        dispatch(Event.QuestionList.mysteryTestHotelAnswerAdded({
            parentId: props.question._id,
            type: props.type,
        }));
        dispatch(Event.QuestionList.mysteryTestHotelQuestionVisibleForChanged({
            id: props.question._id,
            valueArray: questionVisibleFor,
        }));
    };

    let dependencyAnswers: Answer[] = [];
    props.questions.forEach(question => {
        if(props.question.dependency.on === question._id) {
            if(question.answers) {
                dependencyAnswers = question.answers;
            }
        }
    });

    const handleVisibileForChange = (event: SelectChangeEvent<typeof locationTypes>) => {
        const {
            target: { value },
        } = event;
        setQuestionVisibleFor(
            typeof value === 'string' ? value.split(',') : value,
        );
        dispatch(Event.QuestionList.mysteryTestHotelQuestionVisibleForChanged({
            id: props.question._id,
            valueArray: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const answerList = props.question.answers?.map((answer: any) =>
        <AnswerEdit
            key={answer._id}
            answer={answer}
            mainCategoryId={props.mainCategoryId}
            subCategoryId={props.subCategoryId}
            questionId={props.question._id}
        />,
    );

    useEffect(() => {
        setQuestion(props.question.label);
    }, [props.question.label]);

    return (
        <Box sx={styles.wrapper}>
            <Typography variant={'body1'} sx={styles.sectionDividerQuestions}>
                <Box sx={styles.questionTitleWrapper}>
                    Frage:
                    <Box sx={styles.questionButtonWrapper}>
                        <Tooltip title={'Frage nach oben verschieben'}>
                            <IconButton
                                sx={styles.iconButton}
                                onClick={moveQuestionUp}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Frage nach unten verschieben'}>
                            <IconButton
                                sx={styles.iconButton}
                                onClick={moveQuestionDown}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={'Frage löschen'}>
                            <IconButton
                                sx={styles.iconButton}
                                onClick={() => {setDeleteInfoOpen(true);}}
                            >
                                <DeleteForever />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Typography>

            <TextField
                key={props.question._id}
                label={'Frage'}
                variant={'outlined'}
                fullWidth={true}
                size={'small'}
                sx={styles.questionField}
                value={question}
                onChange={event => {setQuestion(event.target.value);}}
                onBlur={event => {
                    dispatch(Event.QuestionList.mysteryTestHotelQuestionChanged({
                        id: props.question._id,
                        label: event.target.value,
                    }));
                }}
            />

            <FormControl
                sx={styles.answerType}
            >
                <InputLabel id={'type-multiple-checkbox-label'} sx={styles.formInputLabel}>
                    Type
                </InputLabel>
                <Select
                    value={props.question.answer_type}
                    size={'small'}
                    onChange={event => {
                        dispatch(Event.QuestionList.mysteryTestHotelQuestionTypeChanged({
                            id: props.question._id,
                            type: event.target.value,
                        }));
                    }}
                >
                    <MenuItem value={'radio'}>Radio</MenuItem>
                    <MenuItem value={'checkbox'}>Checkbox</MenuItem>
                    <MenuItem value={'textbox'}>Textbox</MenuItem>
                </Select>
            </FormControl>

            <FormControl
                sx={styles.questionField}
            >
                <InputLabel id={'type-multiple-checkbox-label'} sx={styles.formInputLabel}>
                    Abhängige Frage
                </InputLabel>
                <Select
                    variant={'outlined'}
                    size={'small'}
                    value={props.question.dependency.on === '' ? 'keine_Auswahl' : props.question.dependency.on}
                    displayEmpty={true}
                    onChange={(event) => {
                        dispatch(Event.QuestionList.mysteryTestHotelQuestionDependencyQuestionChanged({
                            id: props.question._id,
                            questionId: event.target.value,
                        }));
                    }}
                >
                    <MenuItem value={'keine_Auswahl'}>keine Auswahl</MenuItem>
                    {props.questions
                        .filter(question => question._id !== props.question._id)
                        .map((question: AnswerGroup) =>
                            <MenuItem key={question._id} value={question._id}>
                                {question.label}
                            </MenuItem>,
                        )}
                </Select>
            </FormControl>

            <FormControl
                sx={styles.answerType}
            >
                <InputLabel id={'type-multiple-checkbox-label'} sx={styles.formInputLabel}>
                    Antwort
                </InputLabel>
                <Select
                    size={'small'}
                    value={props.question.dependency.value}
                    displayEmpty={true}
                    onChange={event => {
                        dispatch(Event.QuestionList.mysteryTestHotelQuestionDependencyValueChanged({
                            id: props.question._id,
                            valueId: event.target.value,
                        }));
                    }}
                >
                    {dependencyAnswers?.map((answer) => (
                        <MenuItem key={answer._id} value={answer._id}>
                            {answer.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Box sx={styles.questionOptionWrapper}>
                <FormGroup sx={styles.formGroup}>
                    <FormControlLabel
                        sx={{marginRight: 0, paddingRight: '7px'}}
                        control={
                            <Switch
                                checked={props.question.required}
                                onChange={event => {
                                    dispatch(Event.QuestionList.mysteryTestHotelQuestionRequiredChanged({
                                        id: props.question._id,
                                        required: event.target.checked,
                                    }));
                                }}
                            />}
                        label={'Pflichtfrage'}
                    />
                </FormGroup>

                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id={'type-multiple-checkbox-label'}>
                        Sichtbar für
                    </InputLabel>
                    <Select
                        labelId={'type-multiple-checkbox-label'}
                        id={'type-multiple-checkbox'}
                        multiple={true}
                        value={questionVisibleFor}
                        onChange={handleVisibileForChange}
                        input={<OutlinedInput label={'Sichtbarkeit'} />}
                        renderValue={(selected) => selected.map(type => locationTypesNames[type]).join(', ')}
                        MenuProps={MenuProps}
                        disabled={props.type === 'hotel'}
                    >
                        {props.type === 'restaurant' && locationTypes.map(type => (
                            <MenuItem key={type} value={type}>
                                <Checkbox checked={questionVisibleFor.indexOf(type) > -1} />
                                <ListItemText primary={locationTypesNames[type]} />
                            </MenuItem>
                        ))}
                        {props.type === 'hotel' &&
                            <MenuItem key={'hotel'} value={'hotel'}>
                                <Checkbox checked={questionVisibleFor.indexOf('hotel') > -1} />
                                <ListItemText primary={'Hotel garni / Pension'} />
                            </MenuItem>
                        }
                    </Select>
                </FormControl>
            </Box>

            <Typography variant={'body1'} sx={styles.sectionDividerAnswers}>
                Antworten:
            </Typography>

            {answerList}

            <Button
                variant={'contained'}
                onClick={() => addAnswer()}
                sx={{margin: '30px 0px', width: 'fit-content'}}
            >
                Antwort hinzufügen
            </Button>

            <DeleteDialog
                open={deleteInfoOpen}
                onClose={() => {setDeleteInfoOpen(false);}}
                onFinalize={() => {deleteQuestion(); setDeleteInfoOpen(false);}}
                title={'Frage löschen'}
                text={`Möchten Sie die Frage "${props.question.label}" löschen?`}
                continueButtonText={'Löschen'}
            />
        </Box>
    );
};

export default QuestionEdit;
