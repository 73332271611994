import {Alert, Box, Button, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import Command from '../../state/actions/command';

const StartWebsiteCheckInfo = () => {
    const dispatch = useDispatch();

    const startSelfAssessment = () => {
        dispatch(Command.WebsiteCheck.startWebsiteCheck({}));
    };

    return (
        <Box>
            <Alert severity={'info'}>
                Der Website Check liefet Informationen über die Unternehmenspräsentation des teilnehmenden Betriebes im
                digitalen Raum.  Der Website Check liefeert dem Tester eine Orientierung zu den Digitalangeboten des
                Betriebes und erleichtert den Soll-/Ist-Vergleichs zwischen digitalem Leistungsversprechen auf der
                Website und der beim Test angetroffenen Realsituation.
            </Alert>
            <Typography variant={'body2'} sx={{ marginTop: 3, marginBottom: 3 }}>
                Ihr Arbeitsstand wird zwischengespeichert.
            </Typography>
            <Button variant={'contained'} onClick={startSelfAssessment}>
                Website Check beginnen
            </Button>
        </Box>
    );
};

export default StartWebsiteCheckInfo;
