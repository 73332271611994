import CompanyEvent from './companyEvent';
import UserEvent from './userEvent';
import SelfAssessmentEvent from './selfAssessmentEvent';
import WebsiteCheckEvent from './websiteCheckEvent';
import MysteryTestEvent from './mysteryTestEvent';
import NotificationEvent from './notificationEvent';
import TrustYouScoreEvent from './trustYouScoreEvent';
import QuestionListEvent from './questionListEvent';

const Event = {
    User: UserEvent,
    Company: CompanyEvent,
    SelfAssessment: SelfAssessmentEvent,
    WebsiteCheck: WebsiteCheckEvent,
    MysteryTest: MysteryTestEvent,
    Notification: NotificationEvent,
    TrustYou: TrustYouScoreEvent,
    QuestionList: QuestionListEvent,
};

export default Event;
