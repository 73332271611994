import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import Config from '../util/config';
import Keycloak from '../keycloak';
import {
    AnswerSelfAssessmentQuestionData,
    AssignCompanyEmployeeData,
    CreateCompanyData,
    FinalizeMysteryTestData,
    FinalizeSelfAssessmentData,
    FinalizeWebsiteCheckData,
    GenerateBookingContractPayload,
    GenerateMysteryTestReportData,
    RegisterNewCompanyData,
    RemoveCompanyTester,
    ReopenMysteryTestData,
    ReopenSelfAssessmentData,
    ReopenWebsiteCheckData,
    SetCompanyTestCategoryData,
    SetCompanyTester,
    SetNewTrustYouScoreData,
    StartNewMysteryTestAttemptData,
    StartNewMysteryTestData,
    StartNewWebsiteCheckData,
    StartSelfAssessmentData,
    SubmitMysteryTestHotelChangesData,
    UnassignCompanyEmployeeData,
    UpdateBasicCompanyDataData,
    UpdateMysteryTestDataData,
    UpdateTrustYouScoreData,
    UpdateWebsiteCheckDataData,
} from './types';
import {Company, CompanyStatus} from '../state/types/Company';
import {SelfAssessment} from '../state/types/SelfAssessment';
import {WebsiteCheck} from '../state/types/WebsiteCheck';
import {MysteryTest} from '../state/types/MysteryTest';
import {User} from '../state/types/User';

const configuredAxios = axios.create({
    baseURL: Config.api.baseUrl,
});

const sendApiRequest = async (config: AxiosRequestConfig): Promise<AxiosResponse> => {
    // @todo reduce min validity to ~10
    await Keycloak.refreshToken(10);

    // @ts-ignore
    configuredAxios.defaults.headers.Authorization = `Bearer ${Keycloak.instance.token}`;
    return configuredAxios(config);
};

const sendMessageToMessageBox = async (name: string, payload?: object): Promise<AxiosResponse> => {
    return sendApiRequest({
        url: `/messagebox/${name}`,
        method: 'post',
        data: payload,
    });
};

const Api = {
    Command: {
        createCompany: async (payload: CreateCompanyData): Promise<void> => {
            await sendMessageToMessageBox('CreateCompany', payload);
        },
        registerNewCompany: async (payload: RegisterNewCompanyData): Promise<void> => {
            await sendMessageToMessageBox('RegisterNewCompany', payload);
        },
        assignCompanyEmployee: async (payload: AssignCompanyEmployeeData): Promise<void> => {
            await sendMessageToMessageBox('AssignCompanyEmployee', payload);
        },
        unassignCompanyEmployee: async (payload: UnassignCompanyEmployeeData): Promise<void> => {
            await sendMessageToMessageBox('UnassignCompanyEmployee', payload);
        },
        updateBasicCompanyData: async (payload: UpdateBasicCompanyDataData): Promise<void> => {
            await sendMessageToMessageBox('UpdateBasicCompanyData', payload);
        },
        setCompanyTestCategory: async (payload: SetCompanyTestCategoryData): Promise<void> => {
            await sendMessageToMessageBox('SetCompanyTestCategory', payload);
        },
        setCompanyTester: async (payload: SetCompanyTester): Promise<void> => {
            await sendMessageToMessageBox('SetCompanyTester', payload);
        },
        removeCompanyTester: async (payload: RemoveCompanyTester): Promise<void> => {
            await sendMessageToMessageBox('RemoveCompanyTester', payload);
        },

        startSelfAssessment: async (payload: StartSelfAssessmentData) => {
            await sendMessageToMessageBox('StartSelfAssessment', payload);
        },
        answerSelfAssessmentQuestion: async (payload: AnswerSelfAssessmentQuestionData) => {
            await sendMessageToMessageBox('AnswerSelfAssessmentQuestion', payload);
        },
        finalizeSelfAssessment: async (payload: FinalizeSelfAssessmentData) => {
            await sendMessageToMessageBox('FinalizeSelfAssessment', payload);
        },
        reopenSelfAssessment: async (payload: ReopenSelfAssessmentData) => {
            await sendMessageToMessageBox('ReopenSelfAssessment', payload);
        },

        startNewWebsiteCheck: async (payload: StartNewWebsiteCheckData) => {
            await sendMessageToMessageBox('StartNewWebsiteCheck', payload);
        },
        updateWebsiteCheckData: async (payload: UpdateWebsiteCheckDataData) => {
            await sendMessageToMessageBox('UpdateWebsiteCheckData', payload);
        },
        finalizeWebsiteCheck: async (payload: FinalizeWebsiteCheckData) => {
            await sendMessageToMessageBox('FinalizeWebsiteCheck', payload);
        },
        reopenWebsiteCheck: async (payload: ReopenWebsiteCheckData) => {
            await sendMessageToMessageBox('ReopenWebsiteCheck', payload);
        },

        startNewMysteryTest: async (payload: StartNewMysteryTestData) => {
            await sendMessageToMessageBox('StartNewMysteryTest', payload);
        },
        updateMysteryTestData: async (payload: UpdateMysteryTestDataData) => {
            await sendMessageToMessageBox('UpdateMysteryTestData', payload);
        },
        finalizeMysteryTest: async (payload: FinalizeMysteryTestData) => {
            await sendMessageToMessageBox('FinalizeMysteryTest', payload);
        },
        reopenMysteryTest: async (payload: ReopenMysteryTestData) => {
            await sendMessageToMessageBox('ReopenMysteryTest', payload);
        },
        generateMysteryTestReport: async (payload: GenerateMysteryTestReportData) => {
            await sendMessageToMessageBox('GenerateMysteryTestReport', payload);
        },
        generateBookingContract: async (payload: GenerateBookingContractPayload) => {
            await sendMessageToMessageBox('GenerateBookingContract', payload);
        },
        setNewTrustYouScore: async (payload: SetNewTrustYouScoreData) => {
            await sendMessageToMessageBox('SetNewTrustYouScore', payload);
        },
        updateTrustYouScore: async (payload: UpdateTrustYouScoreData) => {
            await sendMessageToMessageBox('UpdateTrustYouScore', payload);
        },
        submitMysteryTestHotelChanges: async (payload: SubmitMysteryTestHotelChangesData) => {
            await sendMessageToMessageBox('SubmitQuestionConfigurationChanges', payload);
        },
        startNewMysteryTestAttempt: async (payload: StartNewMysteryTestAttemptData) => {
            await sendMessageToMessageBox('StartNewMysteryTestAttempt', payload);
        },
    },
    Query: {
        companyList: async (): Promise<Company[]> => {
            const response = await sendMessageToMessageBox('CompanyList');

            return response.data.map((companyData: any): Company => ({
                id: companyData.companyId,
                name: companyData.companyName,
                restaurantName: companyData.restaurantName,
                billingStreet: companyData.billingStreet,
                billingZip: companyData.billingZip,
                billingCity: companyData.billingCity,
                contactPerson: companyData.contactPerson,
                street: companyData.street,
                zip: companyData.zip,
                city: companyData.city,
                phone: companyData.phone,
                email: companyData.email,
                web: companyData.web,
                employees: companyData.employees,
                testCategory: companyData.testCategory || null,
                tester: companyData.tester || null,
                hidden: companyData.hidden,
                travelRegion: companyData.travelRegion,
                bookedQualityCheck: companyData.bookedQualityCheck,
                bookedQualityCheckDate: companyData.bookedQualityCheckDate,
                bookedMarketing: companyData.bookedMarketing,
                bookedMarketingDate: companyData.bookedMarketingDate,
            }));
        },
        companyStatusList: async (): Promise<CompanyStatus[]> => {
            const response = await sendMessageToMessageBox('CompanyStatusList');

            return response.data.companyStatusList.map((companyData: any): CompanyStatus => ({
                companyId: companyData.companyId,
                selfAssessment: companyData.selfAssessment,
                websiteCheck: companyData.websiteCheck,
                mysteryTest: companyData.mysteryTest,
                trustYouScore: companyData.trustYouScore,
            }));
        },
        selfAssessment: async (companyId: string): Promise<SelfAssessment> => {
            const response = await sendMessageToMessageBox('SelfAssessment', { companyId });

            const answers = response.data.answers;

            return {
                selfAssessmentId: response.data.selfAssessmentId,
                data: (answers && typeof answers === 'object' && !Array.isArray(answers)) ? response.data.answers : {},
                submitted: response.data.submitted,
            };
        },
        websiteCheck: async (companyId: string): Promise<WebsiteCheck> => {
            const response = await sendMessageToMessageBox('WebsiteCheck', { companyId });

            const data = response.data.data;

            return {
                websiteCheckId: response.data.websiteCheckId,
                data: (data && typeof data === 'object' && !Array.isArray(data)) ? data : {},
                submitted: response.data.submitted,
            };
        },
        mysteryTest: async (companyId: string): Promise<MysteryTest> => {
            const response = await sendMessageToMessageBox('MysteryTest', { companyId });

            const data = response.data.data;

            return {
                mysteryTestId: response.data.mysteryTestId,
                type: response.data.type,
                data: (data && typeof data === 'object' && !Array.isArray(data)) ? data : {},
                submitted: response.data.submitted,
                attempt: response.data.attempt,
            };
        },
        user: async (userId: string): Promise<User> => {
            const response = await sendMessageToMessageBox('User', { userId });

            return response.data;
        },
        userByEmail: async (email: string): Promise<User> => {
            const response = await sendMessageToMessageBox('UserByEmail', { userEmail: email });

            return response.data;
        },
        testQuestions: async (): Promise<any> => {
            const response = await sendMessageToMessageBox('TestQuestions', {});

            return response.data;
        },
        questionConfiguration: async (type: string): Promise<any> => {
            const response = await sendMessageToMessageBox('QuestionConfiguration', {questionConfigurationType: type});

            return response.data;
        },
    },
};

export default Api;
