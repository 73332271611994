import {Box, Divider, TextField} from '@mui/material';
import {AnswerGroup, Question} from '../../util/dataTypes';
import AnswerCard from './AnswerCard';
import React from 'react';

interface QuestionProps {
    questions: Question[];
    additionTextField?: boolean;
    data: any;
    questionData: any;
    onQuestionDataChange: (data: any) => void;
    disabled?: boolean;
}

const styles = {
    textfields: {
        width: '100%',
    },
};

const QuestionCard = (props: QuestionProps) => {
    const updateQuestionData = (answerGroupId: string, newAnswer: any) => {
        props.onQuestionDataChange({
            ...props.questionData,
            [answerGroupId]: newAnswer,
        });
    };

    const questions = props.questions;

    return (
        <Box>
            {!questions ?
                ''
                :
                questions.map((question: Question) => (
                    <Box>
                        <h3>{question.label}</h3>
                        <p>{question.description}</p>
                        {!question.answersGroups ?
                            ''
                            :
                            question.answersGroups.map((answerGroup: AnswerGroup) => {
                                const value = props.questionData[answerGroup._id] || props.data[answerGroup._id] || {};
                                const skipQuestion = Boolean(
                                    answerGroup.dependency?.on &&
                                    answerGroup.dependency?.value &&
                                    (
                                        props.questionData[answerGroup.dependency?.on]?.radio ||
                                        props.data[answerGroup.dependency?.on]?.radio
                                    ) !== answerGroup.dependency?.value,
                                );

                                if (skipQuestion) {
                                    if (props.data[answerGroup._id] && props.questionData[answerGroup._id] !== null) {
                                        updateQuestionData(answerGroup._id, null);
                                    } else if (props.questionData[answerGroup._id]) {
                                        updateQuestionData(answerGroup._id, undefined);
                                    }

                                    return null;
                                }

                                return (
                                    <Box key={answerGroup._id}>
                                        <AnswerCard
                                            id={answerGroup._id}
                                            label={answerGroup.label}
                                            type={answerGroup.answer_type}
                                            answers={answerGroup.answers}
                                            value={value}
                                            onChange={newData => updateQuestionData(answerGroup._id, newData)}
                                            disabled={props.disabled}
                                        />
                                        <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                                    </Box>
                                );
                            })
                        }

                        {props.additionTextField && (
                            <Box>
                                <TextField
                                    sx={styles.textfields}
                                    label={'Anmerkungen'}
                                    multiline={true}
                                    rows={4}
                                    value={props.questionData[question._id]?.infoText
                                        || props.data[question._id]?.infoText || ''}
                                    onChange={event => updateQuestionData(
                                        question._id,
                                        { infoText: event.target.value },
                                    )}
                                    disabled={props.disabled}
                                />
                                <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                            </Box>
                        )
                        }

                    </Box>
                ))
            }
        </Box>
    );

};

export default QuestionCard;
