import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

interface FinalizeMysteryConfirmationProps {
    open: boolean;
    type: string;
    onClose: () => void;
    onFinalize: () => void;
}

const FinalizeMysteryConfirmation = (props: FinalizeMysteryConfirmationProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                {props.type === 'hotel' ? 'MysteryTest-Hotel' :
                    props.type === 'restaurant' ? 'MysteryTest-Restaurant' : ''}
                &nbsp;Fragen speichern
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Möchten Sie den angepassten&nbsp;
                    {props.type === 'hotel' ? 'MysteryTest-Hotel' :
                        props.type === 'restaurant' ? 'MysteryTest-Restaurant' : ''}
                    &nbsp;wirklich speichern?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button color={'primary'} onClick={props.onFinalize} variant={'contained'}>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FinalizeMysteryConfirmation;
