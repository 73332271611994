import {formatDateTimeReadable} from '../../../util/formatDate';
import {DateTime} from 'luxon';
import React from 'react';
import {Box} from '@mui/material';

interface LatestTestDateProps {
    data: {
        submitted: boolean;
        finishedAt?: string;
        startedAt?: string;
    };
    useStyle: boolean;
}

const styles = {
    container: {
        fontSize: '0.8125rem',
        marginLeft: 2,
    },
};
const LatestTestDate = (props: LatestTestDateProps) => {
    const dateString = props.data?.submitted ? props.data.finishedAt : props.data?.startedAt;

    return (
        <Box sx={props.useStyle ? styles.container : undefined}>
            {
                (dateString && props.data.submitted ? 'abgeschlossen am ' : 'gestartet am ') +
                formatDateTimeReadable(dateString ? DateTime.fromISO(dateString) : DateTime.local())
            }
        </Box>
    );
};

export default LatestTestDate;
