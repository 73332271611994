import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../state/actions/command';
import {Selector} from '../../state/selector';
import {ReduxState} from '../../state/reducer';

interface SetCompanyHiddenDialogProps {
    open: boolean;
    companyId?: string;
    onClose: () => void;
}

const SetCompanyHiddenDialog = (props: SetCompanyHiddenDialogProps) => {
    const dispatch = useDispatch();
    const company = useSelector((state: ReduxState) => Selector.Company.company(state, props.companyId || ''));

    const setCompanyHidden = () => {
        if (!company) {return;}

        dispatch(Command.Company.updateBasicCompanyData({
            companyId: company.id,
            companyName: company.name,
            restaurantName: company.restaurantName,
            billingStreet: company.billingStreet,
            billingZip: company.billingZip,
            billingCity: company.billingCity,
            contactPerson: company.contactPerson,
            street: company.street,
            zip: company.zip,
            city: company.city,
            phone: company.phone,
            email: company.email,
            web: company.web,
            testCategory: company.testCategory ? company.testCategory : undefined,
            hidden: true,
            travelRegion: company.travelRegion,
            bookedQualityCheck: company.bookedQualityCheck,
            bookedQualityCheckDate: company.bookedQualityCheckDate,
            bookedMarketing: company.bookedMarketing,
            bookedMarketingDate: company.bookedMarketingDate,
        }));

        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <DialogTitle>Unternehmen ausblenden</DialogTitle>
            <DialogContent>
                <Typography>
                    Sie Sie sicher, dass Sie das Unternehmen {company?.name} ausblenden wollen?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button
                    children={'Ausblenden'}
                    variant={'contained'}
                    onClick={setCompanyHidden}
                />
            </DialogActions>
        </Dialog>
    );
};

export default SetCompanyHiddenDialog;
