import {Box, Button, Card, FormControlLabel, FormGroup, Menu, MenuItem, Switch, TextField} from '@mui/material';
import CategoryStepper from './common/CategoryStepper';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import Command from '../../state/actions/command';
import {theme} from '../../mui/theme';
import SaveDialog from './common/SaveDialog';
import SubCategoryEdit from './components/SubCategoryEdit';
import Event from '../../state/actions/event';
import Fab from '@mui/material/Fab';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImportMysteryTestDialog from './common/ImportMysteryTestDialog';
import {useNavigate} from 'react-router';

const styles = {
    flexBox: {
        display: 'flex',
        columnGap: '30px',
        width: '100%',
        position: 'relative',
        paddingBottom: '33vh',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1000px',
    },
    leftBar: {
        maxHeight: 'calc(100vh - 120px)',
        overflowY: 'scroll',
        position: 'sticky',
        top: '88px',
    },
    questionnaire: {
        padding: 4,
        width: '100%',
    },
    buttons: {
        marginLeft: '20px',
    },
    stickyButton: {
        position: 'sticky',
        top: '90%',
        padding: '15px 20px',
        backgroundColor: '#ff8800',
        lineHeight: '1',
    },
    formGroup: {
        width: 'fit-content',
    },
};

interface EditMysteryTestProps {
    type: string;
}

const EditMysteryTest = (props: EditMysteryTestProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loadedData = useSelector(Selector.QuestionList.mysteryTestHotelConfiguration);
    const [data, setData] = useState<any>(undefined);
    const [activateCategory, setActivateCategory] = useState<number>(0);

    const [confirmInfoOpen, setConfirmInfoOpen] = useState<boolean>(false);

    const [category, setCategory] = useState<string>('');
    const [categoryDescription, setCategoryDescription] = useState<string>('');
    const [categoryAdditionalPoints, setCategoryAdditionalPoints] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [importInfoOpen, setImportInfoOpen] = useState<boolean>(false);

    const saveMysteryTest = () => {
        dispatch(Command.QuestionList.submitMysteryTestHotelChanges({
            data: loadedData,
            type: props.type === 'hotel' ? 'mysteryTestHotel' :
                props.type === 'restaurant' ? 'mysteryTestRestaurant' : '',
        }));
        setConfirmInfoOpen(false);
    };
    const importMysteryTest = () => {
        dispatch(Command.QuestionList.importMysteryTestQuestions({type: props.type}));
        setImportInfoOpen(false);
    };

    const addCategory = () => {
        dispatch(Event.QuestionList.mysteryTestHotelCategoryAdded({}));
    };

    const addSubCategory = (categoryId: string) => {
        dispatch(Event.QuestionList.mysteryTestHotelSubCategoryAdded({
            parentId: categoryId,
        }));
    };

    const deleteCategory = () => {
        dispatch(Event.QuestionList.mysteryTestHotelCategoryDeleted({
            id: data.categories[activateCategory]._id,
        }));
    };

    const moveCategoryUp = (position: number) => {
        dispatch(Event.QuestionList.mysteryTestHotelCategoryMovedUp({
            position: position,
        }));
    };

    const moveCategoryDown = (position: number) => {
        dispatch(Event.QuestionList.mysteryTestHotelCategoryMovedDown({
            position: position,
        }));
    };

    const categorySelected = (categoryId: string) => {
        let pos = 0;
        data.categories.forEach((category: any) => {
            if (category._id === categoryId) {
                setActivateCategory(pos);
            }
            pos += 1;
        });
    };

    const subCategoryList = data && data.categories && data.categories[activateCategory] ?
        data.categories[activateCategory].questions.map((question: any) =>
            <Accordion
                key={question._id}
                sx={{margin: '0px 0px'}}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={'panel1a-content'}
                    id={'panel1a-header'}
                    sx={{
                        position: 'sticky',
                        top: '64px',
                        zIndex: 10,
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <Typography sx={{fontSize: '2rem'}}>{question.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SubCategoryEdit
                        id={question._id}
                        parentId={data.categories[activateCategory]._id}
                        title={question.label}
                        description={question.description}
                        questions={question.answersGroups}
                        type={props.type}
                    />
                </AccordionDetails>
            </Accordion>,
        ) : <p>loading...</p>;

    useEffect(() => {
        setData(loadedData);
    }, [loadedData]);

    useEffect(() => {
        if (props.type === 'hotel') {
            dispatch(Command.QuestionList.fetchQuestionConfiguration(
                {questionConfigurationType: 'mysteryTestHotel'},
            ));
        }
        if (props.type === 'restaurant') {
            dispatch(Command.QuestionList.fetchQuestionConfiguration(
                {questionConfigurationType: 'mysteryTestRestaurant'},
            ));
        }
        setActivateCategory(0);
    }, [props.type]);

    useEffect(() => {
        if (data !== undefined && data.categories !== undefined && data.categories.length !== 0) {
            setCategory(data.categories[activateCategory].label);
            setCategoryDescription(data.categories[activateCategory].description);
            setCategoryAdditionalPoints(data.categories[activateCategory].additionalPoints ?
                data.categories[activateCategory].additionalPoints : false,
            );
        }
    }, [activateCategory, data]);

    /* eslint-disable max-len */
    return (
        <>
            {(data !== undefined && data.categories !== undefined && data.categories[0] !== undefined) && (
                <Box sx={styles.flexBox}>
                    <Box sx={styles.leftBar}>
                        <Button
                            variant={'contained'}
                            onClick={addCategory}
                        >
                            Kategorie hinzufügen
                        </Button>

                        <CategoryStepper
                            activeCategory={activateCategory}
                            categories={data.categories}
                            selectCategory={categorySelected}
                            moveCategoryUp={() => moveCategoryUp(activateCategory)}
                            moveCategoryDown={() => moveCategoryDown(activateCategory)}
                            deleteCategory={deleteCategory}
                        />
                    </Box>

                    <Box sx={styles.flexColumn}>
                        <Card sx={{padding: '20px'}}>
                            <TextField
                                label={`Kategorie ${activateCategory + 1}`}
                                variant={'outlined'}
                                fullWidth={true}
                                size={'small'}
                                sx={{marginBottom: '30px'}}
                                value={category}
                                onChange={event => {setCategory(event.target.value);}}
                                onBlur={event => {
                                    dispatch(Event.QuestionList.mysteryTestHotelCategoryLabelChanged({
                                        id: data.categories[activateCategory]._id,
                                        label: event.target.value,
                                    }));
                                }}
                            />
                            <TextField
                                label={'Beschreibung'}
                                variant={'outlined'}
                                fullWidth={true}
                                size={'small'}
                                value={categoryDescription}
                                multiline={true}
                                rows={4}
                                onChange={event => {setCategoryDescription(event.target.value);}}
                                onBlur={event => {
                                    dispatch(Event.QuestionList.mysteryTestHotelCategoryDescriptionChanged({
                                        id: data.categories[activateCategory]._id,
                                        description: event.target.value,
                                    }));
                                }}
                            />

                            <FormGroup sx={styles.formGroup}>
                                <FormControlLabel
                                    sx={{marginRight: 0, paddingRight: '7px'}}
                                    control={
                                        <Switch
                                            checked={categoryAdditionalPoints}
                                            onChange={event => {
                                                setCategoryAdditionalPoints(event.target.checked);
                                                dispatch(Event.QuestionList.mysteryTestHotelCategoryAdditionalPointsFlagChanged({
                                                    id: data.categories[activateCategory]._id,
                                                    value: event.target.checked,
                                                }));
                                            }}
                                        />}
                                    label={'Kategorie als Bonus Punkte werten.'}
                                />
                            </FormGroup>

                        </Card>

                        <Button
                            variant={'contained'}
                            onClick={() => addSubCategory(data.categories[activateCategory]._id)}
                            sx={{margin: '30px 0px', width: 'fit-content'}}
                        >
                            Unterkategorie hinzufügen
                        </Button>

                        <Box>
                            {subCategoryList}
                        </Box>
                    </Box>

                    <Box>
                        { (props.type === 'hotel' || props.type === 'restaurant') && (<>
                            <Button
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    setAnchorEl(event.currentTarget);
                                }}
                                sx={{position: 'sticky', top: '88px'}}
                            >
                                Optionen
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => {
                                    setImportInfoOpen(false);
                                    setAnchorEl(null);
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        saveMysteryTest();
                                        navigate(`/questioneditor/mysterytest-${props.type}/test-config`);
                                    }}
                                >
                                    Aktuelle Test Configuration speichern und Testen.
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        window.open(window.location.origin +
                                            `/api/questioneditor/${props.type}/preview`, '_blank');
                                    }}
                                >
                                    Zuletzt gespeicherte Test Configuration als PDF öffnen.
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setImportInfoOpen(true);
                                        setAnchorEl(null);
                                    }}
                                >
                                    Fragen aus dem MysteryTest {props.type === 'hotel' ? 'Restaurant' : 'Hotel'} übernhemen
                                </MenuItem>
                            </Menu>
                        </>)}

                        <Fab
                            sx={styles.stickyButton}
                            variant={'extended'}
                            onClick={() => {setConfirmInfoOpen(true);}}
                        >
                            Änderungen Speichern
                        </Fab>
                    </Box>

                    <SaveDialog
                        open={confirmInfoOpen}
                        type={props.type}
                        onClose={() => {setConfirmInfoOpen(false);}}
                        onFinalize={saveMysteryTest}
                    />

                    <ImportMysteryTestDialog
                        open={importInfoOpen}
                        type={props.type}
                        onClose={() => {setImportInfoOpen(false);}}
                        onImport={() => {
                            importMysteryTest();
                        }}
                    />
                </Box>
            )}
            {(data !== undefined && data.categories !== undefined && data.categories[0] === undefined) && (
                <Box sx={styles.flexBox}>
                    <Box>
                        <Button
                            variant={'contained'}
                            onClick={addCategory}
                        >
                            Fragen Erstellung Starten
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default EditMysteryTest;
