import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {Selector} from '../../selector';
import {ReopenMysteryTestPayload} from '../../actions/mysteryTestCommand';

const onReopenMysteryTest = function*(data: ReopenMysteryTestPayload) {
    const mysteryTestId: string|null = yield select(Selector.MysteryTest.mysteryTestId);

    if (!mysteryTestId) {
        return;
    }

    try {
        yield call(Api.Command.reopenMysteryTest, {
            mysteryTestId,
        });

        yield put(Event.MysteryTest.mysteryTestReopened({}));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Der Mystery-Test wurde erneut geöffnet.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Mystery-Test konnte nicht erneut geöffnet werden.',
        }));
    }
};

export function* reopenMysteryTestFlow() {
    while (true) {
        const action: Action<ReopenMysteryTestPayload> = yield take(
            Command.MysteryTest.reopenMysteryTest.toString(),
        );

        yield fork(onReopenMysteryTest, action.payload);
    }
}
