import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import {RemoveCompanyTesterPayload} from '../../actions/companyCommand';
import Event from '../../actions/event';
import Api from '../../../api';

const onRemoveCompanyTester = function*(data: RemoveCompanyTesterPayload) {
    try {
        yield call(Api.Command.removeCompanyTester, {
            companyId: data.companyId,
        });

        yield put(Event.Company.companyTesterRemoved({
            companyId: data.companyId,
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Der Tester wurde entfernt.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Tester konnte nicht entfernt werden.',
        }));
    }
};

export function* removeCompanyTesterFlow() {
    while (true) {
        const action: Action<RemoveCompanyTesterPayload> = yield take(
            Command.Company.removeCompanyTester.toString(),
        );

        yield fork(onRemoveCompanyTester, action.payload);
    }
}
