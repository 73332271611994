import {Alert, Box, Divider} from '@mui/material';
import {AnswerGroup, Question} from '../../util/dataTypes';
import React from 'react';
import MysterytestAnswerCard from './MysterytestAnswerCard';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';

const styles = {
    noQuestionsInfo: {
        marginBottom: 2,
    },
};

interface MysterytestQuestionCardProps {
    questions: Question[];
    data: any;
    questionData: any;
    onQuestionDataChange: (data: any) => void;
    isHotel: boolean;
    warnRequiredQuestions: boolean;
    disabled?: boolean;
    testEnvironment: boolean;
    testVisibility: string;
}

const MysterytestQuestionCard = (props: MysterytestQuestionCardProps) => {
    const selectedCompany = useSelector(Selector.Company.selectedCompany);

    if (!selectedCompany?.testCategory && !props.testEnvironment) {
        return null;
    }

    const updateQuestionData = (answerGroupId: string, newAnswer: any) => {
        props.onQuestionDataChange({
            ...props.questionData,
            [answerGroupId]: newAnswer,
        });
    };

    const finalQuestions = props.questions.map(
        (question: Question) => {
            const finalAnswerGroups = (question.answersGroups || [])
                .map((answerGroup: AnswerGroup) => {

                    if (!answerGroup.display) {
                        return null;
                    }

                    // to-do improve skip handling
                    const skipQuestion = Boolean(
                        answerGroup.dependency?.on &&
                        answerGroup.dependency?.value &&
                        (
                            props.questionData[answerGroup.dependency?.on]?.radio ||
                            props.data[answerGroup.dependency?.on]?.radio
                        ) !== answerGroup.dependency?.value,
                    );

                    if (skipQuestion) {
                        if (props.data[answerGroup._id] && props.questionData[answerGroup._id] !== null) {
                            updateQuestionData(answerGroup._id, null);
                        } else if (props.questionData[answerGroup._id]) {
                            updateQuestionData(answerGroup._id, undefined);
                        }

                        return null;
                    }

                    const value = props.questionData[answerGroup._id]
                        || props.data[answerGroup._id] || {};

                    return (
                        <Box key={answerGroup._id}>
                            <MysterytestAnswerCard
                                id={answerGroup._id}
                                label={answerGroup.label}
                                type={answerGroup.answer_type}
                                answers={answerGroup.answers!}
                                value={value}
                                onChange={newData => updateQuestionData(answerGroup._id, newData)}
                                required={answerGroup.required}
                                warn={props.warnRequiredQuestions}
                                disabled={props.disabled}
                                testEnvironment={props.testEnvironment}
                            />
                            <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                        </Box>
                    );
                })
                .filter(Boolean);

            if (finalAnswerGroups.length < 1) {
                return null;
            }

            return (
                <Box key={question._id}>
                    <h3>{question.label}</h3>
                    <p>{question.description}</p>
                    {finalAnswerGroups}
                </Box>
            );
        })
        .filter(Boolean);

    return (
        <Box>
            {finalQuestions?.length > 0 ? finalQuestions : (
                <Alert severity={'info'} sx={styles.noQuestionsInfo}>
                    Für die gewählte Testkategorie sind keine Angaben notwendig.
                </Alert>
            )}
        </Box>
    );

};

export default MysterytestQuestionCard;
