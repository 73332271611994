import {ReduxState} from '../reducer';

const websiteCheckId = (state: ReduxState): string|null => state.websiteCheck.websiteCheckId;
const loaded = (state: ReduxState): boolean => state.websiteCheck.loaded;
const data = (state: ReduxState): any => state.websiteCheck.data;
const submitted = (state: ReduxState): boolean => state.websiteCheck.submitted;

const WebsiteCheckSelector = {
    websiteCheckId,
    loaded,
    data,
    submitted,
};

export default WebsiteCheckSelector;
