import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import {AssignCompanyEmployeePayload} from '../../actions/companyCommand';
import Event from '../../actions/event';
import Api from '../../../api';
import {User} from '../../types/User';

const onAssignCompanyEmployee = function*(data: AssignCompanyEmployeePayload) {
    let userId: string;

    try {
        const user: User = yield call(Api.Query.userByEmail, data.email);
        userId = user.id;
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Es konnte kein Nutzer mit dieser E-Mail Adresse gefunden werden.',
        }));
        return;
    }

    try {
        yield call(Api.Command.assignCompanyEmployee, {
            companyId: data.companyId,
            employeeId: userId,
        });

        yield put(Event.Company.companyEmployeeAssigned({
            companyId: data.companyId,
            userId: userId,
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Der Mitarbeiter wurde hinzugefügt.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Mitarbeiter konnte nicht hinzugefügt werden.',
        }));
    }
};

export function* assignCompanyEmployeeFlow() {
    while (true) {
        const action: Action<AssignCompanyEmployeePayload> = yield take(
            Command.Company.assignCompanyEmployee.toString(),
        );

        yield fork(onAssignCompanyEmployee, action.payload);
    }
}
