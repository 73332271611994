import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../state/actions/command';
import {Selector} from '../../state/selector';
import {ReduxState} from '../../state/reducer';
import ClearIcon from '@mui/icons-material/Clear';
import UserAvatar from '../common/UserAvatar';

const isEmail = (value: string) => value.length > 5;


interface ManageCompanyEmployeesDialogProps {
    open: boolean;
    companyId?: string;
    onClose: () => void;
}

const ManageCompanyEmployeesDialog = (props: ManageCompanyEmployeesDialogProps) => {
    const dispatch = useDispatch();
    const company = useSelector((state: ReduxState) => Selector.Company.company(state, props.companyId || ''));
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        setTimeout(
            () => {
                setEmail('');
            },
            100,
        );
    }, [props.companyId]);

    const activateSaveButton = isEmail(email);

    const assignCompanyEmployee = () => {
        if (!activateSaveButton || !props.companyId) {
            return;
        }

        dispatch(Command.Company.assignCompanyEmployee({
            companyId: props.companyId,
            email,
        }));

        props.onClose();
    };

    const unassignCompanyEmployee = (userId: string) => {
        if (!props.companyId) {
            return;
        }

        dispatch(Command.Company.unassignCompanyEmployee({
            companyId: props.companyId,
            userId: userId,
        }));
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <DialogTitle>Mitarbeiter verwalten</DialogTitle>
            <DialogContent>
                {(company && company.employees.length > 0) && (<>
                    <Typography>Bereits hinzugefügte Mitarbeiter:</Typography>
                    {company?.employees.map(employee => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <UserAvatar userId={employee} />
                            <IconButton size={'small'} onClick={() => unassignCompanyEmployee(employee)}>
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    ))}
                </>)}
                <Box sx={{ marginTop: 1 }}>
                    <TextField
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        label={'User E-Mail'}
                        variant={'outlined'}
                        fullWidth={true}
                        sx={{ marginTop: 1 }}
                        type={'email'}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button
                    children={'Hinzufügen'}
                    startIcon={<AddIcon />}
                    variant={'contained'}
                    onClick={assignCompanyEmployee}
                    disabled={!activateSaveButton}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ManageCompanyEmployeesDialog;
