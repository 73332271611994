import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {v4 as uuidv4} from 'uuid';
import {SetTrustYouScorePayload} from '../../actions/trustYouScoreCommand';

const onSetTrustYouScore = function*(data: SetTrustYouScorePayload) {
    const trustYouId = uuidv4();

    try {
        yield call(Api.Command.setNewTrustYouScore, {
            trustYouId,
            companyId: data.companyId,
            trustYouScore: data.score,
        });

        yield put(Event.TrustYou.trustYouScoreSet({
            trustYouId,
            score: data.score,
        }));

        yield put(Command.Company.fetchCompanyStatusList({}));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der TrustYou Score konnte nicht gesetzt werden.',
        }));
    }
};

export function* setTrustYouScoreFlow() {
    while (true) {
        const action: Action<SetTrustYouScorePayload> = yield take(
            Command.TrustYou.setTrustYouScore.toString(),
        );

        yield fork(onSetTrustYouScore, action.payload);
    }
}
