import {ReduxState} from '../reducer';

const selfAssessmentId = (state: ReduxState): string|null => state.selfAssessment.selfAssessmentId;
const loaded = (state: ReduxState): boolean => state.selfAssessment.loaded;
const data = (state: ReduxState): any => state.selfAssessment.data;
const submitted = (state: ReduxState): boolean => state.selfAssessment.submitted;

const SelfAssessmentSelector = {
    selfAssessmentId,
    loaded,
    data,
    submitted,
};

export default SelfAssessmentSelector;
