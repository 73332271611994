interface Categories {
    [index: string]: string;
}

export const testCategories = {
    restaurant: 'Restaurant',
    excursionRestaurant: 'Ausflugslokal',
    cafe: 'Café / Eisdiele',
    countryGuesthouse: 'Landgasthof',
    pub: 'Kneipe / Bar',
    hotel: 'Hotel garni / Pension',
} as Categories;
