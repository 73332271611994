import {createAction} from 'redux-actions';

const createCompanyCommand = <T>(type: string) => createAction<T>('_C/CO/' + type);

export interface CreateCompanyPayload {
    name: string;
    email: string;
}

export interface RegisterNewCompanyPayload {
    userId: string;
    companyId: string;
    restaurantName: string;
    companyName: string;
    billingStreet: string;
    billingZip: string;
    billingCity: string;
    contactPerson: string;
    street: string;
    zip: string;
    city: string;
    phone: string;
    email: string;
    web: string;
    testCategory: string;
    hidden: boolean;

    travelRegion: string;
    bookedQualityCheck: boolean;
    bookedQualityCheckDate: string;
    bookedMarketing: boolean;
    bookedMarketingDate: string;
}

export interface FetchCompanyListPayload {
}

export interface FetchCompanyStatusListPayload {
}

export interface AssignCompanyEmployeePayload {
    companyId: string;
    email: string;
}

export interface UnassignCompanyEmployeePayload {
    companyId: string;
    userId: string;
}

export interface UpdateBasicCompanyDataPayload {
    companyId: string;
    restaurantName: string;
    companyName: string;
    billingStreet: string;
    billingZip: string;
    billingCity: string;
    contactPerson: string;
    street: string;
    zip: string;
    city: string;
    phone: string;
    email: string;
    web: string;
    testCategory?: string;
    hidden: boolean;

    travelRegion: string;
    bookedQualityCheck: boolean;
    bookedQualityCheckDate: string;
    bookedMarketing: boolean;
    bookedMarketingDate: string;
}

export interface SetCompanyTesterPayload {
    companyId: string;
    email: string;
}

export interface RemoveCompanyTesterPayload {
    companyId: string;
}

const CompanyCommand = {
    createCompany: createCompanyCommand<CreateCompanyPayload>('CREATE_COMPANY'),
    registerNewCompany: createCompanyCommand<RegisterNewCompanyPayload>('REGISTER_NEW_COMPANY'),
    fetchCompanyList: createCompanyCommand<FetchCompanyListPayload>('FETCH_COMPANY_LIST'),
    fetchCompanyStatusList: createCompanyCommand<FetchCompanyStatusListPayload>('FETCH_COMPANY_STATUS_LIST'),
    assignCompanyEmployee: createCompanyCommand<AssignCompanyEmployeePayload>('ASSIGN_COMPANY_EMPLOYEE'),
    unassignCompanyEmployee: createCompanyCommand<UnassignCompanyEmployeePayload>('UNASSIGN_COMPANY_EMPLOYEE'),
    updateBasicCompanyData: createCompanyCommand<UpdateBasicCompanyDataPayload>('UPDATE_BASIC_COMPANY_DATA'),
    setCompanyTester: createCompanyCommand<SetCompanyTesterPayload>('SET_COMPANY_TESTER'),
    removeCompanyTester: createCompanyCommand<RemoveCompanyTesterPayload>('REMOVE_COMPANY_TESTER'),
};

export default CompanyCommand;
