import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {FetchWebsiteCheckPayload} from '../../actions/websiteCheckCommand';
import {WebsiteCheck} from '../../types/WebsiteCheck';

const onFetchWebsiteCheck = function*(data: FetchWebsiteCheckPayload) {
    try {
        const websiteCheck: WebsiteCheck = yield call(Api.Query.websiteCheck, data.companyId);

        yield put(Event.WebsiteCheck.websiteCheckFetched({ websiteCheck }));
    } catch (e) {
        if (e.response?.status === 404) {
            yield put(Event.WebsiteCheck.websiteCheckNotFound({}));
            return;
        }

        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Website-Check konnte nicht geladen werden.',
        }));
    }
};

export function* fetchWebsiteCheckFlow() {
    while (true) {
        const action: Action<FetchWebsiteCheckPayload> = yield take(
            Command.WebsiteCheck.fetchWebsiteCheck.toString(),
        );

        yield fork(onFetchWebsiteCheck, action.payload);
    }
}
