import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import {Box} from '@mui/material';
import WebsiteCheck from './WebsiteCheck';
import StartWebsiteCheckInfo from './StartWebsiteCheckInfo';
import WebsiteCheckOverview from './WebsiteCheckOverview';
import WebsiteCheckHeader from './WebsiteCheckHeader';

const styles = {
    root: {
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'auto',
    },
};

const WebsiteCheckPage = () => {
    const isAdmin = useSelector(Selector.User.isAdmin);
    const hasTesterCompanyAccess = useSelector(Selector.User.hasTesterCompanyAccess);
    const websiteCheckLoaded = useSelector(Selector.WebsiteCheck.loaded);
    const websiteCheckId = useSelector(Selector.WebsiteCheck.websiteCheckId);

    if (hasTesterCompanyAccess) {
        return <WebsiteCheckOverview />;
    }

    if (isAdmin) {
        return (
            <Box sx={styles.root}>
                <WebsiteCheckHeader title={'Website Check'} />

                {(websiteCheckLoaded && websiteCheckId) && <WebsiteCheck />}
                {(websiteCheckLoaded && !websiteCheckId) && <StartWebsiteCheckInfo />}
            </Box>
        );
    } else if (hasTesterCompanyAccess) {
        return <WebsiteCheckOverview />;
    } else {
        return null;
    }
};

export default WebsiteCheckPage;
