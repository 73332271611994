import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {AnswerSelfAssessmentQuestionPayload} from '../../actions/selfAssessmentCommand';
import {Selector} from '../../selector';

const onAnswerSelfAssessmentQuestion = function*(data: AnswerSelfAssessmentQuestionPayload) {
    const selfAssessmentId: string|null = yield select(Selector.SelfAssessment.selfAssessmentId);

    if (!selfAssessmentId) {
        return;
    }

    try {
        yield call(Api.Command.answerSelfAssessmentQuestion, {
            selfAssessmentId,
            selfAssessmentQuestionData: data.data,
        });

        yield put(Event.SelfAssessment.selfAssessmentQuestionAnswered({
            data: data.data,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Selbstauskunft Daten konnten nicht gespeichert werden.',
        }));
    }
};

export function* answerSelfAssessmentQuestionFlow() {
    while (true) {
        const action: Action<AnswerSelfAssessmentQuestionPayload> = yield take(
            Command.SelfAssessment.answerSelfAssessmentQuestion.toString(),
        );

        yield fork(onAnswerSelfAssessmentQuestion, action.payload);
    }
}
