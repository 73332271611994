import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, TextField, Tooltip} from '@mui/material';
import {theme} from '../../../mui/theme';
import {AnswerGroup} from '../../../util/dataTypes';
import QuestionEdit from './QuestionEdit';
import Event from '../../../state/actions/event';
import {useDispatch} from 'react-redux';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForever from '@mui/icons-material/DeleteForever';
import DeleteDialog from '../common/DeleteDialog';

interface SubCategoryEditProps {
    id: string;
    parentId: string;
    title: string;
    description: string;
    questions: AnswerGroup[];
    type: string;
}

const styles = {
    wrapper: {
        boxSizing: 'border-box',
    },
    step: {
        stepLabel: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
                width: '80%',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
                width: '70%',
            },
        },
    },
    categoryWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    titleButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '30px',
    },
    iconButton: {
    },
};

const SubCategoryEdit = (props: SubCategoryEditProps) => {
    const dispatch = useDispatch();

    const [deleteInfoOpen, setDeleteInfoOpen] = useState<boolean>(false);

    const [subcategory, setSubcategory] = useState<string>(props.title);
    const [subcategoryDescription, setSubcategoryDescription] = useState<string>(props.description);


    const deleteSubCategory = () => {
        dispatch(Event.QuestionList.mysteryTestHotelSubCategoryDeleted({
            id: props.id,
            parentId: props.parentId,
        }));
    };

    const moveSubCategoryUp = () => {
        dispatch(Event.QuestionList.mysteryTestHotelSubCategoryMovedUp({
            id: props.id,
            parentId: props.parentId,
        }));
    };
    const moveSubCategoryDown = () => {
        dispatch(Event.QuestionList.mysteryTestHotelSubCategoryMovedDown({
            id: props.id,
            parentId: props.parentId,
        }));
    };

    const addQuestion = (subCategoryId: string) => {
        dispatch(Event.QuestionList.mysteryTestHotelQuestionAdded({
            parentId: subCategoryId,
        }));
    };

    const questionList = props.questions?.map((question: any) =>
        <QuestionEdit
            key={question._id}
            mainCategoryId={props.parentId}
            subCategoryId={props.id}
            questions={props.questions}
            question={question}
            type={props.type}
        />,
    );

    useEffect(() => {
        setSubcategory(props.title);
        setSubcategoryDescription(props.description);
    }, [props.title, props.description]);

    return (
        <Box sx={styles.wrapper} key={props.id}>
            <Box sx={styles.titleButtonWrapper}>
                <TextField
                    label={'Subkategorie'}
                    variant={'outlined'}
                    fullWidth={true}
                    size={'small'}
                    value={subcategory}
                    onChange={event => {setSubcategory(event.target.value);}}
                    onBlur={event => {
                        dispatch(Event.QuestionList.mysteryTestHotelSubCategoryLabelChanged({
                            id: props.id,
                            parentId: props.parentId,
                            label: event.target.value,
                        }));
                    }}
                />

                <Tooltip title={'Subkategorie nach oben verschieben'}>
                    <IconButton
                        sx={styles.iconButton}
                        onClick={moveSubCategoryUp}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Subkategorie nach unten verschieben'}>
                    <IconButton
                        sx={styles.iconButton}
                        onClick={moveSubCategoryDown}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title={'Subkategorie löschen'}>
                    <IconButton
                        sx={styles.iconButton}
                        onClick={() => {setDeleteInfoOpen(true);}}
                    >
                        <DeleteForever />
                    </IconButton>
                </Tooltip>
            </Box>

            <TextField
                label={'Beschreibung'}
                variant={'outlined'}
                fullWidth={true}
                size={'small'}
                multiline={true}
                rows={4}
                value={subcategoryDescription}
                onChange={event => {setSubcategoryDescription(event.target.value);}}
                onBlur={event => {
                    dispatch(Event.QuestionList.mysteryTestHotelSubCategoryDescriptionChanged({
                        id: props.id,
                        parentId: props.parentId,
                        description: event.target.value,
                    }));
                }}
            />

            <Button
                variant={'contained'}
                onClick={() => addQuestion(props.id)}
                sx={{margin: '30px 0px', width: 'fit-content'}}
            >
                Frage hinzufügen
            </Button>

            <Box>
                {questionList}
            </Box>

            <DeleteDialog
                open={deleteInfoOpen}
                onClose={() => {setDeleteInfoOpen(false);}}
                onFinalize={() => {deleteSubCategory(); setDeleteInfoOpen(false);}}
                title={'Subkategorie löschen'}
                text={`Möchten Sie die Subkategorie "${props.title}" löschen?`}
                continueButtonText={'Löschen'}
            />
        </Box>
    );
};

export default SubCategoryEdit;
