import {Box} from '@mui/material';
import MysteryTestRestaurant from './MysteryTestRestaurant';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import {testCategories} from '../../util/testCategoryType';
import StartMysteryTestInfo from '../MysterytestHotel/StartMysteryTestInfo';
import MysteryTestHeader from '../MysterytestHotel/MysteryTestHeader';

const styles = {
    root: {
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'auto',
    },
};

interface MysteryTestRestaurantPageProps {
    testEnvironment: boolean;
}

const MysterytestRestaurantPage = (props: MysteryTestRestaurantPageProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);
    const hasTesterCompanyAccess = useSelector(Selector.User.hasTesterCompanyAccess);
    const mysteryTestLoaded = useSelector(Selector.MysteryTest.loaded);
    const mysteryTestId = useSelector(Selector.MysteryTest.mysteryTestId);
    const selectedCompany = useSelector(Selector.Company.selectedCompany);

    if (!isAdmin && !hasTesterCompanyAccess) {
        return null;
    }

    const selectedTestCategory = selectedCompany?.testCategory || undefined;
    const testCategory = selectedTestCategory ? testCategories[selectedTestCategory] : 'Restaurant';

    return (
        <Box sx={styles.root}>
            <MysteryTestHeader
                title={
                    props.testEnvironment ?
                        'Mystery-Check Test Configuration: Restaurants' :
                        `Mystery-Check: "${testCategory}"`
                }
            />

            {((mysteryTestLoaded && mysteryTestId) || props.testEnvironment) &&
                <MysteryTestRestaurant testEnvironment={props.testEnvironment} />
            }
            {(mysteryTestLoaded && !mysteryTestId) && <StartMysteryTestInfo />}
        </Box>
    );
};

export default MysterytestRestaurantPage;
