import React, {useState} from 'react';
import {Box, IconButton, Step, StepLabel, Stepper, Tooltip} from '@mui/material';
import {StructureCategories} from '../../../util/dataTypes';
import {theme} from '../../../mui/theme';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteDialogue from './DeleteDialog';

interface CategoryStepperProps {
    activeCategory: number;
    categories: StructureCategories[];
    selectCategory: (categoryId: string) => void;
    moveCategoryUp: () => void;
    moveCategoryDown: () => void;
    deleteCategory: () => void;
}

const styles = {
    wrapper: {
        maxWidth: '600px',
    },
    step: {
        stepLabel: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
                width: '80%',
                cursor: 'pointer',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
                width: '70%',
                cursor: 'pointer',
            },
        },
    },
    categoryWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    iconButton: {
        width: '15%',
    },
};

const CategoryStepper = (props: CategoryStepperProps) => {
    const [deleteInfoOpen, setDeleteInfoOpen] = useState<boolean>(false);

    return (
        <Box sx={styles.wrapper}>
            <Stepper
                activeStep={props.activeCategory}
                orientation={'vertical'}
            >
                {!props.categories ?
                    ''
                    :
                    props.categories.map((category: StructureCategories) => (
                        <Step key={category._id} completed={false}>
                            <StepLabel>
                                <Box
                                    sx={styles.categoryWrapper}
                                    onClick={() => {props.selectCategory(category._id);}}
                                >
                                    <Box sx={styles.step.stepLabel}>
                                        {category.label}
                                    </Box>

                                    <Tooltip title={'Kategorie nach oben verschieben'}>
                                        <IconButton
                                            sx={styles.iconButton}
                                            onClick={() => {props.moveCategoryUp();}}
                                            disabled={category._id !== props.categories[props.activeCategory]._id}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Kategorie nach unten verschieben'}>
                                        <IconButton
                                            sx={styles.iconButton}
                                            onClick={() => {props.moveCategoryDown();}}
                                            disabled={category._id !== props.categories[props.activeCategory]._id}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Kategorie löschen'}>
                                        <IconButton
                                            sx={styles.iconButton}
                                            onClick={() => {setDeleteInfoOpen(true);}}
                                            disabled={category._id !== props.categories[props.activeCategory]._id}
                                        >
                                            <DeleteForever />
                                        </IconButton>
                                    </Tooltip>

                                </Box>
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>

            <DeleteDialogue
                open={deleteInfoOpen}
                onClose={() => {setDeleteInfoOpen(false);}}
                onFinalize={() => {props.deleteCategory(); setDeleteInfoOpen(false);}}
                title={'Kategorie löschen'}
                text={`Wollen Sie die Kategorie "${props.categories[props.activeCategory].label}" löschen?`}
                continueButtonText={'Löschen'}
            />

        </Box>
    );
};

export default CategoryStepper;
