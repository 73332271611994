import {createTheme} from '@mui/material';
import { deDE } from '@mui/x-data-grid';

declare module '@mui/material/styles' {
    interface Theme {
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
    }
}

const white = '#FFFFFF';

export const theme = createTheme(
    {
        palette: {
            primary: {
                contrastText: white,
                main: '#3F51B5',
            },
            secondary: {
                main: white,
            },
        },
        typography: {
            h1: {
                fontSize: '2rem',
                '@media (min-width: 420px)': {
                    fontSize: '3rem',
                },
            },
            h2: {
                fontSize: '1.7rem',
                '@media (min-width: 420px)': {
                    fontSize: '2.5rem',
                },
            },
            h3: {
                fontSize: '1.5rem',
                '@media (min-width: 420px)': {
                    fontSize: '2.1rem',
                },
            },
            h4: {
                fontSize: '1.3rem',
                '@media (min-width: 420px)': {
                    fontSize: '1.7rem',
                },
            },
            h5: {
                fontSize: '1.2rem',
                '@media (min-width: 420px)': {
                    fontSize: '1.4rem',
                },
            },
            h6: {
                fontSize: '1.1rem',
                '@media (min-width: 420px)': {
                    fontSize: '1.2rem',
                },
            },
        },
        components: {
        },
    },
    deDE,
);
