/* eslint-disable max-len */

const answerGroupVisible = (answerGroup: any, companyType: string): boolean =>
{
    if (companyType === '_all_') {
        return true;
    }
    let result = false;
    answerGroup.answers.forEach((answer: any) => {
        if (answer.visibleFor.includes(companyType)) {
            result = true;
        }
    });
    return result;
};

const displayDependency = (subC: any, answerG: any, mysteryTestDoc: any, companyType: string): boolean =>
{
    if (answerG.dependency.on === '') {
        return true;
    }
    subC.answersGroups.forEach((dependentG: any) => {
        if (dependentG._id === answerG.dependency.on) {
            if (dependentG.answer_type === 'radio') {
                if (!((mysteryTestDoc[dependentG._id]?.radio === answerG.dependency.value) &&
                    displayDependency(subC, dependentG, mysteryTestDoc, companyType)) &&
                    answerGroupVisible(answerG, companyType)) {
                    return false;
                }
            } else if (dependentG.answer_type === 'checkbox') {
                if (!((mysteryTestDoc[dependentG._id]?.checkbox[answerG.dependency.value] === true) &&
                    displayDependency(subC, dependentG, mysteryTestDoc, companyType)) &&
                    answerGroupVisible(answerG, companyType)) {
                    return false;
                }
            }
        }
    });
    return true;
};

const calculateDependentMysteryTest = (mysteryTestStructure: any, mysteryTestDoc: any, companyType: string): any =>
// mysterytestDoc is required to be provided as => mysterytestDoc.state.data
{
    mysteryTestStructure.categories.forEach((category: any) => {
        category.questions.forEach((subC: any) => {
            subC.answersGroups.forEach((answerG: any) => {
                if (answerG.dependency.on === '') {
                    answerG.display = answerGroupVisible(answerG, companyType);
                } else {
                    subC.answersGroups.forEach((dependentG: any) => {
                        if (dependentG._id === answerG.dependency.on) {
                            if (dependentG.answer_type === 'radio') {
                                answerG.display = (mysteryTestDoc[dependentG._id]?.radio === answerG.dependency.value) &&
                                    displayDependency(subC, dependentG, mysteryTestDoc, companyType) &&
                                    answerGroupVisible(answerG, companyType);
                            } else if (dependentG.answer_type === 'checkbox') {
                                answerG.display = (mysteryTestDoc[dependentG._id]?.checkbox[answerG.dependency.value] === true) &&
                                    displayDependency(subC, dependentG, mysteryTestDoc, companyType) &&
                                    answerGroupVisible(answerG, companyType);
                            } else if (dependentG.answer_type === 'textbox') {
                                answerG.display = !(mysteryTestDoc[answerG._id]?.text === null || mysteryTestDoc[answerG._id]?.text === '') &&
                                    answerGroupVisible(answerG, companyType);
                            }
                        }
                    });
                }
            });
        });
    });
    return mysteryTestStructure;
};

export default calculateDependentMysteryTest;
