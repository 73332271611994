import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import {UnassignCompanyEmployeePayload} from '../../actions/companyCommand';
import Event from '../../actions/event';
import Api from '../../../api';

const onUnassignCompanyEmployee = function*(data: UnassignCompanyEmployeePayload) {

    try {
        yield call(Api.Command.unassignCompanyEmployee, {
            companyId: data.companyId,
            employeeId: data.userId,
        });

        yield put(Event.Company.companyEmployeeUnassigned({
            companyId: data.companyId,
            userId: data.userId,
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Der Mitarbeiter wurde entfernt.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Mitarbeiter konnte nicht entfernt werden.',
        }));
    }
};

export function* unassignCompanyEmployeeFlow() {
    while (true) {
        const action: Action<UnassignCompanyEmployeePayload> = yield take(
            Command.Company.unassignCompanyEmployee.toString(),
        );

        yield fork(onUnassignCompanyEmployee, action.payload);
    }
}
