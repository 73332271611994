import {createAction} from 'redux-actions';

const createQuestionListCommand = <T>(type: string) => createAction<T>('_C/QL/' + type);

export interface FetchTestListPayload {
}
export interface FetchQuestionConfigurationPayload {
    questionConfigurationType: string;
}
export interface SubmitMysteryTestHotelChangesPayload {
    data: any;
    type: string;
}
export interface ImportMysteryTestQuestionsPayload {
    type: string;
}

/* eslint-disable max-len */
const QuestionListCommand = {
    fetchTestList: createQuestionListCommand<FetchTestListPayload>('FETCH_TEST_LIST'),
    fetchQuestionConfiguration:
        createQuestionListCommand<FetchQuestionConfigurationPayload>('FETCH_QUESTION_CONFIGURATION'),
    submitMysteryTestHotelChanges:
        createQuestionListCommand<SubmitMysteryTestHotelChangesPayload>('SUBMIT_MYSTERY_TEST_HOTEL_CHANGES'),
    importMysteryTestQuestions:
        createQuestionListCommand<ImportMysteryTestQuestionsPayload>('IMPORT_MYSTERY_TEST_QUESTIONS'),
};

export default QuestionListCommand;
