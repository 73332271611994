import {Action, handleActions} from 'redux-actions';
import Event from '../actions/event';
import {
    MysteryTestHotelAnswerAddedPayload,
    MysteryTestHotelAnswerDeletedPayload,
    MysteryTestHotelAnswerLabelChangedPayload,
    MysteryTestHotelAnswerMovedDownPayload,
    MysteryTestHotelAnswerMovedUpPayload,
    MysteryTestHotelAnswerPointsChangedPayload,
    MysteryTestHotelCategoryAddedPayload,
    MysteryTestHotelCategoryAdditionalPointsFlagChangedPayload,
    MysteryTestHotelCategoryDeletedPayload,
    MysteryTestHotelCategoryDescriptionChangedPayload,
    MysteryTestHotelCategoryLabelChangedPayload,
    MysteryTestHotelCategoryMovedDownPayload,
    MysteryTestHotelCategoryMovedUpPayload,
    MysteryTestHotelQuestionAddedPayload,
    MysteryTestHotelQuestionChangedPayload,
    MysteryTestHotelQuestionDeletedPayload,
    MysteryTestHotelQuestionDependencyQuestionChangedPayload,
    MysteryTestHotelQuestionDependencyQuestionValueChangedPayload,
    MysteryTestHotelQuestionMovedDownPayload,
    MysteryTestHotelQuestionMovedUpPayload,
    MysteryTestHotelQuestionRequiredChangedPayload,
    MysteryTestHotelQuestionTypeChangedPayload,
    MysteryTestHotelQuestionVisibleForPayload,
    MysteryTestHotelSubCategoryAddedPayload,
    MysteryTestHotelSubCategoryDeletedPayload,
    MysteryTestHotelSubCategoryDescriptionChangedPayload,
    MysteryTestHotelSubCategoryLabelChangedPayload,
    MysteryTestHotelSubCategoryMovedDownPayload,
    MysteryTestHotelSubCategoryMovedUpPayload,
    MysteryTestImportQuestionsPayload,
    QuestionConfigurationFetchedPayload,
    QuestionListFetchedPayload,
} from '../actions/questionListEvent';
import {v4 as uuidv4} from 'uuid';

export interface QuestionListState {
    activeVersion?: number;
    mysteryTestHotel: any;
    mysteryTestRestaurant: any;
    websiteCheck: any;
    selfAssessment: any;
    mysteryTestHotelConfiguration: any;
    mysteryTestHotelConfigurationId?: string;
    mysteryTestRestaurantConfiguration: any;
    websiteCheckConfiguration: any;
    selfAssessmentConfiguration: any;
}

export const questionListInitialState: QuestionListState = {
    activeVersion: undefined,
    mysteryTestHotel: {},
    mysteryTestRestaurant: {},
    websiteCheck: {},
    selfAssessment: {},
    mysteryTestHotelConfiguration: {},
    mysteryTestHotelConfigurationId: undefined,
    mysteryTestRestaurantConfiguration: {},
    websiteCheckConfiguration: {},
    selfAssessmentConfiguration: {},
};


/* eslint-disable max-len */
const updateMysteryTestCategory = (
    mysteryTestHotel: any,
    actionCategoryId: string,
    handler: (category: any) => any,
) => {
    return mysteryTestHotel.categories.map((category: any) => {
        if (category._id !== actionCategoryId) {
            return category;
        }

        return handler(category);
    });
};
const updateMysteryTestSubCategory = (
    mysteryTestHotel: any,
    actionCategoryId: string,
    actionSubCategoryId: string,
    handler: (subCategory: any) => any,
) => {
    return updateMysteryTestCategory(
        mysteryTestHotel,
        actionCategoryId,
        (category: any) => {
            return {
                ...category,
                questions: category.questions.map((subCategory: any) => {
                    if (subCategory._id !== actionSubCategoryId) {
                        return subCategory;
                    }

                    return handler(subCategory);
                }),
            };
        },
    );
};

// TODO use following function to reduce code duplication
/*
const updateMysteryTestQuestion = (
    mysteryTestHotel: any,
    actionCategoryId: string,
    actionSubCategoryId: string,
    actionQuestionId: string,
    handler: (question: any) => any,
) => {
    return updateMysteryTestSubCategory(
        mysteryTestHotel,
        actionCategoryId,
        actionSubCategoryId,
        (subCategory: any) => {
            return {
                ...subCategory,
                answersGroups: subCategory.answersGroups.map((question: any) => {
                    if (question._id !== actionQuestionId) {
                        return question;
                    }

                    return handler(question);
                }),
            };
        },
    );
};
*/

// TODO use following function to reduce code duplication
/*
const updateMysteryTestAnswer = (
    mysteryTestHotel: any,
    actionCategoryId: string,
    actionSubCategoryId: string,
    actionQuestionId: string,
    actionAnswerId: string,
    handler: (answer: any) => any,
) => {
    return updateMysteryTestQuestion(
        mysteryTestHotel,
        actionCategoryId,
        actionSubCategoryId,
        actionQuestionId,
        (question: any) => {
            return {
                ...question,
                answers: question.answers.map((answer: any) => {
                    if (answer._id !== actionAnswerId) {
                        return answer;
                    }

                    return handler(answer);
                }),
            };
        },
    );
};
*/

export const questionListReducer = handleActions<QuestionListState, any>(
    {
        [Event.QuestionList.questionListFetched.toString()]: (state, action: Action<QuestionListFetchedPayload>): QuestionListState => {
            return {
                ...state,
                activeVersion: action.payload.data['activeVersion'],
                mysteryTestHotel: action.payload.data['mysteryTestHotel'],
                mysteryTestRestaurant: action.payload.data['mysteryTestRestaurant'],
                websiteCheck: action.payload.data['websiteCheck'],
                selfAssessment: action.payload.data['selfAssessment'],
            };
        },
        [Event.QuestionList.questionConfigurationFetched.toString()]: (state, action: Action<QuestionConfigurationFetchedPayload>): QuestionListState => {
            switch (action.payload.questionConfigurationType) {
                case 'mysteryTestHotel':
                    return {
                        ...state,
                        mysteryTestHotelConfiguration: action.payload.data,
                        mysteryTestHotelConfigurationId: action.payload.questionConfigurationId,
                    };
                case 'mysteryTestRestaurant':
                    return {
                        ...state,
                        mysteryTestHotelConfiguration: action.payload.data,
                        mysteryTestHotelConfigurationId: action.payload.questionConfigurationId,
                        //mysteryTestRestaurantConfiguration: action.payload.data,
                    };
                default:
                    return state;
            }

        },
        [Event.QuestionList.mysteryTestImportQuestions.toString()]: (state, action: Action<MysteryTestImportQuestionsPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    _id: uuidv4(),
                    type: action.payload.type === 'hotel' ? 'mysterycheckhotel' : 'mysterycheckrestaurant',

                    categories: action.payload.data.data.categories.map((category: any) => {
                        return {
                            ...category,
                            _id: uuidv4(),
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    _id: uuidv4(),
                                    answersGroups: subCategory.answersGroups.map((answersGroup: any) => {
                                        return {
                                            ...answersGroup,
                                            _id: uuidv4(),
                                            required: false,
                                            dependency: {
                                                on: '',
                                                value: '',
                                            },
                                            answers: answersGroup.answers.map((answer: any) => {
                                                return {
                                                    ...answer,
                                                    _id: uuidv4(),
                                                    visibleFor: action.payload.type === 'hotel' ? ['hotel'] : [],
                                                };
                                            }),
                                        };
                                    }),
                                };
                            }),
                        };
                    }),

                },
            };
        },

        [Event.QuestionList.mysteryTestHotelCategoryAdded.toString()]: (state, action: Action<MysteryTestHotelCategoryAddedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: [
                        ...state.mysteryTestHotelConfiguration.categories,
                        {
                            _id: uuidv4(),
                            label: 'Neue Kategorie',
                            description: '',
                            additionalPoints: false,
                            questions: [],
                        },
                    ],
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelCategoryDeleted.toString()]: (state, action: Action<MysteryTestHotelCategoryDeletedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: [
                        ...state.mysteryTestHotelConfiguration.categories.filter((category: any) => category._id !== action.payload.id),
                    ],
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelCategoryMovedUp.toString()]: (state, action: Action<MysteryTestHotelCategoryMovedUpPayload>): QuestionListState => {
            const pos = action.payload.position;
            if (pos === 0) {
                return state;
            }
            const movedList = state.mysteryTestHotelConfiguration.categories;
            const movedElement = state.mysteryTestHotelConfiguration.categories[pos];
            movedList.splice(pos, 1);
            movedList.splice(pos - 1, 0, movedElement);
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: movedList,
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelCategoryMovedDown.toString()]: (state, action: Action<MysteryTestHotelCategoryMovedDownPayload>): QuestionListState => {
            const pos = action.payload.position;
            if (pos === state.mysteryTestHotelConfiguration.categories.length - 1) {
                return state;
            }
            const movedList = state.mysteryTestHotelConfiguration.categories;
            const movedElement = state.mysteryTestHotelConfiguration.categories[pos];
            movedList.splice(pos, 1);
            movedList.splice(pos + 1, 0, movedElement);
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: movedList,
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelCategoryLabelChanged.toString()]: (state, action: Action<MysteryTestHotelCategoryLabelChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestCategory(state.mysteryTestHotelConfiguration, action.payload.id, (category: any) => {
                        return {
                            ...category,
                            label: action.payload.label,
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelCategoryDescriptionChanged.toString()]: (state, action: Action<MysteryTestHotelCategoryDescriptionChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestCategory(state.mysteryTestHotelConfiguration, action.payload.id, (category: any) => {
                        return {
                            ...category,
                            description: action.payload.description,
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelCategoryAdditionalPointsFlagChanged.toString()]: (state, action: Action<MysteryTestHotelCategoryAdditionalPointsFlagChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestCategory(state.mysteryTestHotelConfiguration, action.payload.id, (category: any) => {
                        return {
                            ...category,
                            additionalPoints: action.payload.value,
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelSubCategoryAdded.toString()]: (state, action: Action<MysteryTestHotelSubCategoryAddedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestCategory(state.mysteryTestHotelConfiguration, action.payload.parentId, (category: any) => {
                        return {
                            ...category,
                            questions: [
                                ...category.questions,
                                {
                                    _id: uuidv4(),
                                    label: 'Neue Sub-Kategorie',
                                    description: '',
                                    answersGroups: [],
                                },
                            ],
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelSubCategoryDeleted.toString()]: (state, action: Action<MysteryTestHotelSubCategoryDeletedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestCategory(state.mysteryTestHotelConfiguration, action.payload.parentId, (category: any) => {
                        return {
                            ...category,
                            questions: [
                                ...category.questions.filter((question: any) => question._id !== action.payload.id),
                            ],
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelSubCategoryMovedUp.toString()]: (state, action: Action<MysteryTestHotelSubCategoryMovedUpPayload>): QuestionListState => {
            const parentPos = state.mysteryTestHotelConfiguration.categories.findIndex((category: any) => category._id === action.payload.parentId);
            const pos = state.mysteryTestHotelConfiguration.categories[parentPos].findIndex((question: any) => question._id === action.payload.id);
            if (pos === 0) {
                return state;
            }
            const movedList = state.mysteryTestHotelConfiguration.categories[parentPos].questions;
            const movedElement = state.mysteryTestHotelConfiguration.categories[parentPos].questions[pos];
            movedList.splice(pos, 1);
            movedList.splice(pos - 1, 0, movedElement);

            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestCategory(state.mysteryTestHotelConfiguration, action.payload.parentId, (category: any) => {
                        return {
                            ...category,
                            questions: movedList,
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelSubCategoryMovedDown.toString()]: (state, action: Action<MysteryTestHotelSubCategoryMovedDownPayload>): QuestionListState => {
            const parentPos = state.mysteryTestHotelConfiguration.categories.findIndex((category: any) => category._id === action.payload.parentId);
            const pos = state.mysteryTestHotelConfiguration.categories[parentPos].questions.findIndex((question: any) => question._id === action.payload.id);
            if (pos === state.mysteryTestHotelConfiguration.categories[parentPos].questions.length - 1) {
                return state;
            }
            const movedList = state.mysteryTestHotelConfiguration.categories[parentPos].questions;
            const movedElement = state.mysteryTestHotelConfiguration.categories[parentPos].questions[pos];
            movedList.splice(pos, 1);
            movedList.splice(pos + 1, 0, movedElement);

            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestCategory(state.mysteryTestHotelConfiguration, action.payload.parentId, (category: any) => {
                        return {
                            ...category,
                            questions: movedList,
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelSubCategoryLabelChanged.toString()]: (state, action: Action<MysteryTestHotelSubCategoryLabelChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestSubCategory(state.mysteryTestHotelConfiguration, action.payload.parentId, action.payload.id, (subCategory: any) => {
                        return {
                            ...subCategory,
                            label: action.payload.label,
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelSubCategoryDescriptionChanged.toString()]: (state, action: Action<MysteryTestHotelSubCategoryDescriptionChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: updateMysteryTestSubCategory(state.mysteryTestHotelConfiguration, action.payload.parentId, action.payload.id, (subCategory: any) => {
                        return {
                            ...subCategory,
                            description: action.payload.description,
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionAdded.toString()]: (state, action: Action<MysteryTestHotelQuestionAddedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                if (subCategory._id !== action.payload.parentId) {
                                    return subCategory;
                                }
                                return {
                                    ...subCategory,
                                    answersGroups: [
                                        ...subCategory.answersGroups,
                                        {
                                            _id: uuidv4(),
                                            label: 'Frage?',
                                            answer_type: 'radio',
                                            required: true,
                                            dependency: {
                                                on: '',
                                                value: '',
                                            },
                                            answers: [],
                                        },
                                    ],
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionDeleted.toString()]: (state, action: Action<MysteryTestHotelQuestionDeletedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                if (subCategory._id !== action.payload.parentId) {
                                    return subCategory;
                                }
                                return {
                                    ...subCategory,
                                    answersGroups: [
                                        ...subCategory.answersGroups.filter((answerGroup: any) => answerGroup._id !== action.payload.id),
                                    ],
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionMovedUp.toString()]: (state, action: Action<MysteryTestHotelQuestionMovedUpPayload>): QuestionListState => {
            const parentParentPos = state.mysteryTestHotelConfiguration.categories.findIndex((category: any) => category._id === action.payload.parentParentId);
            const parentPos = state.mysteryTestHotelConfiguration.categories[parentParentPos].questions.findIndex((question: any) => question._id === action.payload.parentId);
            const pos = state.mysteryTestHotelConfiguration.categories[parentParentPos].questions[parentPos].answersGroups.findIndex((answerGroup: any) => answerGroup._id === action.payload.id);
            if (pos === 0) {
                return state;
            }
            const movedList = state.mysteryTestHotelConfiguration.categories[parentParentPos].questions[parentPos].answersGroups;
            const movedElement = state.mysteryTestHotelConfiguration.categories[parentParentPos].questions[parentPos].answersGroups[pos];
            movedList.splice(pos, 1);
            movedList.splice(pos - 1, 0, movedElement);

            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                if (subCategory._id !== action.payload.parentId) {
                                    return subCategory;
                                }
                                return {
                                    ...subCategory,
                                    answersGroups: movedList,
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionMovedDown.toString()]: (state, action: Action<MysteryTestHotelQuestionMovedDownPayload>): QuestionListState => {
            const parentParentPos = state.mysteryTestHotelConfiguration.categories.findIndex((category: any) => category._id === action.payload.parentParentId);
            const parentPos = state.mysteryTestHotelConfiguration.categories[parentParentPos].questions.findIndex((question: any) => question._id === action.payload.parentId);
            const pos = state.mysteryTestHotelConfiguration.categories[parentParentPos].questions[parentPos].answersGroups.findIndex((answerGroup: any) => answerGroup._id === action.payload.id);
            if (pos === state.mysteryTestHotelConfiguration.categories[parentParentPos].questions[parentPos].answersGroups[pos].length - 1) {
                return state;
            }
            const movedList = state.mysteryTestHotelConfiguration.categories[parentParentPos].questions[parentPos].answersGroups;
            const movedElement = state.mysteryTestHotelConfiguration.categories[parentParentPos].questions[parentPos].answersGroups[pos];
            movedList.splice(pos, 1);
            movedList.splice(pos + 1, 0, movedElement);

            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                if (subCategory._id !== action.payload.parentId) {
                                    return subCategory;
                                }
                                return {
                                    ...subCategory,
                                    answersGroups: movedList,
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionChanged.toString()]: (state, action: Action<MysteryTestHotelQuestionChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((question: any) => {
                                return {
                                    ...question,
                                    answersGroups: question.answersGroups.map((answerGroup: any) => {
                                        if (answerGroup._id !== action.payload.id) {
                                            return answerGroup;
                                        }
                                        return {
                                            ...answerGroup,
                                            label: action.payload.label,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionTypeChanged.toString()]: (state, action: Action<MysteryTestHotelQuestionTypeChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((question: any) => {
                                        if (question._id !== action.payload.id) {
                                            return question;
                                        }
                                        return {
                                            ...question,
                                            answer_type: action.payload.type,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionRequiredChanged.toString()]: (state, action: Action<MysteryTestHotelQuestionRequiredChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((question: any) => {
                                        if (question._id !== action.payload.id) {
                                            return question;
                                        }
                                        return {
                                            ...question,
                                            required: action.payload.required,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionDependencyQuestionChanged.toString()]: (state, action: Action<MysteryTestHotelQuestionDependencyQuestionChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((question: any) => {
                                        if (question._id !== action.payload.id) {
                                            return question;
                                        }
                                        return {
                                            ...question,
                                            dependency: {
                                                ...question.dependency,
                                                on: action.payload.questionId === 'keine_Auswahl' ? '' : action.payload.questionId,
                                                value: '',
                                            },
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionDependencyValueChanged.toString()]: (state, action: Action<MysteryTestHotelQuestionDependencyQuestionValueChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((question: any) => {
                                        if (question._id !== action.payload.id) {
                                            return question;
                                        }
                                        return {
                                            ...question,
                                            dependency: {
                                                ...question.dependency,
                                                value: action.payload.valueId,
                                            },
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelQuestionVisibleForChanged.toString()]: (state, action: Action<MysteryTestHotelQuestionVisibleForPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((question: any) => {
                                        if (question._id !== action.payload.id) {
                                            return question;
                                        }
                                        return {
                                            ...question,
                                            answers: question.answers.map((answer: any) => {
                                                return {
                                                    ...answer,
                                                    visibleFor: action.payload.valueArray,
                                                };
                                            }),
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelAnswerAdded.toString()]: (state, action: Action<MysteryTestHotelAnswerAddedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((answersGroup: any) => {
                                        if (answersGroup._id !== action.payload.parentId) {
                                            return answersGroup;
                                        }
                                        return {
                                            ...answersGroup,
                                            answers: [
                                                ...answersGroup.answers,
                                                {
                                                    _id: uuidv4(),
                                                    label: '"Antwort"',
                                                    visibleFor: action.payload.type === 'hotel' ? ['hotel'] : [],
                                                    points: 0,
                                                },
                                            ],
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelAnswerDeleted.toString()]: (state, action: Action<MysteryTestHotelAnswerDeletedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((answersGroup: any) => {
                                        if(answersGroup._id !== action.payload.parentId) {
                                            return answersGroup;
                                        }
                                        return {
                                            ...answersGroup,
                                            answers: answersGroup.answers.filter((answer: any) => answer._id !== action.payload.id),
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelAnswerMovedUp.toString()]: (state, action: Action<MysteryTestHotelAnswerMovedUpPayload>): QuestionListState => {
            const pppPos = state.mysteryTestHotelConfiguration.categories.findIndex((category: any) => category._id === action.payload.pppId);
            const ppPos = state.mysteryTestHotelConfiguration.categories[pppPos].questions.findIndex((question: any) => question._id === action.payload.ppId);
            const pPos = state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups.findIndex((answerGroup: any) => answerGroup._id === action.payload.pId);
            const pos = state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups[pPos].answers.findIndex((answerGroup: any) => answerGroup._id === action.payload.id);
            if (pos === 0) {
                return state;
            }
            const movedList = state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups[pPos].answers;
            const movedElement = state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups[pPos].answers[pos];
            movedList.splice(pos, 1);
            movedList.splice(pos - 1, 0, movedElement);

            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((answersGroup: any) => {
                                        if(answersGroup._id !== action.payload.pId) {
                                            return answersGroup;
                                        }
                                        return {
                                            ...answersGroup,
                                            answers: movedList,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelAnswerMovedDown.toString()]: (state, action: Action<MysteryTestHotelAnswerMovedDownPayload>): QuestionListState => {
            const pppPos = state.mysteryTestHotelConfiguration.categories.findIndex((category: any) => category._id === action.payload.pppId);
            const ppPos = state.mysteryTestHotelConfiguration.categories[pppPos].questions.findIndex((question: any) => question._id === action.payload.ppId);
            const pPos = state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups.findIndex((answerGroup: any) => answerGroup._id === action.payload.pId);
            const pos = state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups[pPos].answers.findIndex((answerGroup: any) => answerGroup._id === action.payload.id);
            if (pos === state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups[pPos].answers[pos].length) {
                return state;
            }
            const movedList = state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups[pPos].answers;
            const movedElement = state.mysteryTestHotelConfiguration.categories[pppPos].questions[ppPos].answersGroups[pPos].answers[pos];
            movedList.splice(pos, 1);
            movedList.splice(pos + 1, 0, movedElement);

            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((answersGroup: any) => {
                                        if(answersGroup._id !== action.payload.pId) {
                                            return answersGroup;
                                        }
                                        return {
                                            ...answersGroup,
                                            answers: movedList,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelAnswerLabelChanged.toString()]: (state, action: Action<MysteryTestHotelAnswerLabelChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((answersGroup: any) => {
                                        if(answersGroup._id !== action.payload.parentId) {
                                            return answersGroup;
                                        }
                                        return {
                                            ...answersGroup,
                                            answers: answersGroup.answers.map((answer: any) => {
                                                if(answer._id !== action.payload.id) {
                                                    return answer;
                                                }
                                                return {
                                                    ...answer,
                                                    label: action.payload.label,
                                                };
                                            }),
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
        [Event.QuestionList.mysteryTestHotelAnswerPointsChanged.toString()]: (state, action: Action<MysteryTestHotelAnswerPointsChangedPayload>): QuestionListState => {
            return {
                ...state,
                mysteryTestHotelConfiguration: {
                    ...state.mysteryTestHotelConfiguration,
                    categories: state.mysteryTestHotelConfiguration.categories.map((category: any) => {
                        return {
                            ...category,
                            questions: category.questions.map((subCategory: any) => {
                                return {
                                    ...subCategory,
                                    answersGroups: subCategory.answersGroups.map((answersGroup: any) => {
                                        if(answersGroup._id !== action.payload.parentId) {
                                            return answersGroup;
                                        }
                                        return {
                                            ...answersGroup,
                                            answers: answersGroup.answers.map((answer: any) => {
                                                if(answer._id !== action.payload.id) {
                                                    return answer;
                                                }
                                                return {
                                                    ...answer,
                                                    points: action.payload.points,
                                                };
                                            }),
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        },
    },
    questionListInitialState,
);
