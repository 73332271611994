import {Box, Button, Card, Typography} from '@mui/material';
import CategoryStepper from '../CategoryStepper/CategoryStepper';
import QuestionCard from '../QuestionCard/QuestionCard';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import Command from '../../state/actions/command';
import FinalizeSelfAssessmentConfirmation from './FinalizeSelfAssessmentConfirmation';

const styles = {
    flexBox: {
        display: 'flex',
        columnGap: '30px',
    },
    categories: {
        width: '450px',
    },
    questionnaire: {
        padding: 4,
        width: '100%',
    },
    buttons: {
        marginLeft: '20px',
    },
};

const SelfAssessment = () => {
    const dispatch = useDispatch();
    const selfAssessment = useSelector(Selector.QuestionList.selfAssessment);
    const data = useSelector(Selector.SelfAssessment.data);
    const selfAssessmentSubmitted = useSelector(Selector.SelfAssessment.submitted);
    const [activateCategory, setActivateCategory] = useState<number>(0);
    const [questionData, setQuestionData] = useState<any>({});
    const [confirmInfoOpen, setConfirmInfoOpen] = useState<boolean>(false);


    useEffect(() => {
        if(selfAssessment.categories) {
            let counter = -1;

            selfAssessment.categories.forEach(
                (category: any, index: number) => {
                    const hasAnsweredQuestion = category.questions.some(
                        (question: any) => question.answersGroups.some(
                            (answerGroup: any) => Boolean(data[answerGroup._id]),
                        ),
                    );

                    if (hasAnsweredQuestion && counter === index - 1) {
                        counter = index;
                    }
                },
            );

            if (counter >= 0) {
                setActivateCategory(counter);
            }
        } else {
            setActivateCategory(0);
        }
    }, [selfAssessment.categories]);

    /* eslint-disable */
    const saveAnswer = () => {
        console.log(questionData, selfAssessmentSubmitted);
        if (Object.values(questionData).length > 0 && !selfAssessmentSubmitted) {
            dispatch(Command.SelfAssessment.answerSelfAssessmentQuestion({data: questionData}));
        }
    };

    const enableNextButton = true;
    //const enableNextButton = categories[activateCategory].questions[0]?.answersGroups
    //    && categories[activateCategory].questions[0].answersGroups!.length <= Object.values(questionData).length;

    const isLastCategory = selfAssessment.categories ?
        activateCategory === selfAssessment.categories.length - 1 : true;

    const goToNextQuestion = () => {
        saveAnswer();
        setActivateCategory(isLastCategory ? 0 : activateCategory + 1);
        setQuestionData({});
    };

    const goToPreviousQuestion = () => {
        setActivateCategory(activateCategory - 1);
        setQuestionData({});
    };

    const confirmFinalizeSelfAssessment = () => {
        saveAnswer();
        setConfirmInfoOpen(true);
    };

    const finalizeSelfAssessment = () => {
        dispatch(Command.SelfAssessment.finalizeSelfAssessment({}));
        setConfirmInfoOpen(false);
    };

    return (
        <Box sx={styles.flexBox}>
            {selfAssessment.categories && (
                <>
                    <Box>
                        <CategoryStepper
                            activeCategory={activateCategory}
                            categories={selfAssessment.categories}
                        />
                    </Box>

                    <Card sx={styles.questionnaire}>
                        <QuestionCard
                            additionTextField={true}
                            questions={selfAssessment.categories[activateCategory].questions}
                            data={data}
                            questionData={questionData}
                            onQuestionDataChange={setQuestionData}
                            disabled={selfAssessmentSubmitted}
                        />

                        <Box sx={{ textAlign: 'right' }}>
                            <Button
                                sx={styles.buttons}
                                variant={'outlined'}
                                onClick={goToPreviousQuestion}
                                disabled={activateCategory <= 0}
                            >
                                Zurück
                            </Button>

                            <Button
                                sx={styles.buttons}
                                variant={'contained'}
                                onClick={goToNextQuestion}
                                disabled={(isLastCategory && selfAssessmentSubmitted)}
                            >
                                {(isLastCategory && !selfAssessmentSubmitted) ? 'Speichern' : 'Weiter'}
                            </Button>

                            {(isLastCategory && !selfAssessmentSubmitted) && (<>
                                <Typography component={'span'}>&nbsp; oder &nbsp;</Typography>
                                <Button
                                    variant={'contained'}
                                    onClick={confirmFinalizeSelfAssessment}
                                    disabled={!enableNextButton}
                                >
                                    Jetzt einreichen
                                </Button>
                            </>)}
                        </Box>
                    </Card>
                    <FinalizeSelfAssessmentConfirmation
                        open={confirmInfoOpen}
                        onClose={() => setConfirmInfoOpen(false)}
                        onFinalize={finalizeSelfAssessment}
                    />
                </>
            )}
        </Box>
    );
};

export default SelfAssessment;
