import {
    Menu,
    MenuItem,
} from '@mui/material';
import React from 'react';
interface DownloadReportMenuDialogProps {
    open: boolean;
    mysteryTestId?: string;
    mysteryTestSubmitted?: boolean;
    companyId?: string;
    onClose: () => void;
    anchorEl: null | HTMLElement;
}

const openMysteryTestReport = (mysteryTestId: string) => {
    // @todo cleanup and security
    window.open(window.location.origin + '/api/reports/mystery-test/' + mysteryTestId);
};

const openContract = (companyId: string) => {
    // @todo cleanup and security
    window.open(window.location.origin + '/api/contract/' + companyId);
};

const DownloadReportMenu = (props: DownloadReportMenuDialogProps) => {


    return (
        <Menu
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.onClose}
        >
            {props.mysteryTestSubmitted && props.mysteryTestId && (
                <>
                    <MenuItem
                        onClick={() => {
                            openMysteryTestReport(
                                props.mysteryTestId!,
                            );
                            props.onClose();
                        }}
                    >
                        Admin-Report
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            openMysteryTestReport(
                                props.mysteryTestId! + '_company',
                            );
                            props.onClose();
                        }}
                    >
                        Unternehmensreport
                    </MenuItem>
                </>
            )}
            <MenuItem
                onClick={() => {
                    openContract(
                        props.companyId + '',
                    );
                    props.onClose();
                }}
            >
                Buchungsvertrag
            </MenuItem>
            <MenuItem
                onClick={() => {
                    openContract(
                        props.companyId + '_voucher',
                    );
                    props.onClose();
                }}
            >
                Gutschein
            </MenuItem>
        </Menu>
    );
};

export default DownloadReportMenu;
