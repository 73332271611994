import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {v4 as uuidv4} from 'uuid';
import {Selector} from '../../selector';
import {StartWebsiteCheckPayload} from '../../actions/websiteCheckCommand';

const onStartWebsiteCheck = function*(data: StartWebsiteCheckPayload) {
    const websiteCheckId = uuidv4();
    const companyId: string|null = yield select(Selector.Company.selectedCompanyId);

    if (!companyId) {
        return;
    }

    try {
        yield call(Api.Command.startNewWebsiteCheck, {
            websiteCheckId,
            companyId,
        });

        yield put(Event.WebsiteCheck.websiteCheckStarted({
            websiteCheckId,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Website-Check konnte nicht gestartet werden.',
        }));
    }
};

export function* startWebsiteCheckFlow() {
    while (true) {
        const action: Action<StartWebsiteCheckPayload> = yield take(
            Command.WebsiteCheck.startWebsiteCheck.toString(),
        );

        yield fork(onStartWebsiteCheck, action.payload);
    }
}
