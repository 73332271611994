import {fork, put, take} from 'redux-saga/effects';
import Command from '../../actions/command';
import Event from '../../actions/event';

const onStopKeepAlive = function* () {
    yield put(Event.User.keepAliveStopped({}));
};

export function* stopKeepAliveFlow() {
    while (true) {
        yield take(Command.User.stopKeepAlive);
        yield fork(onStopKeepAlive);
    }
}
