import {createAction} from 'redux-actions';

const createMysteryTestCommand = <T>(type: string) => createAction<T>('_C/MT/' + type);

export interface FetchMysteryTestPayload {
    companyId: string;
}

export interface StartMysteryTestPayload {
}

export interface UpdateMysteryTestDataPayload {
    data: object;
}

export interface FinalizeMysteryTestPayload {
}

export interface ReopenMysteryTestPayload {
}

export interface StartNewMysteryTestAttemptPayload {
}

/* eslint-disable max-len */
const MysteryTestCommand = {
    fetchMysteryTest: createMysteryTestCommand<FetchMysteryTestPayload>('FETCH_MYSTERY_TEST'),
    startMysteryTest: createMysteryTestCommand<StartMysteryTestPayload>('START_MYSTERY_TEST'),
    updateMysteryTestData: createMysteryTestCommand<UpdateMysteryTestDataPayload>('UPDATE_MYSTERY_TEST_DATA'),
    finalizeMysteryTest: createMysteryTestCommand<FinalizeMysteryTestPayload>('FINALIZE_MYSTERY_TEST'),
    reopenMysteryTest: createMysteryTestCommand<ReopenMysteryTestPayload>('REOPEN_MYSTERY_TEST'),
    startNewMysteryTestAttempt: createMysteryTestCommand<StartNewMysteryTestAttemptPayload>('START_NEW_MYSTERY_TEST_ATTEMPT'),
};

export default MysteryTestCommand;
