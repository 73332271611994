import React, {useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import PageLayout from './layout/PageLayout';
import DashboardPage from './components/dashboard/DashboardPage';
import AdminPage from './components/Admin/AdminPage';
import {useDispatch} from 'react-redux';
import Command from './state/actions/command';
import SelfAssessmentPage from './components/SelfAssessment/SelfAssessmentPage';
import MysterytestHotelPage from './components/MysterytestHotel/MysterytestHotelPage';
import MysterytestRestaurantPage from './components/MysterytestRestaurant/MysterytestRestaurantPage';
import WebsiteCheckPage from './components/WebsiteCheck/WebsiteCheckPage';
import BaseDataPage from './components/baseData/BaseDataPage';
import EditMysteryTest from './components/QuestionEditor/EditMysteryTest';
import RegisterPage from './components/Register/RegisterPage';

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Command.Company.fetchCompanyList({}));
        dispatch(Command.User.startKeepAlive({}));
        dispatch(Command.QuestionList.fetchTestList({}));
    }, []);

    return (
        <Routes>
            <Route path={'/'} element={<PageLayout />}>
                <Route path={'/dashboard'} element={<DashboardPage />} />
                <Route path={'/companies'} element={<AdminPage />} />
                <Route path={'/companies/:companyId/base-data'} element={<BaseDataPage />} />
                <Route path={'/companies/:companyId/self-assessment'} element={<SelfAssessmentPage />} />
                <Route path={'/companies/:companyId/website-check'} element={<WebsiteCheckPage />} />
                <Route
                    path={'/companies/:companyId/mysterytest-hotel'}
                    element={<MysterytestHotelPage testEnvironment={false} />}
                />
                <Route
                    path={'/companies/:companyId/mysterytest-restaurant'}
                    element={<MysterytestRestaurantPage testEnvironment={false} />}
                />
                <Route
                    path={'/questioneditor/mysterytest-hotel'}
                    element={<EditMysteryTest type={'hotel'} />}
                />
                <Route
                    path={'/questioneditor/mysterytest-hotel/test-config'}
                    element={<MysterytestHotelPage testEnvironment={true} />}
                />
                <Route
                    path={'/questioneditor/mysterytest-restaurant'}
                    element={<EditMysteryTest type={'restaurant'} />}
                />
                <Route
                    path={'/questioneditor/mysterytest-restaurant/test-config'}
                    element={<MysterytestRestaurantPage testEnvironment={true} />}
                />

                <Route
                    path={'/companies/registration'}
                    element={<RegisterPage />}
                />

                <Route index={true} element={<Navigate to={'/companies'} replace={true} />} />
            </Route>
        </Routes>
    );
};

export default App;
