import {Box, Button, Card, IconButton, Tooltip, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import { CSVLink } from 'react-csv';
import AddNewCompanyDialog from './AddNewCompanyDialog';
import {
    DataGrid,
    GridColDef,
    GridCsvGetRowsToExportParams,
    GridRenderCellParams,
    GridRowsProp,
    GridToolbar,
    GridValueFormatterParams,
    gridSortedRowIdsSelector,
} from '@mui/x-data-grid';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import {useNavigate} from 'react-router';
import ManageCompanyEmployeesDialog from './ManageCompanyEmployeesDialog';
import QuizIcon from '@mui/icons-material/Quiz';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SetCompanyTesterDialog from './SetCompanyTesterDialog';
import {CompanyStatus} from '../../state/types/Company';
import CompanyStatusOverview from './AdminPage/CompanyStatusOverview';
import Api from '../../api';
import SetTrustYouScoreDialog from './SetTrustYouScoreDialog';
import Assets from '../../assets/assets';
import DownloadReportMenu from './DownloadReportMenu';
import SetCompanyHiddenDialog from './SetCompanyHiddenDialog';
import Command from '../../state/actions/command';
import {DateTime} from 'luxon';
import {testCategories} from '../../util/testCategoryType';
import { Link } from 'react-router-dom';
import RerenderCompanyContractDialog from './RerenderCompanyContractDialog';

const styles = {
    root: {
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
};

const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
    gridSortedRowIdsSelector(apiRef);

const AdminPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyList = useSelector(Selector.Company.companyList);
    const userList = useSelector(Selector.User.users);
    const companyStatusMap = useSelector(Selector.Company.companyStatusMap);
    const userId = useSelector(Selector.User.userId);
    const isAdmin = useSelector(Selector.User.isAdmin);
    const isEditor = useSelector(Selector.User.isEditor);
    const isTester = useSelector(Selector.User.isTester);
    const activeVersion = useSelector(Selector.QuestionList.activeVersion);
    const [addNewCompanyDialogOpen, setAddNewCompanyDialogOpen] = useState<boolean>(false);
    const [companyIdToManageEmployeesFor, setCompanyIdToManageEmployeesFor] = useState<string>();
    const [companyIdToSetTesterFor, setCompanyIdToSetTesterFor] = useState<string>();
    const [companyIdToSetTrustYouScore, setCompanyIdToSetTrustYouScore] = useState<string>();
    const [companyStatusToSetTrustYouScore, setCompanyStatusToSetTrustYouScore] = useState<any>();
    const [companyIdToSetHidden, setCompanyIdToSetHidden] = useState<string>();
    const [companyIdToRerenderContract, setCompanyIdToRerenderContract] = useState<string>();
    const [mysteryTestIdToDownloadReport, setMysteryTestIdToDownloadReport] = useState<string>();
    const [companyIdToDownloadContract, setCompanyIdToDownloadContract] = useState<string>();
    const [downloadReportButton, setDownloadReportButton] = React.useState<null | HTMLElement>(null);
    const [reportAvailable, setReportAvailable] = useState<boolean>(false);
    const downloadReportOpen = Boolean(downloadReportButton);

    const loadUserData = async () => {
        const userIds: any[] = [];
        const loadedUsers: any[] = [];
        Object.keys(userList).forEach(key => {
            loadedUsers.push(key);
        });
        companyList.forEach(company => {
            if (!company.hidden && company.tester &&
                !userIds.includes(company.tester) && !loadedUsers.includes(company.tester))
            {
                userIds.push(company.tester);
                dispatch(Command.User.fetchUser({userId: company.tester}));
            }
        });
    };
    useEffect(() => {
        if (companyList !== []) {
            loadUserData();
        }
    }, [companyList]);

    const generateMysteryTestReport = (mysteryTestId: string) => {
        // @todo run through sagas
        Api.Command.generateMysteryTestReport({ mysteryTestId });
    };

    const rows: GridRowsProp = companyList.filter(company => !company.hidden).map(company => ({
        ...company,
        companyStatus: companyStatusMap[company.id],
    }));

    /* eslint-disable max-len */
    const columns: GridColDef[] = [
        { field: 'restaurantName', headerName: 'Unternehmen', minWidth: 200, flex: 3 },
        { field: 'zip', headerName: 'Plz', minWidth: 133, flex: 2, hide: true},
        { field: 'city', headerName: 'Ort', minWidth: 133, flex: 2 },
        { field: 'street', headerName: 'Strasse', minWidth: 133, flex: 2, hide: true },
        { field: 'phone', headerName: 'Telefon', minWidth: 133, flex: 2 },
        { field: 'email', headerName: 'E-Mail', minWidth: 200, flex: 3 },
        { field: 'contactPerson', headerName: 'Ansprechpartner', minWidth: 200, flex: 3 },
        { field: 'travelRegion', headerName: 'Reiseregion', minWidth: 133, flex: 2 },
        {
            field: 'testCategory',
            headerName: 'Testkategorie',
            minWidth: 133,
            flex: 2,
            hide: true,
            renderCell: (params: GridRenderCellParams) => {
                return testCategories[params.row.testCategory ?? ''] ?? '-';
            },
        },
        { field: 'lastModified', headerName: 'Letzte Aktualisierung', minWidth: 133, flex: 2, disableExport: true },
        {
            field: 'companyStatus',
            headerName: 'Status',
            width: 150,
            sortable: false,
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => {
                const companyStatus: CompanyStatus|null = params.row.companyStatus;

                if (!companyStatus) {
                    return null;
                }

                /* TO-DO : unschärfe(0) / Gesamteindruck(0) für die Berechnung berücksichtigen */
                return <CompanyStatusOverview status={companyStatus} version={activeVersion} />;
            },
            valueGetter: params => {
                let value = '';

                value += !params.row.companyStatus?.selfAssessment[activeVersion] ? 'SA: nicht begonnen'
                    : params.row.companyStatus?.selfAssessment[activeVersion].submitted ? 'SA: eingereicht' : 'SA: begonnen';
                value += '\n';
                value += !params.row.companyStatus?.websiteCheck[activeVersion] ? 'WC: nicht begonnen'
                    : params.row.companyStatus?.websiteCheck[activeVersion].submitted ? 'WC: eingereicht' : 'WC: begonnen';
                value += '\n';
                value += !params.row.companyStatus?.mysteryTest[activeVersion] ? 'MT: nicht begonnen'
                    : params.row.companyStatus?.mysteryTest[activeVersion].submitted ? 'MT: eingereicht' : 'MT: begonnen';
                value += '\n';
                value += !params.row.companyStatus?.trustYouScore[activeVersion] ? 'TY: nicht begonnen'
                    : params.row.companyStatus?.trustYouScore[activeVersion]?.score ? 'TY: eingereicht' : 'TY: begonnen';

                return value;
            },
        },
        {
            field: 'selfAssassmentScoreExport',
            headerName: 'selfAssassment',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                value += (companyStatus.selfAssessment[activeVersion] && companyStatus.selfAssessment[activeVersion].submitted) ?
                    companyStatus.selfAssessment[activeVersion].score : ' - ';
                return value;
            },
        },
        {
            field: 'selfAssassmentMaxScoreExport',
            headerName: 'von maximal',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                value += (companyStatus.selfAssessment[activeVersion] && companyStatus.selfAssessment[activeVersion].submitted) ?
                    companyStatus.selfAssessment[activeVersion].achievableScore : ' - ';
                return value;
            },
        },
        {
            field: 'selfAssassmentPercentExport',
            headerName: 'Prozentsatz',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.selfAssessment[activeVersion] && companyStatus.selfAssessment[activeVersion].submitted){
                    const percent = Math.round(
                        (100 * companyStatus.selfAssessment[activeVersion].score!) /
                        (companyStatus.selfAssessment[activeVersion].achievableScore!));
                    value += percent;
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'selfAssessmentStartDate',
            headerName: 'Begonnen',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.selfAssessment[activeVersion]){
                    const dateString = companyStatus.selfAssessment[activeVersion].startedAt;
                    if(!dateString) {
                        return '';
                    }
                    value += DateTime.fromISO(dateString).toFormat('dd.MM.yyyy HH:mm');
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'selfAssessmentFinishedDate',
            headerName: 'Abgeschlossen',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.selfAssessment[activeVersion] && companyStatus.selfAssessment[activeVersion].submitted){
                    const dateString = companyStatus.selfAssessment[activeVersion].finishedAt;
                    if(!dateString) {
                        return '';
                    }
                    value += DateTime.fromISO(dateString).toFormat('dd.MM.yyyy HH:mm');
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'websiteCheckScoreExport',
            headerName: 'websiteCheck',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                value += companyStatus.websiteCheck[activeVersion] && companyStatus.websiteCheck[activeVersion].submitted ?
                    companyStatus.websiteCheck[activeVersion].score : ' - ';
                return value;
            },

        },
        {
            field: 'websiteCheckMaxScoreExport',
            headerName: 'von maximal',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                value += companyStatus.websiteCheck[activeVersion] && companyStatus.websiteCheck[activeVersion].submitted ?
                    companyStatus.websiteCheck[activeVersion].achievableScore : ' - ';
                return value;
            },

        },
        {
            field: 'websiteCheckPercentExport',
            headerName: 'Prozentsatz',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.websiteCheck[activeVersion] && companyStatus.websiteCheck[activeVersion].submitted){
                    const percent = Math.round(
                        (100 * companyStatus.websiteCheck[activeVersion].score!) /
                        (companyStatus.websiteCheck[activeVersion].achievableScore!));
                    value += percent;
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'websiteCheckStartDate',
            headerName: 'Begonnen',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.websiteCheck[activeVersion]){
                    const dateString = companyStatus.websiteCheck[activeVersion].startedAt;
                    if(!dateString) {
                        return '';
                    }
                    value += DateTime.fromISO(dateString).toFormat('dd.MM.yyyy HH:mm');
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'websiteCheckFinishedDate',
            headerName: 'Abgeschlossen',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.websiteCheck[activeVersion] && companyStatus.websiteCheck[activeVersion].submitted){
                    const dateString = companyStatus.websiteCheck[activeVersion].finishedAt;
                    if(!dateString) {
                        return '';
                    }
                    value += DateTime.fromISO(dateString).toFormat('dd.MM.yyyy HH:mm');
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'mysteryTestScoreExport',
            headerName: 'mysteryTest',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                value += companyStatus.mysteryTest[activeVersion] && companyStatus.mysteryTest[activeVersion].submitted ?
                    companyStatus.mysteryTest[activeVersion].score : ' - ';
                return value;
            },

        },
        /* 'Gesamteindruck'(0)(AchievablePoints) and 'unschärfe'(0) of the questions for MysteryTest */
        {
            field: 'mysteryTestMaxScoreExport',
            headerName: 'von maximal',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                value += companyStatus.mysteryTest[activeVersion] && companyStatus.mysteryTest[activeVersion].submitted ?
                    companyStatus.mysteryTest[activeVersion].achievableScore! : ' - ';
                return value;
            },

        },
        {
            field: 'mysteryTestPercentExport',
            headerName: 'Prozentsatz',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.mysteryTest[activeVersion] && companyStatus.mysteryTest[activeVersion].submitted){
                    const percent = Math.round(
                        (100 * companyStatus.mysteryTest[activeVersion].score!) /
                        (companyStatus.mysteryTest[activeVersion].achievableScore!));
                    value += percent;
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'mysteryTestStartDate',
            headerName: 'Begonnen',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.mysteryTest[activeVersion]){
                    const dateString = companyStatus.mysteryTest[activeVersion].startedAt;
                    if(!dateString) {
                        return '';
                    }
                    value += DateTime.fromISO(dateString).toFormat('dd.MM.yyyy HH:mm');
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'mysteryTestFinishedDate',
            headerName: 'Abgeschlossen',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.mysteryTest[activeVersion] && companyStatus.mysteryTest[activeVersion].submitted){
                    const dateString = companyStatus.mysteryTest[activeVersion].finishedAt;
                    if(!dateString) {
                        return '';
                    }
                    value += DateTime.fromISO(dateString).toFormat('dd.MM.yyyy HH:mm');
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'trustYouScoreExport',
            headerName: 'Trust You Score',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                value += companyStatus.trustYouScore[activeVersion] && companyStatus.trustYouScore[activeVersion].score ?
                    companyStatus.trustYouScore[activeVersion].score! : ' - ';
                return value;
            },

        },
        {
            field: 'trustYouScorePercentExport',
            headerName: '% (5.0)',
            sortable: false,
            hide: true,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){return '-';}
                const companyStatus = companyStatusMap[params.id];
                if(companyStatus == null){return '-';}
                if(companyStatus.trustYouScore[activeVersion] && companyStatus.trustYouScore[activeVersion].score){
                    const percent = Math.round(
                        (100 * companyStatus.trustYouScore[activeVersion].score!) /
                        (5));
                    value += percent;
                } else {
                    value += ' - ';
                }
                return value;
            },
        },
        {
            field: 'testerExport',
            headerName: 'Tester',
            width: 0,
            flex: 0,
            sortable: false,
            hide: false,
            renderCell: (params: GridRenderCellParams) => { return ''; },
            valueFormatter: (params: GridValueFormatterParams) => {
                let value = '';
                if(!params.id){
                    return '-';
                } else {
                    const company = companyList.find(company => company.id === params.id);
                    if(company && company.tester && userList[company.tester]){
                        const user = userList[company.tester];
                        value += user.email + ', ' + user.firstName + ' ' + user.lastName;
                    } else {
                        value += ' - ';
                    }
                    return value;
                }
            },
        },
        {
            field: 'actions',
            headerName: 'Aktionen',
            width: isAdmin ? 436 : 156, // + 40 for each additional action IconButton
            sortable: false,
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => {
                const showEditAction = Boolean(isAdmin || params.row.employees.includes(userId));
                const showViewAction = !showEditAction && isTester && params.row.tester === userId;
                const companyStatus: CompanyStatus|null = params.row.companyStatus;

                return (<>
                    {showEditAction && (
                        <Button
                            variant={'contained'}
                            size={'small'}
                            children={'Stammdaten'}
                            startIcon={<EditIcon />}
                            onClick={() => navigate(`/companies/${params.row.id}/base-data`)}
                        />
                    )}
                    {showViewAction && (
                        <Button
                            variant={'contained'}
                            size={'small'}
                            children={'Details anzeigen'}
                            onClick={() => navigate(`/companies/${params.row.id}/base-data`)}
                        />
                    )}
                    {isAdmin && (
                        <Tooltip title={'Mitarbeiter verwalten'}>
                            <IconButton onClick={() => setCompanyIdToManageEmployeesFor(params.row.id)}>
                                <PersonIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isAdmin && (
                        <Tooltip title={'Tester auswählen'}>
                            <IconButton onClick={() => setCompanyIdToSetTesterFor(params.row.id)}>
                                <QuizIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isAdmin && (
                        <Tooltip title={'Report generieren'}>
                            <IconButton
                                onClick={() => {
                                    generateMysteryTestReport(companyStatus!.mysteryTest[activeVersion]!.mysteryTestId);
                                }}
                                disabled={!companyStatus?.mysteryTest[activeVersion]?.submitted}
                                children={<SummarizeIcon />}
                            />
                        </Tooltip>
                    )}
                    {isAdmin && (
                        <Tooltip
                            title={!companyStatus?.mysteryTest?.[activeVersion]?.submitted ?
                                'Vertrag herunterladen' : 'Report/Vertrag herunterladen'}
                        >
                            <IconButton
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    setMysteryTestIdToDownloadReport(companyStatus!.mysteryTest?.[activeVersion]?.mysteryTestId);
                                    setCompanyIdToDownloadContract(params.row.id!);
                                    setDownloadReportButton(event.currentTarget);
                                    setReportAvailable(!!companyStatus?.mysteryTest?.[activeVersion]?.submitted);
                                }}
                                children={
                                    <DownloadIcon
                                        color={
                                            !companyStatus?.mysteryTest[activeVersion]?.submitted ?
                                                'action' : 'primary'
                                        }
                                    />
                                }
                            />
                        </Tooltip>
                    )}
                    {isAdmin && (
                        <Tooltip title={'Vertrag neu generieren'}>
                            <IconButton onClick={() => setCompanyIdToRerenderContract(params.row.id)}>
                                <SummarizeIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isAdmin && (
                        <Tooltip title={'TrustYou Score setzen'}>
                            <IconButton
                                onClick={() => {
                                    setCompanyStatusToSetTrustYouScore(params.row.companyStatus!);
                                    setCompanyIdToSetTrustYouScore(params.row.id!);}
                                }
                                children={(
                                    <img
                                        src={Assets.trustYouBubbles}
                                        alt={'trustYouScore'}
                                        width={24}
                                        height={24}
                                        style={{
                                            objectFit: 'contain',
                                            filter: Boolean(companyStatus?.trustYouScore[activeVersion]) ? 'grayscale(1)' : undefined,
                                        }}
                                    />
                                )}
                            />
                        </Tooltip>
                    )}
                    {isAdmin && (
                        <Tooltip title={'Unternehmen Ausblenden'}>
                            <IconButton
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    setCompanyIdToSetHidden(params.row.id!);
                                }}
                                disabled={params.row.hidden}
                                children={<VisibilityOffIcon />}
                            />
                        </Tooltip>
                    )}
                    {isEditor && !!companyStatus?.mysteryTest?.[activeVersion]?.submitted && (
                        <Tooltip title={'Unternehmensreport'}>
                            <IconButton
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    window.open(window.location.origin + '/api/reports/mystery-test/' +
                                        companyStatus!.mysteryTest?.[activeVersion]?.mysteryTestId + '_company');
                                }}
                                children={<DownloadIcon color={'primary'} />}
                            />
                        </Tooltip>
                    )}
                </>);
            },
        },
    ];

    // Overview and BG-Score Export
    const headers = [
        { label: 'Betrieb', key: 'name' },
        { label: 'E-Mail', key: 'email' },
        { label: 'Kontakt', key: 'contact' },
        { label: 'Mystery Test', key: 'mysteryTest' },
        { label: 'Mystery Test in %', key: 'mysteryTestPercentage' },
        { label: 'Wichtung Mystery Test', key: 'mysteryTestWeight' },
        { label: 'Versuche Mystery  Test', key: 'mysteryTestTrys' },
        { label: 'TrustYou Score', key: 'trustYouScore' },
        { label: 'TrustYou Score in %', key: 'trustYouScorePercentage' },
        { label: 'Wichtung TrustYou Score', key: 'trustYouScoreWeight' },
        { label: 'Selbstauskunft', key: 'selfAssessment' },
        { label: 'Selbstauskunft in %', key: 'selfAssessmentPercentage' },
        { label: 'Wichtung Selbstauskunft', key: 'selfAssessmentWeight' },
        { label: 'Website-Check', key: 'websiteCheck' },
        { label: 'Website-Check in %', key: 'websiteCheckPercentage' },
        { label: 'Wichtung Website-Check', key: 'websiteCheckWeight' },
        { label: 'BG Score', key: 'BGScore' },
        { label: 'Kategorie', key: 'testCategory' },
        { label: 'Reiseregion', key: 'travelRegion' },
    ];
    const data = companyList.filter(company => !company.hidden).map(company => {
        const companyStatus = companyStatusMap[company.id];
        const mt = companyStatus?.mysteryTest[activeVersion];
        const sa = companyStatus?.selfAssessment[activeVersion];
        const wc = companyStatus?.websiteCheck[activeVersion];
        const ts = companyStatus?.trustYouScore[activeVersion];

        const mtP = mt?.score && mt?.achievableScore ?
            Number((mt.score * 100 / mt.achievableScore).toFixed(2)) : 0;
        const tsP = ts?.score ?
            Number((ts.score * 100 / 5).toFixed(2)) : 0;
        const saP = sa?.score && sa?.achievableScore ?
            Number((sa.score * 100 / sa.achievableScore).toFixed(2)) : 0;
        const wcP = wc?.score && wc?.achievableScore ?
            Number((wc.score * 100 / wc.achievableScore).toFixed(2)) : 0;

        const mtW = 0.6, saW = 0.1, wcW = 0.1, tsW = 0.2;

        const bgScore = (mtP * mtW) + (saP * saW) + (wcP * wcW) + (tsP * tsW);

        return {
            name: company.restaurantName,
            email: company.email,
            contact: company.contactPerson,
            mysteryTest: mt?.score && mt?.achievableScore ? `${mt.score} / ${mt.achievableScore}` : '-',
            mysteryTestPercentage: `${mtP.toFixed(2)}%`,
            mysteryTestWeight: mtW,
            mysteryTestTrys: mt?.attempt || 1,
            trustYouScore: ts?.score ? `${ts.score.toFixed(3)} / 5` : '-',
            trustYouScorePercentage: `${tsP.toFixed(2)}%`,
            trustYouScoreWeight: tsW,
            selfAssessment: sa?.score && sa?.achievableScore ? `${sa.score} / ${sa.achievableScore}` : '-',
            selfAssessmentPercentage: `${saP.toFixed(2)}%`,
            selfAssessmentWeight: saW,
            websiteCheck: wc?.score && wc?.achievableScore ? `${wc.score} / ${wc.achievableScore}` : '-',
            websiteCheckPercentage: `${wcP.toFixed(2)}%`,
            websiteCheckWeight: wcW,
            BGScore: `${bgScore.toFixed(2)}%`,
            testCategory: company.testCategory ? testCategories[company.testCategory] : '-',
            travelRegion: company.travelRegion,
        };
    });
    const date = DateTime.now().toFormat('yyyyMMdd-HHmmss');
    const csvReport = {
        data: data,
        headers: headers,
        separator: ';',
        filename: `${date}-score-export.csv`,
    };

    return (
        <Box sx={styles.root}>
            <Box>
                <Typography variant={'h1'}>
                    Teilnehmende Unternehmen
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                    {rows.length === 0 && !isAdmin && (
                        <Link 
                            to={'/companies/registration'}
                            style={{ textDecoration: 'none', color: 'red' }}
                        >
                            <Button 
                                children={'Unternehmen registrieren'}
                                variant={'contained'}
                            />
                        </Link>
                    )}
                    {isAdmin && (
                        <Button
                            children={'Gesamt Report'}
                            variant={'contained'}
                            onClick={() => {
                                window.open(window.location.origin + '/api/reports/over-all-report/preview');
                            }}
                        />
                    )}
                    {isAdmin && (
                        <CSVLink {...csvReport} onClick={() => {}}>
                            <Button
                                children={'Übersicht exportieren'}
                                variant={'contained'}
                            />
                        </CSVLink>
                    )}
                    {isAdmin && (
                        <Button
                            onClick={() => setAddNewCompanyDialogOpen(true)}
                            children={'Unternehmen hinzufügen'}
                            variant={'contained'}
                        />
                    )}
                </Box>
            </Box>
            <Card sx={{ marginTop: 2, flex: 1, width: '100%' }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    disableSelectionOnClick={true}
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    disableColumnMenu={true}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 200 },
                            csvOptions: {
                                delimiter: ';',
                                fileName: 'Brandenburger Gastlichkeit',
                                allColumns: true,
                                getRowsToExport: getUnfilteredRows,
                                disableToolbarButton: !isAdmin,
                            },
                            printOptions: { disableToolbarButton: true },
                        },
                    }}
                />
            </Card>
            <AddNewCompanyDialog
                open={addNewCompanyDialogOpen}
                onClose={() => setAddNewCompanyDialogOpen(false)}
            />
            <ManageCompanyEmployeesDialog
                open={Boolean(companyIdToManageEmployeesFor)}
                companyId={companyIdToManageEmployeesFor}
                onClose={() => setCompanyIdToManageEmployeesFor(undefined)}
            />
            <SetCompanyTesterDialog
                open={Boolean(companyIdToSetTesterFor)}
                companyId={companyIdToSetTesterFor}
                onClose={() => setCompanyIdToSetTesterFor(undefined)}
            />
            {Boolean(companyIdToSetTrustYouScore && companyStatusToSetTrustYouScore) &&
                <SetTrustYouScoreDialog
                    open={Boolean(companyIdToSetTrustYouScore && companyStatusToSetTrustYouScore)}
                    companyId={companyIdToSetTrustYouScore}
                    companyStatus={companyStatusToSetTrustYouScore}
                    version={activeVersion}
                    onClose={() => {
                        setCompanyIdToSetTrustYouScore(undefined);
                        setCompanyStatusToSetTrustYouScore(undefined);
                    }}
                />
            }
            <DownloadReportMenu
                open={downloadReportOpen}
                mysteryTestId={mysteryTestIdToDownloadReport}
                mysteryTestSubmitted={reportAvailable}
                companyId={companyIdToDownloadContract}
                anchorEl={downloadReportButton}
                onClose={() => {
                    setDownloadReportButton(null);
                    setMysteryTestIdToDownloadReport(undefined);
                }}
            />
            {isAdmin && (
                <RerenderCompanyContractDialog
                    open={Boolean(companyIdToRerenderContract)}
                    companyId={companyIdToRerenderContract}
                    onClose={() => {setCompanyIdToRerenderContract(undefined);}}
                />
            )}
            <SetCompanyHiddenDialog
                open={Boolean(companyIdToSetHidden)}
                companyId={companyIdToSetHidden}
                onClose={() => setCompanyIdToSetHidden(undefined)}
            />
        </Box>
    );
};

export default AdminPage;
