import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

interface StartMysteryTestAttemptConfirmationProps {
    open: boolean;
    onClose: () => void;
    onStart: () => void;
}

const StartMysteryTestAttemptConfirmation = (props: StartMysteryTestAttemptConfirmationProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Mystery-Test zurücksetzen
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Möchten Sie den Mystery-Test wirklich zurücksetzen? Die Daten werden vollständig
                    zurückgesetzt und ein erneutes Ausfüllen ist notwendig.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button color={'warning'} onClick={props.onStart} variant={'contained'}>
                    Zurücksetzen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StartMysteryTestAttemptConfirmation;
