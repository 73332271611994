import {Box, Button, Card, Typography} from '@mui/material';
import CategoryStepper from '../CategoryStepper/CategoryStepper';
import MysterytestQuestionCard from './MysterytestQuestionCard';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import Command from '../../state/actions/command';
import {theme} from '../../mui/theme';
import FinalizeMysteryTestConfirmation from './FinalizeMysteryTestConfirmation';
import NotAllRequiredQuestionsAnsweredInfo from './NotAllRequiredQuestionsAnsweredInfo';
import Event from '../../state/actions/event';
import calculateDependentMysteryTest from '../MysterytestHotel/MysterytestHelperDependencyFunctions';

const styles = {
    flexBox: {
        display: 'flex',
        columnGap: '30px',
    },
    categories: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '32px',
        },
    },
    questionnaire: {
        padding: 4,
        width: '100%',
    },
    buttons: {
        marginLeft: '20px',
    },
};

interface MysteryTestHotelProps {
    testEnvironment: boolean;
}

/* eslint-disable max-len */
const MysteryTestHotel = (props: MysteryTestHotelProps) => {
    const dispatch = useDispatch();
    const mysteryTestHotel = useSelector(
        props.testEnvironment ?
            Selector.QuestionList.mysteryTestHotelConfiguration :
            Selector.QuestionList.mysteryTestHotel,
    );
    const data = useSelector(Selector.MysteryTest.data);
    const mysteryTestSubmitted = useSelector(Selector.MysteryTest.submitted);
    const selectedCompany = useSelector(Selector.Company.selectedCompany);

    const [activateCategory, setActivateCategory] = useState<number>(0);
    const [questionData, setQuestionData] = useState<any>({});
    const [confirmInfoOpen, setConfirmInfoOpen] = useState<boolean>(false);
    const [warnRequiredQuestions, setWarnRequiredQuestions] = useState<boolean>(false);
    const [warnInfoOpen, setWarnInfoOpen] = useState<boolean>(false);

    const isLastCategory = mysteryTestHotel.categories ?
        activateCategory === mysteryTestHotel.categories.length - 1 : true;
    const enableNextButton = true;

    const saveAnswer = () => {
        if (Object.values(questionData).length > 0 && !mysteryTestSubmitted) {
            !props.testEnvironment && dispatch(Command.MysteryTest.updateMysteryTestData({data: questionData}));
            props.testEnvironment && dispatch(Event.MysteryTest.mysteryTestDataUpdated({data: questionData}));
        }
    };

    const goToNextQuestion = () => {
        saveAnswer();
        if (answeredAllRequiredQuestions()){
            setActivateCategory(isLastCategory ? 0 : activateCategory + 1);
            setWarnRequiredQuestions(false);
        } else {
            setWarnRequiredQuestions(true);
            setWarnInfoOpen(true);
        }
        setQuestionData({});
    };

    const goToPreviousQuestion = () => {
        saveAnswer();
        setActivateCategory(activateCategory - 1);
        setQuestionData({});
        setWarnRequiredQuestions(false);
    };

    const confirmFinalizeMysteryTest = () => {
        saveAnswer();
        if (answeredAllRequiredQuestions()){
            setConfirmInfoOpen(true);
        } else {
            setWarnRequiredQuestions(true);
            setWarnInfoOpen(true);
        }
        setQuestionData({});
    };

    const finalizeMysteryTest = () => {
        !props.testEnvironment && dispatch(Command.MysteryTest.finalizeMysteryTest({}));
        setConfirmInfoOpen(false);
    };

    const answeredAllRequiredQuestions = (): boolean => {
        let allAnswered = true;
        if (props.testEnvironment) {return true;}
        if (!selectedCompany?.testCategory) {return false;}
        const updatedData = {
            ...data,
            ...questionData,
        };

        const activeControllCategory = calculateDependentMysteryTest(mysteryTestHotel, updatedData, selectedCompany!.testCategory!).categories[activateCategory];
        activeControllCategory?.questions.forEach((question: any) => {
            question.answersGroups!.forEach((answerGroup: any) => {
                if (answerGroup.required && answerGroup.display && answerGroup.answers.length > 0) {
                    if (!updatedData[answerGroup._id]) {
                        allAnswered = false;
                    }
                    if (answerGroup.answer_type === 'radio' && updatedData[answerGroup._id]?.radio === undefined) {
                        allAnswered = false;
                    } else if (answerGroup.answer_type === 'checkbox') {
                        // To-Do check if checkbox should be required to be answered
                        if (updatedData[answerGroup._id]?.checkbox) {
                            let tmpCheckbox = false;
                            for (const value of Object.entries(updatedData[answerGroup._id].checkbox)) {
                                tmpCheckbox = value ? true : tmpCheckbox;
                            }
                            allAnswered = tmpCheckbox ? allAnswered : false;
                        } else {
                            allAnswered = false;
                        }
                    } else if (answerGroup.answer_type === 'textbox') {
                        if (updatedData[answerGroup._id]?.text === undefined ||
                            updatedData[answerGroup._id]?.text === null ||
                            updatedData[answerGroup._id]?.text === '') {
                            allAnswered = false;
                        }
                    }
                }
            });
        });
        return allAnswered;
    };

    useEffect(() => {
        if (props.testEnvironment && Object.keys(mysteryTestHotel).length === 0) {
            dispatch(Command.QuestionList.fetchQuestionConfiguration(
                {questionConfigurationType: 'mysteryTestRestaurant'},
            ));
        }
    }, [mysteryTestHotel]);

    return (
        <Box sx={styles.flexBox}>
            {mysteryTestHotel.categories && (
                <>
                    <Box>
                        <CategoryStepper
                            activeCategory={activateCategory}
                            categories={mysteryTestHotel.categories}
                        />
                    </Box>

                    <Card sx={styles.questionnaire}>
                        <MysterytestQuestionCard
                            questions={
                                calculateDependentMysteryTest(
                                    mysteryTestHotel,
                                    {...data, ...questionData},
                                    props.testEnvironment ? 'hotel' : selectedCompany!.testCategory!,
                                ).categories[activateCategory].questions
                            }
                            data={data}
                            questionData={questionData}
                            onQuestionDataChange={setQuestionData}
                            isHotel={true}
                            warnRequiredQuestions={warnRequiredQuestions}
                            disabled={mysteryTestSubmitted}
                            testEnvironment={props.testEnvironment}
                            testVisibility={'hotel'}
                        />

                        <Button
                            variant={'outlined'}
                            onClick={goToPreviousQuestion}
                            disabled={activateCategory <= 0}
                        >
                            Zurück
                        </Button>

                        <Button
                            sx={styles.buttons}
                            variant={'contained'}
                            onClick={goToNextQuestion}
                            disabled={!enableNextButton}
                        >
                            {(isLastCategory && !mysteryTestSubmitted && !props.testEnvironment) ?
                                'Speichern' : 'Weiter'}
                        </Button>

                        {(isLastCategory && !mysteryTestSubmitted && !props.testEnvironment) && (<>
                            <Typography component={'span'}>&nbsp; oder &nbsp;</Typography>
                            <Button
                                variant={'contained'}
                                onClick={confirmFinalizeMysteryTest}
                                disabled={!enableNextButton}
                            >
                                Jetzt einreichen
                            </Button>
                        </>
                        )}
                    </Card>
                    <FinalizeMysteryTestConfirmation
                        open={confirmInfoOpen}
                        onClose={() => setConfirmInfoOpen(false)}
                        onFinalize={finalizeMysteryTest}
                    />
                    <NotAllRequiredQuestionsAnsweredInfo
                        open={warnInfoOpen}
                        onClose={() => setWarnInfoOpen(false)}
                    />
                </>
            )}
        </Box>
    );
};

export default MysteryTestHotel;
