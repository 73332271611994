import {WebsiteCheckQuestionType} from '../../data/website-check';
import {Box, Collapse} from '@mui/material';
import WebsiteCheckQuestionConditional from './WebsiteCheckQuestionConditional';
import React from 'react';

interface WebsiteCheckQuestionConditionalsProps {
    question: WebsiteCheckQuestionType;
    value: string|null;
    conditionalsValue: Record<string, string>;
    onChangeConditionalsValue: (newConditionalsValue: Record<string, string>) => void;
    disabled?: boolean;
}

const WebsiteCheckQuestionConditionals = (props: WebsiteCheckQuestionConditionalsProps) => {
    const conditionalsToShow: Record<string, boolean> = {};

    props.question.conditionals?.forEach(conditional => {
        conditionalsToShow[conditional.id] = conditional.if_answer === props.value;
    });

    const updateValue = (conditionalId: string, newValue: string) => {
        props.onChangeConditionalsValue({
            ...props.conditionalsValue,
            [conditionalId]: newValue,
        });
    };

    return (
        <Box>
            {props.question.conditionals && props.question.conditionals.map(conditional => (
                <Collapse in={conditionalsToShow[conditional.id]} key={conditional.id}>
                    <WebsiteCheckQuestionConditional
                        conditional={conditional}
                        value={props.conditionalsValue[conditional.id]}
                        onChange={newValue => updateValue(conditional.id, newValue)}
                        disabled={props.disabled}
                    />
                </Collapse>
            ))}
        </Box>
    );
};

export default WebsiteCheckQuestionConditionals;
