import {Action, handleActions} from 'redux-actions';
import Event from '../actions/event';
import {
    MysteryTestDataUpdatedPayload,
    MysteryTestFetchedPayload, MysteryTestFinalizedPayload,
    MysteryTestNotFoundPayload, MysteryTestReopenedPayload,
    MysteryTestStartedPayload, NewMysteryTestAttemptStartedPayload,
} from '../actions/mysteryTestEvent';
import {MysteryTestType} from '../types/MysteryTest';

export interface MysteryTestState {
    loaded: boolean;
    mysteryTestId: string|null;
    type: MysteryTestType|null;
    data: any;
    submitted: boolean;
    attempt: number;
}

export const mysteryTestInitialState: MysteryTestState = {
    loaded: false,
    mysteryTestId: null,
    type: null,
    data: {},
    submitted: false,
    attempt: 1,
};

/* eslint-disable max-len */
export const mysteryTestReducer = handleActions<MysteryTestState, any>(
    {
        [Event.MysteryTest.stateCleared.toString()]: (): MysteryTestState => {
            return {
                ...mysteryTestInitialState,
            };
        },
        [Event.MysteryTest.mysteryTestFetched.toString()]: (state, action: Action<MysteryTestFetchedPayload>): MysteryTestState => {
            return {
                ...state,
                loaded: true,
                mysteryTestId: action.payload.mysteryTest.mysteryTestId,
                type: action.payload.mysteryTest.type,
                data: action.payload.mysteryTest.data,
                submitted: action.payload.mysteryTest.submitted,
                attempt: action.payload.mysteryTest.attempt,
            };
        },
        [Event.MysteryTest.mysteryTestNotFound.toString()]: (state, action: Action<MysteryTestNotFoundPayload>): MysteryTestState => {
            return {
                ...state,
                loaded: true,
            };
        },
        [Event.MysteryTest.mysteryTestStarted.toString()]: (state, action: Action<MysteryTestStartedPayload>): MysteryTestState => {
            return {
                ...state,
                mysteryTestId: action.payload.mysteryTestId,
                type: action.payload.type,
            };
        },
        [Event.MysteryTest.mysteryTestDataUpdated.toString()]: (state, action: Action<MysteryTestDataUpdatedPayload>): MysteryTestState => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload.data,
                },
            };
        },
        [Event.MysteryTest.mysteryTestFinalized.toString()]: (state, action: Action<MysteryTestFinalizedPayload>): MysteryTestState => {
            return {
                ...state,
                submitted: true,
            };
        },
        [Event.MysteryTest.mysteryTestReopened.toString()]: (state, action: Action<MysteryTestReopenedPayload>): MysteryTestState => {
            return {
                ...state,
                submitted: false,
            };
        },
        [Event.MysteryTest.newMysteryTestAttemptStarted.toString()]: (state, action: Action<NewMysteryTestAttemptStartedPayload>): MysteryTestState => {
            return {
                ...state,
                attempt: state.attempt + 1,
                data: {},
                submitted: false,
            };
        },
    },
    mysteryTestInitialState,
);
