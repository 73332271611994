import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {Selector} from '../../selector';
import {UpdateWebsiteCheckDataPayload} from '../../actions/websiteCheckCommand';

const onUpdateWebsiteCheckData = function*(data: UpdateWebsiteCheckDataPayload) {
    const websiteCheckId: string|null = yield select(Selector.WebsiteCheck.websiteCheckId);

    if (!websiteCheckId) {
        return;
    }

    try {
        yield call(Api.Command.updateWebsiteCheckData, {
            websiteCheckId,
            websiteCheckData: data.data,
        });

        yield put(Event.WebsiteCheck.websiteCheckDataUpdated({
            data: data.data,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Website-Check Daten konnten nicht gespeichert werden.',
        }));
    }
};

export function* updateWebsiteCheckDataFlow() {
    while (true) {
        const action: Action<UpdateWebsiteCheckDataPayload> = yield take(
            Command.WebsiteCheck.updateWebsiteCheckData.toString(),
        );

        yield fork(onUpdateWebsiteCheckData, action.payload);
    }
}
