import {Alert, Box, Card, CardContent, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Remove';
import React from 'react';

const styles = {
    root: {
        padding: 4,
    },
    questionBox: {
        marginBottom: 4,
        padding: 2,
    },
    answerBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    answerIconPositive: (theme: any) => ({
        marginRight: 1,
        color: theme.palette.success.main,
    }),
    answerIconNegative: (theme: any) => ({
        marginRight: 1,
        color: theme.palette.grey,
    }),
};

const WebsiteCheckOverview = () => {
    const selectedCompany = useSelector(Selector.Company.selectedCompany);
    const websiteCheckId = useSelector(Selector.WebsiteCheck.websiteCheckId);
    const data = useSelector(Selector.WebsiteCheck.data);
    const websiteCheck = useSelector(Selector.QuestionList.websiteCheck);

    return (
        <Box sx={styles.root}>
            <Typography sx={{marginBottom: 4}} variant={'h1'}>
                Website-Check: "{
                    (selectedCompany?.name) ?
                        selectedCompany?.name : selectedCompany?.restaurantName
                }"
            </Typography>

            {!websiteCheckId && (
                <Alert severity={'warning'}>
                    Der Website Check wurde noch nicht begonnen.
                </Alert>
            )}

            {websiteCheckId && (
                <Box>
                    {websiteCheck.questions.map((question: any) => (
                        <Card sx={{marginBottom: 4, padding: 2}}>
                            <Typography variant={'h3'}>{question.label}</Typography>
                            <Typography>{question.description}</Typography>
                            <CardContent>
                                {question.answers.map((answer: any) => {
                                    const isSelected = data[question.id] === answer.id;

                                    return (
                                        <Box sx={styles.answerBox}>
                                            {isSelected
                                                ? <CheckIcon sx={styles.answerIconPositive} />
                                                : <CloseIcon sx={styles.answerIconNegative} />
                                            }
                                            <Typography>{answer.label}</Typography>
                                        </Box>
                                    );
                                })}
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default WebsiteCheckOverview;
