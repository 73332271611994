import CompanySelector from './selectors/companySelector';
import UserSelector from './selectors/userSelector';
import SelfAssessmentSelector from './selectors/selfAssessmentSelector';
import WebsiteCheckSelector from './selectors/websiteCheckSelector';
import MysteryTestSelector from './selectors/mysteryTestSelector';
import NotificationSelector from './selectors/notificationSelector';
import QuestionListSelector from './selectors/questionListSelector';

export const Selector = {
    User: UserSelector,
    Company: CompanySelector,
    SelfAssessment: SelfAssessmentSelector,
    WebsiteCheck: WebsiteCheckSelector,
    MysteryTest: MysteryTestSelector,
    Notification: NotificationSelector,
    QuestionList: QuestionListSelector,
};
