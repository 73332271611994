import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../state/actions/command';
import {Selector} from '../../state/selector';
import {ReduxState} from '../../state/reducer';
import UserAvatar from '../common/UserAvatar';

const isEmail = (value: string) => value.length > 5;


interface SetCompanyTesterDialogProps {
    open: boolean;
    companyId?: string;
    onClose: () => void;
}

const SetCompanyTesterDialog = (props: SetCompanyTesterDialogProps) => {
    const dispatch = useDispatch();
    const company = useSelector((state: ReduxState) => Selector.Company.company(state, props.companyId || ''));
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        setTimeout(
            () => {
                setEmail('');
            },
            100,
        );
    }, [props.companyId]);

    const activateSaveButton = isEmail(email);

    const setCompanyTester = () => {
        if (!activateSaveButton || !props.companyId) {
            return;
        }

        dispatch(Command.Company.setCompanyTester({
            companyId: props.companyId,
            email: email,
        }));

        props.onClose();
    };

    const removeCompanyTester = () => {
        if (!props.companyId) {
            return;
        }

        dispatch(Command.Company.removeCompanyTester({
            companyId: props.companyId,
        }));

        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <DialogTitle>Tester auswählen</DialogTitle>
            <DialogContent>
                {company?.tester && (<>
                    <Typography>Bereits hinzugefügter Tester:</Typography>

                    <UserAvatar userId={company.tester} />

                    <Button
                        children={'Entfernen'}
                        startIcon={<ClearIcon />}
                        variant={'contained'}
                        color={'error'}
                        size={'small'}
                        onClick={removeCompanyTester}
                    />
                </>)}
                <Box sx={{ marginTop: 1 }}>
                    <TextField
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        label={'User E-Mail'}
                        variant={'outlined'}
                        fullWidth={true}
                        sx={{ marginTop: 1 }}
                        type={'email'}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button
                    children={'Hinzufügen'}
                    startIcon={<AddIcon />}
                    variant={'contained'}
                    onClick={setCompanyTester}
                    disabled={!activateSaveButton}
                />
            </DialogActions>
        </Dialog>
    );
};

export default SetCompanyTesterDialog;
