import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {Selector} from '../../selector';
import {FinalizeMysteryTestPayload} from '../../actions/mysteryTestCommand';

const onFinalizeMysteryTest = function*(data: FinalizeMysteryTestPayload) {
    const mysteryTestId: string|null = yield select(Selector.MysteryTest.mysteryTestId);

    if (!mysteryTestId) {
        return;
    }

    try {
        yield call(Api.Command.finalizeMysteryTest, {
            mysteryTestId,
        });

        yield put(Event.MysteryTest.mysteryTestFinalized({}));

        yield call(Api.Command.generateMysteryTestReport, {mysteryTestId});
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Mystery-Test konnte nicht finalisiert werden.',
        }));
    }
};

export function* finalizeMysteryTestFlow() {
    while (true) {
        const action: Action<FinalizeMysteryTestPayload> = yield take(
            Command.MysteryTest.finalizeMysteryTest.toString(),
        );

        yield fork(onFinalizeMysteryTest, action.payload);
    }
}
