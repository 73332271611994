import CompanyCommand from './companyCommand';
import UserCommand from './userCommand';
import SelfAssessmentCommand from './selfAssessmentCommand';
import WebsiteCheckCommand from './websiteCheckCommand';
import MysteryTestCommand from './mysteryTestCommand';
import NotificationCommand from './notificationCommand';
import TrustYouScoreCommand from './trustYouScoreCommand';
import QuestionListCommand from './questionListCommand';

const Command = {
    User: UserCommand,
    Company: CompanyCommand,
    SelfAssessment: SelfAssessmentCommand,
    WebsiteCheck: WebsiteCheckCommand,
    MysteryTest: MysteryTestCommand,
    Notification: NotificationCommand,
    TrustYou: TrustYouScoreCommand,
    QuestionList: QuestionListCommand,
};

export default Command;
