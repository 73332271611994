import {Box} from '@mui/material';
import MysteryTestHotel from './MysteryTestHotel';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import StartMysteryTestInfo from './StartMysteryTestInfo';
import MysteryTestHeader from './MysteryTestHeader';

const styles = {
    root: {
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'auto',
    },
};

interface MysteryTestHotelPageProps {
    testEnvironment: boolean;
}

const MysterytestHotelPage = (props: MysteryTestHotelPageProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);
    const hasTesterCompanyAccess = useSelector(Selector.User.hasTesterCompanyAccess);
    const mysteryTestLoaded = useSelector(Selector.MysteryTest.loaded);
    const mysteryTestId = useSelector(Selector.MysteryTest.mysteryTestId);

    if (!isAdmin && !hasTesterCompanyAccess) {
        return null;
    }

    return (
        <Box sx={styles.root}>
            <MysteryTestHeader
                title={
                    props.testEnvironment ?
                        'Mystery-Check Test Configuration: "Hotel garni / Pension"' :
                        'Mystery-Check: "Hotel garni / Pension"'
                }
            />

            {((mysteryTestLoaded && mysteryTestId) || props.testEnvironment) &&
                <MysteryTestHotel testEnvironment={props.testEnvironment} />
            }
            {(mysteryTestLoaded && !mysteryTestId) && <StartMysteryTestInfo />}
        </Box>
    );
};

export default MysterytestHotelPage;
