import {Action, handleActions} from 'redux-actions';
import {
    KeepAliveStartedPayload,
    KeepAliveStoppedPayload,
    UserAuthenticatedPayload,
    UserFetchedPayload,
} from '../actions/userEvent';
import Event from '../actions/event';
import {User} from '../types/User';

export interface UserState {
    userId: string;
    roles: string[];
    users: Record<string, User>;
    keepAliveRunning: boolean;
}

export const userInitialState: UserState = {
    userId: '',
    roles: [],
    users: {},
    keepAliveRunning: false,
};

/* eslint-disable max-len */
export const userReducer = handleActions<UserState, any>(
    {
        [Event.User.userAuthenticated.toString()]: (state, action: Action<UserAuthenticatedPayload>): UserState => {
            return {
                ...state,
                userId: action.payload.userId,
                roles: action.payload.roles,
            };
        },
        [Event.User.userFetched.toString()]: (state, action: Action<UserFetchedPayload>): UserState => {
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.user.id]: action.payload.user,
                },
            };
        },
        [Event.User.keepAliveStarted.toString()]: (state, action: Action<KeepAliveStartedPayload>): UserState => {
            return {
                ...state,
                keepAliveRunning: true,
            };
        },
        [Event.User.keepAliveStopped.toString()]: (state, action: Action<KeepAliveStoppedPayload>): UserState => {
            return {
                ...state,
                keepAliveRunning: false,
            };
        },
    },
    userInitialState,
);
