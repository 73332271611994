import {createAction} from 'redux-actions';

const createTrustYouScoreCommand = <T>(type: string) => createAction<T>('_C/TY/' + type);

export interface SetTrustYouScorePayload {
    companyId: string;
    score: number;
}

export interface UpdateTrustYouScorePayload {
    trustYouScoreId: string;
    companyId: string;
    score: number;
}


/* eslint-disable max-len */
const TrustYouScoreCommand = {
    setTrustYouScore: createTrustYouScoreCommand<SetTrustYouScorePayload>('SET_TRUST_YOU_SCORE'),
    updateTrustYouScore: createTrustYouScoreCommand<UpdateTrustYouScorePayload>('UPDATE_TRUST_YOU_SCORE'),
};

export default TrustYouScoreCommand;
