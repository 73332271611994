import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useEffect} from 'react';
import Event from '../../state/actions/event';
import Command from '../../state/actions/command';
import {Selector} from '../../state/selector';

const RouteWatcher = () => {
    const dispatch = useDispatch();
    const params = useParams<{ companyId?: string }>();
    const isTester = useSelector(Selector.User.isTester);
    const isAdmin = useSelector(Selector.User.isAdmin);

    useEffect(() => {
        dispatch(Event.Company.companySelected({ companyId: params.companyId || null }));
        dispatch(Event.SelfAssessment.stateCleared({}));
        dispatch(Event.WebsiteCheck.stateCleared({}));
        dispatch(Event.MysteryTest.stateCleared({}));

        if (params.companyId) {
            dispatch(Command.SelfAssessment.fetchSelfAssessment({companyId: params.companyId}));

            // @todo this check does not equal the more complex one in the backend; should be identical
            if (isTester || isAdmin) {
                dispatch(Command.WebsiteCheck.fetchWebsiteCheck({companyId: params.companyId}));
                dispatch(Command.MysteryTest.fetchMysteryTest({companyId: params.companyId}));
            }
        }
    }, [params.companyId]);

    return null;
};

export default RouteWatcher;
