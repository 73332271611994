import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {FetchTestListPayload} from '../../actions/questionListCommand';

const onFetchTestList = function*(data: FetchTestListPayload) {
    try {
        const data: object = yield call(Api.Query.testQuestions);

        yield put(Event.QuestionList.questionListFetched({
            data: data,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Mystery-Test-Hotel Liste konnte nicht geladen werden.',
        }));
    }
};

export function* fetchTestListFlow() {
    while (true) {
        const action: Action<FetchTestListPayload> = yield take(
            Command.QuestionList.fetchTestList.toString(),
        );

        yield fork(onFetchTestList, action.payload);
    }
}
