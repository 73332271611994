import {Box, Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography} from '@mui/material';
import {Answer} from '../../util/dataTypes';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';

const getMaxPoints = (type: string, answers: Answer[]): number => {
    if (type === 'radio') {
        return answers.reduce((max, value) => Math.max(max, value.points), 0);
    }

    if (type === 'checkbox') {
        return answers.reduce((total, value) => total + value.points, 0);
    }

    return 0;
};

const getAchievedPoints = (type: string, answers: Answer[], value: any): number => {
    if (type === 'radio') {
        if (!value.radio) { return 0; }
        const answer = answers.find(answer => answer._id === value.radio);
        if (!answer) { return 0;}
        return answer.points;
    }

    if (type === 'checkbox') {
        if(!value.checkbox) { return 0; }
        let tmpPoints = 0;
        Object.keys(value.checkbox).forEach(key => {
            if (value.checkbox[key] === true) {
                const answer = answers.find(answer => answer.label === key);
                if (answer) { tmpPoints += answer.points; }
            }
        });
        return tmpPoints;
    }

    return 0;
};

const styles = {
    label: {
        fontWeight: 'bold',
    },
    textfields: {
        width: '100%',
    },
};

interface MysterytestAnswerCardProps {
    id: string;
    type: string;
    label: string;
    answers: Answer[];
    value: any;
    onChange: (newValue: object) => void;
    required: boolean;
    warn: boolean;
    disabled?: boolean;
    testEnvironment: boolean;
}

const MysterytestAnswerCard = (props: MysterytestAnswerCardProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);
    const maxPoints = getMaxPoints(props.type, props.answers);
    const achievedPoints = getAchievedPoints(props.type, props.answers, props.value);

    return (
        <Box>
            <Box>
                <FormLabel sx={styles.label} id={props.id}>
                    {props.label}
                    <Typography
                        component={'span'}
                        style={{color: props.warn ? 'red' : 'grey'}}
                    >
                        {props.required ? ' (erforderlich)' : ' (optional)'}
                    </Typography>

                    {isAdmin &&
                        <Typography
                            component={'span'}
                            style={{color: 'grey'}}
                        >
                            {props.type === 'radio' && <>  ({achievedPoints} von {maxPoints})</>}
                            {props.type === 'checkbox' && <>  ({achievedPoints} von {maxPoints})</>}
                        </Typography>
                    }
                </FormLabel>

                {props.type === 'radio' && (
                    <RadioGroup
                        row={true}
                        aria-labelledby={props.id}
                        name={'radio-buttons-group'}
                        value={props.value.radio || null}
                        onChange={event => props.onChange({ radio: event.target.value })}
                    >
                        {props.answers.map((answer: Answer) => (
                            <FormControlLabel
                                key={answer._id}
                                value={answer._id}
                                control={<Radio disabled={props.disabled} />}
                                label={<>
                                    <Typography variant={'body1'} component={'span'}> {answer.label} </Typography>
                                    {isAdmin &&
                                    <Typography
                                        variant={'body2'}
                                        component={'span'}
                                        style={{color: 'grey'}}
                                    >
                                        ({answer.points})
                                    </Typography>}
                                </>}
                            />
                        ))}
                    </RadioGroup>
                )}

                {props.type === 'checkbox' && (
                    <Box>
                        {props.answers.map((answer: Answer) => (
                            <FormControlLabel
                                key={answer._id}
                                value={answer._id}
                                control={(
                                    <Checkbox
                                        checked={props.value.checkbox?.[answer.label] || false}
                                        onChange={event => props.onChange({
                                            checkbox: {
                                                ...(props.value.checkbox || {}),
                                                [answer.label]: event.target.checked,
                                            },
                                        })}
                                        disabled={props.disabled}
                                    />
                                )}
                                label={<>
                                    <Typography variant={'body1'} component={'span'}> {answer.label} </Typography>
                                    {isAdmin &&
                                        <Typography
                                            variant={'body2'}
                                            component={'span'}
                                            style={{color: 'grey'}}
                                        >
                                            ({answer.points})
                                        </Typography>}
                                </>}
                            />
                        ))}
                    </Box>
                )
                }

                {props.type === 'textbox' && (
                    <Box>
                        <TextField
                            sx={styles.textfields}
                            label={props.label}
                            multiline={true}
                            rows={4}
                            value={props.value.text || ''}
                            onChange={event => props.onChange({ text: event.target.value })}
                            disabled={props.disabled}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );

};

export default MysterytestAnswerCard;
