import {createAction} from 'redux-actions';

const createWebsiteCheckCommand = <T>(type: string) => createAction<T>('_C/WC/' + type);

export interface FetchWebsiteCheckPayload {
    companyId: string;
}

export interface StartWebsiteCheckPayload {
}

export interface UpdateWebsiteCheckDataPayload {
    data: object;
}

export interface FinalizeWebsiteCheckPayload {
}

export interface ReopenWebsiteCheckPayload {
}

/* eslint-disable max-len */
const WebsiteCheckCommand = {
    fetchWebsiteCheck: createWebsiteCheckCommand<FetchWebsiteCheckPayload>('FETCH_WEBSITE_CHECK'),
    startWebsiteCheck: createWebsiteCheckCommand<StartWebsiteCheckPayload>('START_WEBSITE_CHECK'),
    updateWebsiteCheckData: createWebsiteCheckCommand<UpdateWebsiteCheckDataPayload>('UPDATE_WEBSITE_CHECK_DATA'),
    finalizeWebsiteCheck: createWebsiteCheckCommand<FinalizeWebsiteCheckPayload>('FINALIZE_WEBSITE_CHECK'),
    reopenWebsiteCheck: createWebsiteCheckCommand<ReopenWebsiteCheckPayload>('REOPEN_WEBSITE_CHECK'),
};

export default WebsiteCheckCommand;
