import {ReduxState} from '../reducer';
import {createSelector} from 'reselect';
import CompanySelector from './companySelector';
import {User} from '../types/User';

const userId = (state: ReduxState): string => state.user.userId;
const roles = (state: ReduxState): string[] => state.user.roles;
const users = (state: ReduxState): Record<string, User> => state.user.users;
export const keepAliveRunning = (state: ReduxState): boolean => state.user.keepAliveRunning;

const isAdmin = createSelector(
    [roles],
    roles => roles.includes('admin'),
);

const isTester = createSelector(
    [roles],
    roles => roles.includes('tester'),
);

const isEditor = createSelector(
    [roles],
    roles => roles.includes('editor'),
);

const hasTesterCompanyAccess = createSelector(
    [isTester, userId, CompanySelector.selectedCompany],
    (isTester, userId, selectedCompany) =>
        isTester && selectedCompany?.tester === userId,
);

const hasNormalCompanyAccess = createSelector(
    [isAdmin, userId, CompanySelector.selectedCompany],
    (isAdmin, userId, selectedCompany) =>
        isAdmin || selectedCompany?.employees.includes(userId),
);

const UserSelector = {
    userId,
    roles,
    users,
    keepAliveRunning,

    isAdmin,
    isTester,
    isEditor,
    hasTesterCompanyAccess,
    hasNormalCompanyAccess,
};

export default UserSelector;
