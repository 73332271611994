import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {FetchQuestionConfigurationPayload} from '../../actions/questionListCommand';

const onFetchQuestionConfiguration = function*(data: FetchQuestionConfigurationPayload) {
    try {
        const questionConfiguration: object =
            yield call(Api.Query.questionConfiguration, data.questionConfigurationType);

        yield put(Event.QuestionList.questionConfigurationFetched(questionConfiguration));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: `Die Fragen Liste ${data.questionConfigurationType} konnte nicht geladen werden.`,
        }));
    }
};

export function* fetchQuestionConfigurationFlow() {
    while (true) {
        const action: Action<FetchQuestionConfigurationPayload> = yield take(
            Command.QuestionList.fetchQuestionConfiguration.toString(),
        );

        yield fork(onFetchQuestionConfiguration, action.payload);
    }
}
