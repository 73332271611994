import React, {useEffect, useState} from 'react';
import {Box, IconButton, TextField, Tooltip} from '@mui/material';
import {Answer} from '../../../util/dataTypes';
import {useDispatch} from 'react-redux';
import Event from '../../../state/actions/event';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForever from '@mui/icons-material/DeleteForever';
import DeleteDialog from '../common/DeleteDialog';

interface AnswerEditProps {
    answer: Answer;
    mainCategoryId: string;
    subCategoryId: string;
    questionId: string;
}

const styles = {
    wrapper: {
        boxSizing: 'border-box',
        display: 'flex',
        justifyItems: 'center',
    },
    answerField: {
        marginTop: 2.5,
        width: 'calc(100% - 110px - 55px)',
    },
    pointsField: {
        marginTop: 2.5,
        marginLeft: 2,
        width: '80px',
        marginRight: '5px',
    },
    iconButton: {
        width: '25px',
        marginTop: '20px',
    },
};

const AnswerEdit = (props: AnswerEditProps) => {
    const dispatch = useDispatch();

    const [deleteInfoOpen, setDeleteInfoOpen] = useState<boolean>(false);

    const [answer, setAnswer] = useState<string>(props.answer.label);
    const [points, setPoints] = useState<number>(props.answer.points);

    const deleteAnswer = () => {
        dispatch(Event.QuestionList.mysteryTestHotelAnswerDeleted({
            id: props.answer._id,
            parentId: props.questionId,
        }));
    };

    const moveAnswerUp = () => {
        dispatch(Event.QuestionList.mysteryTestHotelAnswerMovedUp({
            id: props.answer._id,
            pId: props.questionId,
            ppId: props.subCategoryId,
            pppId: props.mainCategoryId,
        }));
    };

    const moveAnswerDown = () => {
        dispatch(Event.QuestionList.mysteryTestHotelAnswerMovedDown({
            id: props.answer._id,
            pId: props.questionId,
            ppId: props.subCategoryId,
            pppId: props.mainCategoryId,
        }));
    };

    useEffect(() => {
        setAnswer(props.answer.label);
        setPoints(props.answer.points);
    }, [props.answer.label, props.answer.points]);

    return (
        <Box sx={styles.wrapper} key={props.answer._id}>
            <TextField
                label={'Antwort'}
                variant={'outlined'}
                fullWidth={true}
                size={'small'}
                sx={styles.answerField}
                value={answer}
                onChange={event => {setAnswer(event.target.value);}}
                onBlur={event => {
                    dispatch(Event.QuestionList.mysteryTestHotelAnswerLabelChanged({
                        id: props.answer._id,
                        parentId: props.questionId,
                        label: event.target.value,
                    }));
                }}
            />
            <TextField
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                label={'Punkte'}
                variant={'outlined'}
                fullWidth={true}
                size={'small'}
                sx={styles.pointsField}
                value={points}
                onChange={event => {
                    setPoints(Number(event.target.value));
                }}
                onBlur={event => {
                    if (!event.target.validity.valid) {
                        setPoints(props.answer.points);
                        return;
                    }
                    dispatch(Event.QuestionList.mysteryTestHotelAnswerPointsChanged({
                        id: props.answer._id,
                        parentId: props.questionId,
                        points: Number(event.target.value),
                    }));
                }}
            />

            <Tooltip title={'Frage nch oben verschieben'}>
                <IconButton
                    sx={styles.iconButton}
                    onClick={moveAnswerUp}
                >
                    <ArrowUpwardIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={'Frage nach unten verschieben'}>
                <IconButton
                    sx={styles.iconButton}
                    onClick={moveAnswerDown}
                >
                    <ArrowDownwardIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title={'Frage löschen'}>
                <IconButton
                    sx={styles.iconButton}
                    onClick={() => {setDeleteInfoOpen(true);}}
                >
                    <DeleteForever />
                </IconButton>
            </Tooltip>

            <DeleteDialog
                open={deleteInfoOpen}
                onClose={() => {setDeleteInfoOpen(false);}}
                onFinalize={() => {deleteAnswer(); setDeleteInfoOpen(false);}}
                title={'Antwort löschen'}
                text={`Möchten Sie die Antwort "${props.answer.label}" (${props.answer.points}) löschen?`}
                continueButtonText={'Löschen'}
            />
        </Box>
    );
};

export default AnswerEdit;
