import {Action, handleActions} from 'redux-actions';
import Event from '../actions/event';
import {
    SelfAssessmentFetchedPayload,
    SelfAssessmentFinalizedPayload,
    SelfAssessmentNotFoundPayload,
    SelfAssessmentQuestionAnsweredPayload,
    SelfAssessmentReopenedPayload,
    SelfAssessmentStartedPayload,
} from '../actions/selfAssessmentEvent';

export interface SelfAssessmentState {
    loaded: boolean;
    selfAssessmentId: string|null;
    data: any;
    submitted: boolean;
}

export const selfAssessmentInitialState: SelfAssessmentState = {
    loaded: false,
    selfAssessmentId: null,
    data: {},
    submitted: false,
};

/* eslint-disable max-len */
export const selfAssessmentReducer = handleActions<SelfAssessmentState, any>(
    {
        [Event.SelfAssessment.stateCleared.toString()]: (): SelfAssessmentState => {
            return {
                ...selfAssessmentInitialState,
            };
        },
        [Event.SelfAssessment.selfAssessmentFetched.toString()]: (state, action: Action<SelfAssessmentFetchedPayload>): SelfAssessmentState => {
            return {
                ...state,
                loaded: true,
                selfAssessmentId: action.payload.selfAssessment.selfAssessmentId,
                data: action.payload.selfAssessment.data,
                submitted: action.payload.selfAssessment.submitted,
            };
        },
        [Event.SelfAssessment.selfAssessmentNotFound.toString()]: (state, action: Action<SelfAssessmentNotFoundPayload>): SelfAssessmentState => {
            return {
                ...state,
                loaded: true,
            };
        },
        [Event.SelfAssessment.selfAssessmentStarted.toString()]: (state, action: Action<SelfAssessmentStartedPayload>): SelfAssessmentState => {
            return {
                ...state,
                selfAssessmentId: action.payload.selfAssessmentId,
            };
        },
        [Event.SelfAssessment.selfAssessmentQuestionAnswered.toString()]: (state, action: Action<SelfAssessmentQuestionAnsweredPayload>): SelfAssessmentState => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload.data,
                },
            };
        },
        [Event.SelfAssessment.selfAssessmentFinalized.toString()]: (state, action: Action<SelfAssessmentFinalizedPayload>): SelfAssessmentState => {
            return {
                ...state,
                submitted: true,
            };
        },
        [Event.SelfAssessment.selfAssessmentReopened.toString()]: (state, action: Action<SelfAssessmentReopenedPayload>): SelfAssessmentState => {
            return {
                ...state,
                submitted: false,
            };
        },
    },
    selfAssessmentInitialState,
);
