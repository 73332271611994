import {ReduxState} from '../reducer';
import {Company, CompanyStatus} from '../types/Company';
import {createSelector} from 'reselect';

const companyList = (state: ReduxState): Company[] => state.company.companyList;
const companyStatusMap = (state: ReduxState): Record<string, CompanyStatus> => state.company.companyStatusMap;
const selectedCompanyId = (state: ReduxState): string|null => state.company.selectedCompanyId;

const selectedCompany = createSelector(
    [companyList, selectedCompanyId],
    (companyList, selectedCompanyId): Company|null =>
        companyList.find(company => company.id === selectedCompanyId) || null,
);

const company = createSelector(
    [companyList, (_: any, companyId: string) => companyId],
    (companyList, companyId): Company|null => companyList.find(company => company.id === companyId) || null,
);

const CompanySelector = {
    companyList,
    companyStatusMap,
    selectedCompanyId,

    selectedCompany,
    company,
};

export default CompanySelector;
