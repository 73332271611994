import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {v4 as uuidv4} from 'uuid';
import {Selector} from '../../selector';
import {StartMysteryTestPayload} from '../../actions/mysteryTestCommand';
import {Company} from '../../types/Company';

const onStartMysteryTest = function*(data: StartMysteryTestPayload) {
    const mysteryTestId = uuidv4();
    const company: Company|null = yield select(Selector.Company.selectedCompany);

    if (!company || !company.testCategory) {
        return;
    }

    const mysteryTestType = 'hotel' === company.testCategory ? 'hotel' : 'restaurant';

    try {
        yield call(Api.Command.startNewMysteryTest, {
            mysteryTestId,
            companyId: company.id,
            mysteryTestType,
        });

        yield put(Event.MysteryTest.mysteryTestStarted({
            mysteryTestId,
            type: mysteryTestType,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Mystery-Test konnte nicht gestartet werden.',
        }));
    }
};

export function* startMysteryTestFlow() {
    while (true) {
        const action: Action<StartMysteryTestPayload> = yield take(
            Command.MysteryTest.startMysteryTest.toString(),
        );

        yield fork(onStartMysteryTest, action.payload);
    }
}
