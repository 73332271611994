import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import {Box, Card, CardContent, Chip, Typography} from '@mui/material';
import {theme} from '../../mui/theme';
import React from 'react';
import {testCategories} from '../../util/testCategoryType';

const styles = {
    wrapperBox: {
        padding: 4,
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'auto',
    },
    formWrapper: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        paddingTop: 3,
    },
    sectionDivider: {
        marginTop: 4,
        marginBottom: 2.5,
    },
};

const BaseDataOverview = () => {
    const selectedCompany = useSelector(Selector.Company.selectedCompany);

    let testCategoryLabel = 'Restaurant';

    const selectedTestCategory = (
        selectedCompany?.testCategory === null || selectedCompany?.testCategory === undefined
    ) ? undefined : selectedCompany?.testCategory;

    if(selectedTestCategory){
        testCategoryLabel = testCategories[selectedTestCategory];
    }

    return (
        <Box sx={styles.wrapperBox}>
            <Typography variant={'h1'}>
                Unternehmensdaten in der Brandenburger Gastlichkeit
            </Typography>

            <Box sx={styles.formWrapper}>

                <Card sx={{ width: '100%' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Testkategorie
                        </Typography>
                        <Box>
                            <Chip label={testCategoryLabel} />
                            <br />
                        </Box>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Gastroname
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.name) ? selectedCompany.name : '-'}
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Name des Betriebes (Betreiber)
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.restaurantName) ? selectedCompany.restaurantName : '-'}
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Reiseregion
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.travelRegion) ? selectedCompany.travelRegion : '-'}
                        </Typography>

                        <Typography variant={'h3'} sx={styles.sectionDivider}>
                            Rechnungsadresse
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Straße
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.billingStreet) ? selectedCompany.billingStreet : '-'}
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Plz / Ort
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.billingZip) ? selectedCompany.billingZip : '-'} /
                            {(selectedCompany?.billingCity) ? selectedCompany.billingCity : '-'}
                        </Typography>

                        <Typography variant={'h3'} sx={styles.sectionDivider}>
                            Kontaktdaten
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Kontaktperson
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.contactPerson) ? selectedCompany.contactPerson : '-'}
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Straße
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.street) ? selectedCompany.street : '-'}
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Plz / Ort
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.zip) ? selectedCompany.zip : '-'} /
                            {(selectedCompany?.city) ? selectedCompany.city : '-'}
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Telefon
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.phone) ? selectedCompany.phone : '-'}
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            E-Mail
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.email) ? selectedCompany.email : '-'}
                        </Typography>

                        <Typography sx={{ fontSize: 14 }} color={'text.secondary'} gutterBottom={true}>
                            Internet
                        </Typography>
                        <Typography variant={'h5'} component={'div'}>
                            {(selectedCompany?.web) ? selectedCompany.web : '-'}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default BaseDataOverview;
