import {Box, Step, StepLabel, Stepper} from '@mui/material';
import {StructureCategories} from '../../util/dataTypes';
import {theme} from '../../mui/theme';

interface CategoryStepperProps {
    activeCategory: number;
    categories?: StructureCategories[];
}

const styles = {
    wrapper: {
        maxWidth: '400px',
    },
    step: {
        stepLabel: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    },
};

const CategoryStepper = (props: CategoryStepperProps) => {

    return (
        <Box sx={styles.wrapper}>
            <Stepper
                activeStep={props.activeCategory}
                orientation={'vertical'}
            >
                {!props.categories ?
                    ''
                    :
                    props.categories.map((category: StructureCategories) => (
                        <Step key={category._id}>
                            <StepLabel>
                                <Box sx={styles.step.stepLabel}>
                                    {category.label}
                                </Box>
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
        </Box>
    );

};

export default CategoryStepper;
