import {Action, handleActions} from 'redux-actions';
import {Company, CompanyStatus} from '../types/Company';
import Event from '../actions/event';
import {
    BasicCompanyDataUpdatedPayload,
    CompanyCreatedPayload,
    CompanyEmployeeAssignedPayload, CompanyEmployeeUnassignedPayload,
    CompanyListFetchedPayload,
    CompanySelectedPayload, CompanyStatusListFetchedPayload, CompanyTesterRemovedPayload, CompanyTesterSetPayload,
} from '../actions/companyEvent';

export interface CompanyState {
    companyList: Company[];
    companyStatusMap: Record<string, CompanyStatus>;
    selectedCompanyId: string|null;
}

export const companyInitialState: CompanyState = {
    companyList: [],
    companyStatusMap: {},
    selectedCompanyId: null,
};

/* eslint-disable max-len */
export const companyReducer = handleActions<CompanyState, any>(
    {
        [Event.Company.companyListFetched.toString()]: (state, action: Action<CompanyListFetchedPayload>): CompanyState => {
            return {
                ...state,
                companyList: action.payload.companyList,
            };
        },
        [Event.Company.companyStatusListFetched.toString()]: (state, action: Action<CompanyStatusListFetchedPayload>): CompanyState => {
            const companyStatusMap: Record<string, CompanyStatus> = {};

            action.payload.companyStatusList.forEach(companyStatus => {
                companyStatusMap[companyStatus.companyId] = companyStatus;
            });

            return {
                ...state,
                companyStatusMap,
            };
        },
        [Event.Company.companyCreated.toString()]: (state, action: Action<CompanyCreatedPayload>): CompanyState => {
            return {
                ...state,
                companyList: [
                    ...state.companyList,
                    action.payload.company,
                ],
            };
        },
        [Event.Company.companySelected.toString()]: (state, action: Action<CompanySelectedPayload>): CompanyState => {
            return {
                ...state,
                selectedCompanyId: action.payload.companyId,
            };
        },
        [Event.Company.companyEmployeeAssigned.toString()]: (state, action: Action<CompanyEmployeeAssignedPayload>): CompanyState => {
            return {
                ...state,
                companyList: state.companyList.map(company => {
                    if (company.id !== action.payload.companyId) {
                        return company;
                    }

                    return {
                        ...company,
                        employees: [
                            ...company.employees,
                            action.payload.userId,
                        ],
                    };
                }),
            };
        },
        [Event.Company.companyEmployeeUnassigned.toString()]: (state, action: Action<CompanyEmployeeUnassignedPayload>): CompanyState => {
            return {
                ...state,
                companyList: state.companyList.map(company => {
                    if (company.id !== action.payload.companyId) {
                        return company;
                    }

                    return {
                        ...company,
                        employees: company.employees.filter(employee => employee !== action.payload.userId),
                    };
                }),
            };
        },
        [Event.Company.companyTesterSet.toString()]: (state, action: Action<CompanyTesterSetPayload>): CompanyState => {
            return {
                ...state,
                companyList: state.companyList.map(company => {
                    if (company.id !== action.payload.companyId) {
                        return company;
                    }

                    return {
                        ...company,
                        tester: action.payload.userId,
                    };
                }),
            };
        },
        [Event.Company.companyTesterRemoved.toString()]: (state, action: Action<CompanyTesterRemovedPayload>): CompanyState => {
            return {
                ...state,
                companyList: state.companyList.map(company => {
                    if (company.id !== action.payload.companyId) {
                        return company;
                    }

                    return {
                        ...company,
                        tester: null,
                    };
                }),
            };
        },
        [Event.Company.basicCompanyDataUpdated.toString()]: (state, action: Action<BasicCompanyDataUpdatedPayload>): CompanyState => {
            return {
                ...state,
                companyList: state.companyList.map(company => {
                    if (company.id !== action.payload.companyId) {
                        return company;
                    }

                    const { companyId: _1, companyName, ...dataToUpdate } = action.payload;

                    return {
                        ...company,
                        ...dataToUpdate,
                        name: companyName,
                    };
                }),
            };
        },
    },
    companyInitialState,
);
