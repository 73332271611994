import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {RegisterNewCompanyPayload} from '../../actions/companyCommand';

const onRegisterNewCompany = function*(data: RegisterNewCompanyPayload) {
    try {
        yield call(Api.Command.registerNewCompany, {
            companyId: data.companyId,
            restaurantName: data.restaurantName,
            companyName: data.companyName,
            billingStreet: data.billingStreet,
            billingZip: data.billingZip,
            billingCity: data.billingCity,
            contactPerson: data.contactPerson,
            street: data.street,
            zip: data.zip,
            city: data.city,
            phone: data.phone,
            email: data.email,
            web: data.web,
            hidden: data.hidden,
            travelRegion: data.travelRegion,
            testCategory: data.testCategory,
            bookedQualityCheck: data.bookedQualityCheck,
            bookedQualityCheckDate: data.bookedQualityCheckDate,
            bookedMarketing: data.bookedMarketing,
            bookedMarketingDate: data.bookedMarketingDate,
        });

        yield put(Event.Company.companyCreated({
            company: {
                id: data.companyId,
                name: data.companyName,
                email: data.email,
                billingStreet: data.billingStreet,
                billingZip: data.billingZip,
                billingCity: data.billingCity,
                contactPerson: data.contactPerson,
                street: data.street,
                zip: data.zip,
                city: data.city,
                phone: data.phone,
                restaurantName: data.restaurantName,
                web: data.web,
                employees: [],
                testCategory: data.testCategory,
                tester: null,
                hidden: false,
                travelRegion: data.travelRegion,
                bookedQualityCheck: data.bookedQualityCheck,
                bookedQualityCheckDate: data.bookedQualityCheckDate,
                bookedMarketing: data.bookedMarketing,
                bookedMarketingDate: data.bookedMarketingDate,
            },
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: `Das Unternehmen ${data.restaurantName} wurde erfolgreich regestriert.`,
        }));

        yield call(Api.Command.generateBookingContract, {
            companyId: data.companyId,
            email: data.email,
            rerenderContract: false,
        });

        yield call(Api.Command.assignCompanyEmployee, {
            companyId: data.companyId,
            employeeId: data.userId,
        });

        yield put(Event.Company.companyEmployeeAssigned({
            companyId: data.companyId,
            userId: data.userId,
        }));

    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Das Unternehmen konnte nicht regestriert werden.',
        }));
    }
};

export function* registerNewCompanyFlow() {
    while (true) {
        const action: Action<RegisterNewCompanyPayload> = yield take(Command.Company.registerNewCompany.toString());
        yield fork(onRegisterNewCompany, action.payload);
    }
}
