import {Box, Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from '@mui/material';
import {Answer} from '../../util/dataTypes';

const styles = {
    label: {
        fontWeight: 'bold',
    },
    textfields: {
        width: '100%',
    },
};

interface AnswerProps {
    id: string;
    type: string;
    label: string;
    answers?: Answer[];
    value: any;
    onChange: (newValue: object) => void;
    disabled?: boolean;
}

const AnswerCard = (props: AnswerProps) => {

    const answers = props.answers;

    return (
        <Box>
            <Box>
                {props.type !== 'textbox' && (
                    <FormLabel sx={styles.label} id={props.id}>{props.label}</FormLabel>
                )}
                {props.type === 'radio' && (
                    <RadioGroup
                        row={true}
                        aria-labelledby={props.id}
                        name={'radio-buttons-group'}
                        value={props.value.radio || null}
                        onChange={event => props.onChange({ radio: event.target.value })}
                    >
                        {!answers ?
                            ''
                            :
                            answers.map((answer: Answer) => (
                                <FormControlLabel
                                    key={answer.label}
                                    value={answer.label}
                                    control={<Radio disabled={props.disabled} />}
                                    label={answer.label}
                                />
                            ))
                        }
                    </RadioGroup>
                )
                }

                {props.type === 'checkbox' && (
                    <Box>
                        {!answers ?
                            ''
                            :
                            answers.map((answer: Answer) => (
                                <FormControlLabel
                                    key={answer.label}
                                    value={answer.label}
                                    control={(
                                        <Checkbox
                                            checked={props.value.checkbox?.[answer.label] || false}
                                            onChange={event => props.onChange({
                                                checkbox: {
                                                    ...(props.value.checkbox || {}),
                                                    [answer.label]: event.target.checked,
                                                },
                                            })}
                                            disabled={props.disabled}
                                        />
                                    )}
                                    label={answer.label}
                                />
                            ))
                        }
                    </Box>
                )
                }

                {props.type === 'textbox' && (
                    <Box>
                        {answers!.length > 0 && (
                            answers!.map((answer: Answer) => (
                                <TextField
                                    sx={styles.textfields}
                                    label={props.label}
                                    multiline={true}
                                    rows={4}
                                    value={props.value.text || ''}
                                    onChange={event => props.onChange({ text: event.target.value })}
                                    disabled={props.disabled}
                                />
                            ))
                        )
                        }

                        {answers!.length === 0 && (
                            <TextField
                                sx={styles.textfields}
                                label={props.label}
                                multiline={true}
                                rows={4}
                            />
                        )
                        }
                    </Box>
                )
                }

            </Box>
        </Box>
    );

};

export default AnswerCard;
