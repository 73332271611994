import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {Selector} from '../../selector';
import {StartNewMysteryTestAttemptPayload} from '../../actions/mysteryTestCommand';

const onStartNewMysteryTestAttempt = function*(data: StartNewMysteryTestAttemptPayload) {
    try {
        const mysteryTestId: string|null = yield select(Selector.MysteryTest.mysteryTestId);

        if (!mysteryTestId) {
            return;
        }

        yield call(Api.Command.startNewMysteryTestAttempt, {
            mysteryTestId,
        });

        yield put(Event.MysteryTest.newMysteryTestAttemptStarted({
            mysteryTestId,
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Der Mystery-Test wurde zurückgesetzt.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Mystery-Test konnte nicht zurückgesetzt werden.',
        }));
    }
};

export function* startNewMysteryTestAttemptFlow() {
    while (true) {
        const action: Action<StartNewMysteryTestAttemptPayload> = yield take(
            Command.MysteryTest.startNewMysteryTestAttempt.toString(),
        );

        yield fork(onStartNewMysteryTestAttempt, action.payload);
    }
}
