import {Alert, Box, Card, CardContent, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Remove';
import {StructureCategories} from '../../util/dataTypes';
import React from 'react';

const styles = {
    root: {
        padding: 4,
    },
    questionBox: {
        marginBottom: 2,
    },
    answerBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    answerIconPositive: (theme: any) => ({
        marginRight: 1,
        color: theme.palette.success.main,
    }),
    answerIconNegative: (theme: any) => ({
        marginRight: 1,
        color: theme.palette.grey,
    }),
};

const SelfAssessmentOverview = () => {
    const selectedCompany = useSelector(Selector.Company.selectedCompany);
    const selfAssessmentId = useSelector(Selector.SelfAssessment.selfAssessmentId);
    const data = useSelector(Selector.SelfAssessment.data);
    const selfassessment = useSelector(Selector.QuestionList.selfAssessment);

    let categories;
    if(selfassessment.categories.length > 1){
        categories = selfassessment.categories;
    } else {
        categories = selfassessment.categories[0].questions.map((question: any) => {
            return {
                _id: question._id,
                label: question.label,
                description: question.description,
                questions: [question],
            } as StructureCategories;
        });
    }

    return (
        <Box sx={styles.root}>
            <Typography sx={{marginBottom: 4}} variant={'h1'}>
                Selbstauskunft: "{
                    (selectedCompany?.name) ?
                        selectedCompany?.name : selectedCompany?.restaurantName
                }"
            </Typography>

            {!selfAssessmentId && (
                <Alert severity={'warning'}>
                    Die Selbstauskunft wurde noch nicht begonnen.
                </Alert>
            )}

            {selfAssessmentId && (
                <Box>
                    {categories.map((category: any) => (
                        <Box>
                            {category.questions.map((question: any) => (
                                <Card sx={{marginBottom: 4, padding: 2}}>
                                    <Typography variant={'h3'}>{question.label}</Typography>
                                    {question.answersGroups?.map((answerGroup: any) => (
                                        <CardContent sx={styles.questionBox}>
                                            <Typography>{answerGroup.label}</Typography>
                                            {answerGroup.answers?.map((answer: any) => {

                                                let isSelected = false;

                                                if(answerGroup.answer_type === 'radio') {
                                                    isSelected = data[answerGroup._id]?.radio === answer.label;
                                                }

                                                if(answerGroup.answer_type === 'checkbox') {
                                                    isSelected = data[answerGroup._id]?.checkbox[answer.label] === true;
                                                }

                                                return (
                                                    <Box sx={styles.answerBox}>
                                                        {isSelected
                                                            ? <CheckIcon sx={styles.answerIconPositive} />
                                                            : <CloseIcon sx={styles.answerIconNegative} />
                                                        }
                                                        <Typography>{answer.label}</Typography>
                                                    </Box>
                                                );

                                            })}

                                            {data[question._id]?.infoText && (
                                                <Box>
                                                    <Typography variant={'h4'} sx={{marginTop: 2}}>
                                                        Sonstiges:
                                                    </Typography>
                                                    <Typography sx={{marginTop: 2}}>
                                                        {data[question._id]?.infoText}
                                                    </Typography>
                                                </Box>
                                            )}

                                        </CardContent>
                                    ))}
                                </Card>
                            ))}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default SelfAssessmentOverview;
