import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {Company} from '../../types/Company';

const onFetchCompanyList = function*() {
    try {
        const companyList: Company[] = yield call(Api.Query.companyList);

        yield put(Event.Company.companyListFetched({ companyList }));
        yield put(Command.Company.fetchCompanyStatusList({}));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Unternehmensliste konnte nicht geladen werden.',
        }));
    }
};

export function* fetchCompanyListFlow() {
    while (true) {
        yield take(Command.Company.fetchCompanyList.toString());
        yield fork(onFetchCompanyList);
    }
}
