import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {Selector} from '../../selector';
import {UpdateMysteryTestDataPayload} from '../../actions/mysteryTestCommand';

const onUpdateMysteryTestData = function*(data: UpdateMysteryTestDataPayload) {
    const mysteryTestId: string|null = yield select(Selector.MysteryTest.mysteryTestId);

    if (!mysteryTestId) {
        return;
    }

    try {
        yield call(Api.Command.updateMysteryTestData, {
            mysteryTestId,
            mysteryTestData: data.data,
        });

        yield put(Event.MysteryTest.mysteryTestDataUpdated({
            data: data.data,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Mystery-Test Daten konnten nicht gespeichert werden.',
        }));
    }
};

export function* updateMysteryTestDataFlow() {
    while (true) {
        const action: Action<UpdateMysteryTestDataPayload> = yield take(
            Command.MysteryTest.updateMysteryTestData.toString(),
        );

        yield fork(onUpdateMysteryTestData, action.payload);
    }
}
