import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import SelfAssessment from './SelfAssessment';
import StartSelfAssessmentInfo from './StartSelfAssessmentInfo';
import SelfAssessmentOverview from './SelfAssessmentOverview';
import SelfAssessmentHeader from './SelfAssessmentHeader';

const styles = {
    root: {
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'auto',
    },
};
const SelfAssessmentPage = () => {
    const hasNormalCompanyAccess = useSelector(Selector.User.hasNormalCompanyAccess);
    const hasTesterCompanyAccess = useSelector(Selector.User.hasTesterCompanyAccess);
    const selfAssessmentLoaded = useSelector(Selector.SelfAssessment.loaded);
    const selfAssessmentId = useSelector(Selector.SelfAssessment.selfAssessmentId);

    if (hasNormalCompanyAccess) {
        return (
            <Box sx={styles.root}>
                <SelfAssessmentHeader title={'Selbstauskunft'} />

                {(selfAssessmentLoaded && selfAssessmentId) && <SelfAssessment />}
                {(selfAssessmentLoaded && !selfAssessmentId) && <StartSelfAssessmentInfo />}
            </Box>
        );
    } else if (hasTesterCompanyAccess) {
        return <SelfAssessmentOverview />;
    } else {
        return null;
    }
};

export default SelfAssessmentPage;
