import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {FetchMysteryTestPayload} from '../../actions/mysteryTestCommand';
import {MysteryTest} from '../../types/MysteryTest';

const onFetchMysteryTest = function*(data: FetchMysteryTestPayload) {
    try {
        const mysteryTest: MysteryTest = yield call(Api.Query.mysteryTest, data.companyId);

        yield put(Event.MysteryTest.mysteryTestFetched({ mysteryTest }));
    } catch (e) {
        if (e.response?.status === 404) {
            yield put(Event.MysteryTest.mysteryTestNotFound({}));
            return;
        }

        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Mystery-Test konnte nicht geladen werden.',
        }));
    }
};

export function* fetchMysteryTestFlow() {
    while (true) {
        const action: Action<FetchMysteryTestPayload> = yield take(
            Command.MysteryTest.fetchMysteryTest.toString(),
        );

        yield fork(onFetchMysteryTest, action.payload);
    }
}
