import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {UpdateTrustYouScorePayload} from '../../actions/trustYouScoreCommand';

const onUpdateTrustYouScore = function*(data: UpdateTrustYouScorePayload) {
    try {
        yield call(Api.Command.updateTrustYouScore, {
            trustYouId: data.trustYouScoreId,
            trustYouScore: data.score,
        });

        yield put(Event.TrustYou.trustYouScoreUpdated({
            trustYouId: data.trustYouScoreId,
            score: data.score,
        }));

        // yield put(Command.Company.fetchCompanyStatusList({}));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der TrustYou Score konnte nicht geupdatet werden.',
        }));
    }
};

export function* updateTrustYouScoreFlow() {
    while (true) {
        const action: Action<UpdateTrustYouScorePayload> = yield take(
            Command.TrustYou.updateTrustYouScore.toString(),
        );

        yield fork(onUpdateTrustYouScore, action.payload);
    }
}
