import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch} from 'react-redux';
import Command from '../../state/actions/command';

interface AddNewCompanyDialogProps {
    open: boolean;
    onClose: () => void;
}

const AddNewCompanyDialog = (props: AddNewCompanyDialogProps) => {
    const dispatch = useDispatch();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        if (!props.open) {
            setTimeout(
                () => {
                    setName('');
                    setEmail('');
                },
                200,
            );
        }
    }, [props.open]);

    const activateSaveButton = name.length > 0 && /^.+@.+\..+$/.test(email);

    const createCompany = () => {
        if (!activateSaveButton) {
            return;
        }

        dispatch(Command.Company.createCompany({
            name,
            email,
        }));

        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Neues Unternehmen anlegen</DialogTitle>
            <DialogContent>
                <Box>
                    <TextField
                        value={name}
                        onChange={event => setName(event.target.value)}
                        label={'Name'}
                        variant={'outlined'}
                        fullWidth={true}
                        sx={{ marginTop: 1 }}
                    />
                    <TextField
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        label={'E-Mail'}
                        variant={'outlined'}
                        fullWidth={true}
                        sx={{ marginTop: 2 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button
                    children={'Hinzufügen'}
                    startIcon={<AddIcon />}
                    variant={'contained'}
                    onClick={createCompany}
                    disabled={!activateSaveButton}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddNewCompanyDialog;
