import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

interface DeleteConfirmationProps {
    open: boolean;
    onClose: () => void;
    onFinalize: () => void;
    title: string;
    text: string;
    continueButtonText: string;
}

const DeleteConfirmation = (props: DeleteConfirmationProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button color={'primary'} onClick={props.onFinalize} variant={'contained'}>
                    {props.continueButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmation;
