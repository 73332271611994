import {Alert, Box, Button, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import Command from '../../state/actions/command';

const StartSelfAssessmentInfo = () => {
    const dispatch = useDispatch();

    const startSelfAssessment = () => {
        dispatch(Command.SelfAssessment.startSelfAssessment({}));
    };

    return (
        <Box>
            <Alert severity={'info'}>
                Die Selbstauskunft dient der Vorbereitung der Mystery Tester auf den Betriebsbesuch.<br />
                Es werden Informationen zu Kriterien für die Beurteilung der Servicequalität abgefragt.<br />
                Die vorgegebenen Antwortmöglichkeiten können durch eigene Bemerkungen ergänzt werden.
            </Alert>
            <Typography variant={'body2'} sx={{ marginTop: 3, marginBottom: 3 }}>
                Ihr Arbeitsstand wird zwischengespeichert. Nach vollständigem Ausfüllen wird die Selbstauskunft
                entsprechend gesichert und für den Tester bereitgestellt.
            </Typography>
            <Button variant={'contained'} onClick={startSelfAssessment}>
                Selbstauskunft beginnen
            </Button>
        </Box>
    );
};

export default StartSelfAssessmentInfo;
