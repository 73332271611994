import {ReduxState} from '../reducer';
import {MysteryTestType} from '../types/MysteryTest';

const mysteryTestId = (state: ReduxState): string|null => state.mysteryTest.mysteryTestId;
const type = (state: ReduxState): MysteryTestType|null => state.mysteryTest.type;
const loaded = (state: ReduxState): boolean => state.mysteryTest.loaded;
const data = (state: ReduxState): any => state.mysteryTest.data;
const submitted = (state: ReduxState): boolean => state.mysteryTest.submitted;

const MysteryTestSelector = {
    mysteryTestId,
    type,
    loaded,
    data,
    submitted,
};

export default MysteryTestSelector;
