import {createAction} from 'redux-actions';
import {WebsiteCheck} from '../types/WebsiteCheck';

const createWebsiteCheckEvent = <T>(type: string) => createAction<T>('_E/WC/' + type);

export interface WebsiteCheckFetchedPayload {
    websiteCheck: WebsiteCheck;
}

export interface WebsiteCheckNotFoundPayload {
}

export interface WebsiteCheckStartedPayload {
    websiteCheckId: string;
}

export interface WebsiteCheckDataUpdatedPayload {
    data: object;
}

export interface WebsiteCheckFinalizedPayload {
}

export interface WebsiteCheckReopenedPayload {
}

/* eslint-disable max-len */
const WebsiteCheckEvent = {
    stateCleared: createWebsiteCheckEvent<{}>('STATE_CLEARED'),
    websiteCheckFetched: createWebsiteCheckEvent<WebsiteCheckFetchedPayload>('WEBSITE_CHECK_FETCHED'),
    websiteCheckNotFound: createWebsiteCheckEvent<WebsiteCheckNotFoundPayload>('WEBSITE_CHECK_NOT_FOUND'),
    websiteCheckStarted: createWebsiteCheckEvent<WebsiteCheckStartedPayload>('WEBSITE_CHECK_STARTED'),
    websiteCheckDataUpdated: createWebsiteCheckEvent<WebsiteCheckDataUpdatedPayload>('WEBSITE_CHECK_DATA_UPDATED'),
    websiteCheckFinalized: createWebsiteCheckEvent<WebsiteCheckFinalizedPayload>('WEBSITE_CHECK_FINALIZED'),
    websiteCheckReopened: createWebsiteCheckEvent<WebsiteCheckReopenedPayload>('WEBSITE_CHECK_REOPENED'),
};

export default WebsiteCheckEvent;
