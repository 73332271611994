import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {Selector} from '../../selector';
import {ReopenSelfAssessmentPayload} from '../../actions/selfAssessmentCommand';

const onReopenSelfAssessment = function*(data: ReopenSelfAssessmentPayload) {
    const selfAssessmentId: string|null = yield select(Selector.SelfAssessment.selfAssessmentId);

    if (!selfAssessmentId) {
        return;
    }

    try {
        yield call(Api.Command.reopenSelfAssessment, {
            selfAssessmentId,
        });

        yield put(Event.SelfAssessment.selfAssessmentReopened({}));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Das Self-Assessment wurde erneut geöffnet.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Das Self-Assessment konnte nicht erneut geöffnet werden.',
        }));
    }
};

export function* reopenSelfAssessmentFlow() {
    while (true) {
        const action: Action<ReopenSelfAssessmentPayload> = yield take(
            Command.SelfAssessment.reopenSelfAssessment.toString(),
        );

        yield fork(onReopenSelfAssessment, action.payload);
    }
}
