import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {CompanyStatus} from '../../types/Company';

const onFetchCompanyStatusList = function*() {
    try {
        const companyStatusList: CompanyStatus[] = yield call(Api.Query.companyStatusList);

        yield put(Event.Company.companyStatusListFetched({ companyStatusList }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Statusliste der Unternehmen konnte nicht geladen werden.',
        }));
    }
};

export function* fetchCompanyStatusListFlow() {
    while (true) {
        yield take(Command.Company.fetchCompanyStatusList.toString());
        yield fork(onFetchCompanyStatusList);
    }
}
