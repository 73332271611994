import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

interface FinalizeWebsiteCheckConfirmationProps {
    open: boolean;
    onClose: () => void;
    onFinalize: () => void;
}

const FinalizeWebsiteCheckConfirmation = (props: FinalizeWebsiteCheckConfirmationProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Website Check einreichen
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Möchten Sie den Website Check wirklich einreichen? Die Daten können anschließend nicht mehr
                    bearbeitet werden.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button color={'primary'} onClick={props.onFinalize} variant={'contained'}>
                    Einreichen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FinalizeWebsiteCheckConfirmation;
