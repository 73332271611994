import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {v4 as uuidv4} from 'uuid';
import {StartSelfAssessmentPayload} from '../../actions/selfAssessmentCommand';
import {Selector} from '../../selector';

const onStartSelfAssessment = function*(data: StartSelfAssessmentPayload) {
    const selfAssessmentId = uuidv4();
    const companyId: string|null = yield select(Selector.Company.selectedCompanyId);

    if (!companyId) {
        return;
    }

    try {
        yield call(Api.Command.startSelfAssessment, {
            selfAssessmentId,
            companyId,
        });

        yield put(Event.SelfAssessment.selfAssessmentStarted({
            selfAssessmentId,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Selbstauskunft konnte nicht gestartet werden.',
        }));
    }
};

export function* startSelfAssessmentFlow() {
    while (true) {
        const action: Action<StartSelfAssessmentPayload> = yield take(
            Command.SelfAssessment.startSelfAssessment.toString(),
        );

        yield fork(onStartSelfAssessment, action.payload);
    }
}
