import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card, CardContent,
    FormControl,
    FormControlLabel,
    FormLabel, MenuItem, Radio, RadioGroup, Select,
    SelectChangeEvent,
    TextField, Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import Command from '../../state/actions/command';
import SaveIcon from '@mui/icons-material/Save';
import {theme} from '../../mui/theme';

const styles = {
    wrapperBox: {
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'auto',
    },
    formWrapper: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        paddingTop: 3,
    },
    inputFieldSpacing: {
        marginTop: 2.5,
    },
    zipInputField: {
        marginTop: 2.5,
        width: '140px',
    },
    cityInputField: {
        marginTop: 2.5,
        marginLeft: 2,
        width: 'calc(100% - 156px)',
    },
    sectionDivider: {
        marginTop: 4,
        marginBottom: 2.5,
    },
    saveButton: {
        marginTop: 2,
    },
    testCategories: {
        flex: 2,
        [theme.breakpoints.up('sm')]: {
            marginLeft: 2,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: 2,
        },
    },
    baseData: {
        flex: 3,
        [theme.breakpoints.up('sm')]: {
            marginRight: 2,
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginBottom: 2,
        },
    },
};

const TRAVEL_REGION_DATA: string[] = [
    'Prignitz',
    'Ruppiner Seenland',
    'Uckermark',
    'Barnimer Land',
    'Havelland',
    'Potsdam',
    'Seenland Oder-Spree',
    'Dahme-Seenland',
    'Fläming',
    'Cottbus/Spreewald',
    'Elbe-Elster-Land',
    'Lausitzer Seenland',
];

const BaseData = () => {
    const dispatch = useDispatch();
    const isAdmin = useSelector(Selector.User.isAdmin);
    const selectedCompany = useSelector(Selector.Company.selectedCompany);
    const [testCategory, setTestCategory] = useState<string|null>(null);

    const [dataCompanyName, setDataCompanyName] = useState<string>('');
    const [dataRestaurantName, setDataRestaurantName] = useState<string>('');
    const [dataBillingStreet, setDataBillingStreet] = useState<string>('');
    const [dataBillingZip, setDataBillingZip] = useState<string>('');
    const [dataBillingCity, setDataBillingCity] = useState<string>('');
    const [dataContactPerson, setDataContactPerson] = useState<string>('');
    const [dataStreet, setDataStreet] = useState<string>('');
    const [dataZip, setDataZip] = useState<string>('');
    const [dataCity, setDataCity] = useState<string>('');
    const [dataPhone, setDataPhone] = useState<string>('');
    const [dataEmail, setDataEmail] = useState<string>('');
    const [dataWeb, setDataWeb] = useState<string>('');
    const [dataHidden, setDataHidden] = useState<boolean>(false);
    const [dataTravelRegion, setDataTravelRegion] = useState<string>('');

    useEffect(() => {
        if (!selectedCompany) {
            return;
        }

        setDataCompanyName(selectedCompany.name);
        setDataRestaurantName(selectedCompany.restaurantName);
        setDataBillingStreet(selectedCompany.billingStreet);
        setDataBillingZip(selectedCompany.billingZip);
        setDataBillingCity(selectedCompany.billingCity);
        setDataContactPerson(selectedCompany.contactPerson);
        setDataEmail(selectedCompany.email);
        setDataStreet(selectedCompany.street);
        setDataZip(selectedCompany.zip);
        setDataCity(selectedCompany.city);
        setDataPhone(selectedCompany.phone);
        setDataWeb(selectedCompany.web);
        setDataHidden(selectedCompany.hidden);
        setDataTravelRegion(selectedCompany.travelRegion);

        setTestCategory(selectedCompany.testCategory);
    }, [selectedCompany]);

    if (!selectedCompany) {
        return null;
    }

    const saveBaseData = () => {
        dispatch(Command.Company.updateBasicCompanyData({
            companyId: selectedCompany.id,
            restaurantName: dataRestaurantName,
            companyName: dataCompanyName,
            billingStreet: dataBillingStreet,
            billingZip: dataBillingZip,
            billingCity: dataBillingCity,
            contactPerson: dataContactPerson,
            street: dataStreet,
            zip: dataZip,
            city: dataCity,
            phone: dataPhone,
            email: dataEmail,
            web: dataWeb,
            testCategory: testCategory || undefined,
            hidden: Boolean(dataHidden),
            travelRegion: dataTravelRegion,
            bookedQualityCheck: selectedCompany.bookedQualityCheck,
            bookedQualityCheckDate: selectedCompany.bookedQualityCheckDate,
            bookedMarketing: selectedCompany.bookedMarketing,
            bookedMarketingDate: selectedCompany.bookedMarketingDate,
        }));
    };

    const disableTestcategory = (!(testCategory === undefined || testCategory === null || isAdmin));

    return (
        <Box sx={styles.wrapperBox}>
            <Typography variant={'h1'}>
                Ihre Stammdaten in der Brandenburger Gastlichkeit
            </Typography>
            <Box sx={styles.formWrapper}>
                <Card sx={styles.baseData}>
                    <CardContent>
                        <TextField
                            label={'Gastroname'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            value={dataRestaurantName}
                            onChange={event => setDataRestaurantName(event.target.value)}
                        />
                        <TextField
                            label={'Name des Betriebes (Betreiber)'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataCompanyName}
                            onChange={event => setDataCompanyName(event.target.value)}
                        />

                        <Typography variant={'body1'} sx={styles.sectionDivider}>
                           Rechnungsadresse
                        </Typography>

                        <TextField
                            label={'Straße'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            value={dataBillingStreet}
                            onChange={event => setDataBillingStreet(event.target.value)}
                        />
                        <TextField
                            label={'Postleitzahl'}
                            variant={'outlined'}
                            size={'small'}
                            sx={styles.zipInputField}
                            value={dataBillingZip}
                            onChange={event => setDataBillingZip(event.target.value)}
                        />
                        <TextField
                            label={'Stadt'}
                            variant={'outlined'}
                            size={'small'}
                            sx={styles.cityInputField}
                            value={dataBillingCity}
                            onChange={event => setDataBillingCity(event.target.value)}
                        />

                        <Typography variant={'body1'} sx={styles.sectionDivider}>
                            Kontaktdaten
                        </Typography>

                        <TextField
                            label={'Kontaktperson'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            value={dataContactPerson}
                            onChange={event => setDataContactPerson(event.target.value)}
                        />
                        <TextField
                            label={'Straße'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataStreet}
                            onChange={event => setDataStreet(event.target.value)}
                        />
                        <TextField
                            label={'Postleitzahl'}
                            variant={'outlined'}
                            size={'small'}
                            sx={styles.zipInputField}
                            value={dataZip}
                            onChange={event => setDataZip(event.target.value)}
                        />
                        <TextField
                            label={'Stadt'}
                            variant={'outlined'}
                            size={'small'}
                            sx={styles.cityInputField}
                            value={dataCity}
                            onChange={event => setDataCity(event.target.value)}
                        />
                        <TextField
                            label={'Telefon'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataPhone}
                            onChange={event => setDataPhone(event.target.value)}
                        />
                        <TextField
                            label={'E-Mail'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataEmail}
                            onChange={event => setDataEmail(event.target.value)}
                        />
                        <TextField
                            label={'Internet'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataWeb}
                            onChange={event => setDataWeb(event.target.value)}
                        />
                    </CardContent>
                </Card>
                <Card sx={styles.testCategories}>
                    <CardContent>
                        <FormControl>
                            <FormLabel component={'legend'}>
                                <Typography variant={'body1'}>
                                    Ihre Testkategorie
                                </Typography>
                            </FormLabel>

                            <RadioGroup value={testCategory} onChange={event => setTestCategory(event.target.value)}>
                                <FormControlLabel
                                    value={'restaurant'}
                                    control={<Radio color={'primary'} disabled={disableTestcategory} />}
                                    label={'Restaurant'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'excursionRestaurant'}
                                    control={<Radio color={'primary'} disabled={disableTestcategory} />}
                                    label={'Ausflugslokal'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'cafe'}
                                    control={<Radio color={'primary'} disabled={disableTestcategory} />}
                                    label={'Café / Eisdiele'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'countryGuesthouse'}
                                    control={<Radio color={'primary'} disabled={disableTestcategory} />}
                                    label={'Landgasthof'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'pub'}
                                    control={<Radio color={'primary'} disabled={disableTestcategory} />}
                                    label={'Kneipe / Bar'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'hotel'}
                                    control={<Radio color={'primary'} disabled={disableTestcategory} />}
                                    label={'Hotel garni / Pension'}
                                    labelPlacement={'end'}
                                />
                            </RadioGroup>

                            <FormLabel component={'legend'}>
                                <Typography variant={'body1'}>
                                    Ihre Reiseregion
                                </Typography>
                            </FormLabel>
                            <Select
                                value={dataTravelRegion}
                                onChange={(event: SelectChangeEvent) => {
                                    setDataTravelRegion(event.target.value);
                                }}
                            >
                                {TRAVEL_REGION_DATA.map((region: string) => (
                                    <MenuItem value={region} key={region}> {region} </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </CardContent>
                </Card>
            </Box>
            <Button
                variant={'contained'}
                children={'Speichern'}
                startIcon={<SaveIcon />}
                onClick={saveBaseData}
                sx={styles.saveButton}
            />
        </Box>
    );
};

export default BaseData;
