import {Box, Button, Card, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import WebsiteCheckCategories from './WebsiteCheckCategories';
import WebsiteCheckQuestion from './WebsiteCheckQuestion';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import Command from '../../state/actions/command';
import FinalizeWebsiteCheckConfirmation from './FinalizeWebsiteCheckConfirmation';
import {theme} from '../../mui/theme';

const styles = {
    flexBox: {
        display: 'flex',
        columnGap: '30px',
    },
    categories: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '32px',
        },
    },
    questionnaire: {
        padding: 4,
        width: '100%',
    },
};

const WebsiteCheck = () => {
    const dispatch = useDispatch();
    const websiteCheck = useSelector(Selector.QuestionList.websiteCheck);
    const data = useSelector(Selector.WebsiteCheck.data);
    const websiteCheckSubmitted = useSelector(Selector.WebsiteCheck.submitted);
    const [activateCategory, setActivateCategory] = useState<number>(0);
    const [selectedAnswer, setSelectedAnswer] = useState<string|null>(null);
    const [conditionalsData, setConditionalsData] = useState<Record<string, string>|null>(null);
    const [confirmInfoOpen, setConfirmInfoOpen] = useState<boolean>(false);

    const activeQuestion = websiteCheck.questions ? websiteCheck.questions[activateCategory] : {};

    const isLastQuestion = websiteCheck.questions ?
        activateCategory === websiteCheck.questions.length - 1 : true;
    const selectedAnswerValue = selectedAnswer || data[activeQuestion.id] || null;
    const conditionalsValue = conditionalsData || data[activeQuestion.id + '_conditionals'] || {};
    const enableNextButton = Boolean(selectedAnswerValue) &&
        !activeQuestion.conditionals
            ?.filter((conditional: any) => conditional.if_answer === selectedAnswerValue)
            ?.some((conditional: any) => !conditionalsValue[conditional.id]);

    const areAllQuestionsAnswered = websiteCheck.questions ?
        !websiteCheck.questions.some((question: any) => !data[question.id]) : false;
    const areAllConditionalsAnswered = websiteCheck.questions ?
        !websiteCheck.questions
            .map(
                (question: any) => !question.conditionals
                    ?.filter((conditional: any) => conditional.if_answer === data[question.id])
                    ?.some((conditional: any) => !data[question.id + '_conditionals']?.[conditional.id]),
            )
            .some((value: any) => !value) : false;
    const canFinalize = areAllQuestionsAnswered && areAllConditionalsAnswered;

    const saveAnswer = () => {
        if (selectedAnswer && !websiteCheckSubmitted) {
            dispatch(Command.WebsiteCheck.updateWebsiteCheckData({
                data: {
                    [activeQuestion.id]: selectedAnswer,
                    [activeQuestion.id + '_conditionals']:
                        conditionalsData && Object.values(conditionalsData).length > 0 ? conditionalsData : undefined,
                },
            }));
        }
    };

    const goToNextQuestion = () => {
        saveAnswer();
        setActivateCategory(isLastQuestion ? 0 : activateCategory + 1);
        setSelectedAnswer(null);
    };

    const goToPreviousQuestion = () => {
        setActivateCategory(activateCategory - 1);
        setSelectedAnswer(null);
    };

    useEffect(() => {
        if (websiteCheck.questions) {
            let counter = -1;
            websiteCheck.questions.forEach((question: any, index: number) => {
                if (data[question.id] && counter === index - 1) {
                    counter = index;
                }
            });

            if (counter >= 0) {
                setActivateCategory(counter);
            }
        } else {
            setActivateCategory(0);
        }
    }, [websiteCheck]);

    const confirmFinalizeWebsiteCheck = () => {
        saveAnswer();
        setConfirmInfoOpen(true);
    };

    const finalizeWebsiteCheck = () => {
        if (!canFinalize) {
            return;
        }

        dispatch(Command.WebsiteCheck.finalizeWebsiteCheck({}));
        setConfirmInfoOpen(false);
    };

    return (
        <Box sx={styles.flexBox}>
            {websiteCheck.questions && (
                <>
                    <Box sx={styles.categories}>
                        <WebsiteCheckCategories
                            activeCategory={activateCategory}
                            categories={websiteCheck.questions.map(((question: any) => question.label))}
                        />
                    </Box>

                    <Card sx={styles.questionnaire}>
                        <WebsiteCheckQuestion
                            question={activeQuestion}
                            value={selectedAnswerValue}
                            onChange={setSelectedAnswer}
                            conditionalsValue={conditionalsValue}
                            onChangeConditionalsValue={setConditionalsData}
                            disabled={websiteCheckSubmitted}
                        />

                        <Box sx={{ marginTop: 2 }}>
                            <Button
                                variant={'outlined'}
                                onClick={goToPreviousQuestion}
                                disabled={activateCategory <= 0}
                                sx={{ marginRight: 1 }}
                            >
                                Zurück
                            </Button>

                            <Button
                                variant={'contained'}
                                onClick={goToNextQuestion}
                                disabled={!enableNextButton}
                            >
                                {(isLastQuestion && !websiteCheckSubmitted) ? 'Speichern' : 'Weiter'}
                            </Button>

                            {(isLastQuestion && !websiteCheckSubmitted) && (<>
                                <Typography component={'span'}>&nbsp; oder &nbsp;</Typography>
                                <Button
                                    variant={'contained'}
                                    onClick={confirmFinalizeWebsiteCheck}
                                    disabled={!enableNextButton}
                                >
                                    Jetzt einreichen
                                </Button>
                            </>)}
                        </Box>
                    </Card>
                    <FinalizeWebsiteCheckConfirmation
                        open={canFinalize && confirmInfoOpen}
                        onClose={() => setConfirmInfoOpen(false)}
                        onFinalize={finalizeWebsiteCheck}
                    />
                </>
            )}
        </Box>
    );
};

export default WebsiteCheck;
