import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@mui/material';
import {theme} from './mui/theme';
import { BrowserRouter } from 'react-router-dom';
import Keycloak from './keycloak';
import {store} from './state/store';
import {Provider} from 'react-redux';
import Event from './state/actions/event';
import Notifier from './components/common/Notifier';

(async () => {
    await Keycloak.initialize();

    const parsedToken = Keycloak.instance.tokenParsed!;
    const userId = parsedToken.sub || '';
    const roles = parsedToken.resource_access?.['fe-dehoga-analytics-app']?.roles || [];

    store.dispatch(Event.User.userAuthenticated({ userId, roles }));

    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement,
    );

    /*
    <React.StrictMode> was removed for now due to the double useEffect call issue
     */

    root.render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Notifier />
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ThemeProvider>
        </Provider>,
    );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
