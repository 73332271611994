import {Action, handleActions} from 'redux-actions';
import Event from '../actions/event';
import {
    WebsiteCheckDataUpdatedPayload,
    WebsiteCheckFetchedPayload, WebsiteCheckFinalizedPayload,
    WebsiteCheckNotFoundPayload,
    WebsiteCheckReopenedPayload,
    WebsiteCheckStartedPayload,
} from '../actions/websiteCheckEvent';

export interface WebsiteCheckState {
    loaded: boolean;
    websiteCheckId: string|null;
    data: any;
    submitted: boolean;
}

export const websiteCheckInitialState: WebsiteCheckState = {
    loaded: false,
    websiteCheckId: null,
    data: {},
    submitted: false,
};

/* eslint-disable max-len */
export const websiteCheckReducer = handleActions<WebsiteCheckState, any>(
    {
        [Event.WebsiteCheck.stateCleared.toString()]: (): WebsiteCheckState => {
            return {
                ...websiteCheckInitialState,
            };
        },
        [Event.WebsiteCheck.websiteCheckFetched.toString()]: (state, action: Action<WebsiteCheckFetchedPayload>): WebsiteCheckState => {
            return {
                ...state,
                loaded: true,
                websiteCheckId: action.payload.websiteCheck.websiteCheckId,
                data: action.payload.websiteCheck.data,
                submitted: action.payload.websiteCheck.submitted,
            };
        },
        [Event.WebsiteCheck.websiteCheckNotFound.toString()]: (state, action: Action<WebsiteCheckNotFoundPayload>): WebsiteCheckState => {
            return {
                ...state,
                loaded: true,
            };
        },
        [Event.WebsiteCheck.websiteCheckStarted.toString()]: (state, action: Action<WebsiteCheckStartedPayload>): WebsiteCheckState => {
            return {
                ...state,
                websiteCheckId: action.payload.websiteCheckId,
            };
        },
        [Event.WebsiteCheck.websiteCheckDataUpdated.toString()]: (state, action: Action<WebsiteCheckDataUpdatedPayload>): WebsiteCheckState => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload.data,
                },
            };
        },
        [Event.WebsiteCheck.websiteCheckFinalized.toString()]: (state, action: Action<WebsiteCheckFinalizedPayload>): WebsiteCheckState => {
            return {
                ...state,
                submitted: true,
            };
        },
        [Event.WebsiteCheck.websiteCheckReopened.toString()]: (state, action: Action<WebsiteCheckReopenedPayload>): WebsiteCheckState => {
            return {
                ...state,
                submitted: false,
            };
        },
    },
    websiteCheckInitialState,
);
