import {ReduxState} from '../reducer';

/* eslint-disable max-len */
const activeVersion = (state: ReduxState): any => state.questionList.activeVersion;
const mysteryTestHotel = (state: ReduxState): any => state.questionList.mysteryTestHotel;
const mysteryTestRestaurant = (state: ReduxState): any => state.questionList.mysteryTestRestaurant;
const websiteCheck = (state: ReduxState): any => state.questionList.websiteCheck;
const selfAssessment = (state: ReduxState): any => state.questionList.selfAssessment;
const mysteryTestHotelConfiguration = (state: ReduxState): any => state.questionList.mysteryTestHotelConfiguration;
const mysteryTestHotelConfigurationId = (state: ReduxState): string|undefined => state.questionList.mysteryTestHotelConfigurationId;
const mysteryTestRestaurantConfiguration = (state: ReduxState): any => state.questionList.mysteryTestRestaurantConfiguration;
const websiteCheckConfiguration = (state: ReduxState): any => state.questionList.websiteCheckConfiguration;
const selfAssessmentConfiguration = (state: ReduxState): any => state.questionList.selfAssessmentConfiguration;

const QuestionListSelector = {
    activeVersion,
    mysteryTestHotel,
    mysteryTestRestaurant,
    websiteCheck,
    selfAssessment,
    mysteryTestHotelConfiguration,
    mysteryTestHotelConfigurationId,
    mysteryTestRestaurantConfiguration,
    websiteCheckConfiguration,
    selfAssessmentConfiguration,
};

export default QuestionListSelector;
