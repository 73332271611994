import {combineReducers} from 'redux';
import {CompanyState, companyReducer} from './reducers/companyReducer';
import {UserState, userReducer} from './reducers/userReducer';
import {SelfAssessmentState, selfAssessmentReducer} from './reducers/selfAssessmentReducer';
import {WebsiteCheckState, websiteCheckReducer} from './reducers/websiteCheckReducer';
import {MysteryTestState, mysteryTestReducer} from './reducers/mysteryTestReducer';
import {NotificationState, notificationReducer} from './reducers/notificationReducer';
import {QuestionListState, questionListReducer} from './reducers/questionListReducer';

export interface ReduxState {
    user: UserState;
    company: CompanyState;
    selfAssessment: SelfAssessmentState;
    websiteCheck: WebsiteCheckState;
    mysteryTest: MysteryTestState;
    notification: NotificationState;
    questionList: QuestionListState;
}

export const rootReducer = combineReducers({
    user: userReducer,
    company: companyReducer,
    selfAssessment: selfAssessmentReducer,
    websiteCheck: websiteCheckReducer,
    mysteryTest: mysteryTestReducer,
    notification: notificationReducer,
    questionList: questionListReducer,
});
