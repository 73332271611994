import {createAction} from 'redux-actions';

const createQuestionListEvent = <T>(type: string) => createAction<T>('_E/QL/' + type);

export interface QuestionListFetchedPayload {
    data: any;
}
export type QuestionConfigurationFetchedPayload = any;

export interface MysteryTestImportQuestionsPayload {
    type: string;
    data: any;
}
export interface MysteryTestHotelCategoryAddedPayload {
}

export interface MysteryTestHotelCategoryDeletedPayload {
    id: string;
}

export interface MysteryTestHotelCategoryMovedUpPayload {
    position: number;
}

export interface MysteryTestHotelCategoryMovedDownPayload {
    position: number;
}

export interface MysteryTestHotelCategoryLabelChangedPayload {
    id: string;
    label: string;
}

export interface MysteryTestHotelCategoryDescriptionChangedPayload {
    id: string;
    description: string;
}

export interface MysteryTestHotelCategoryAdditionalPointsFlagChangedPayload {
    id: string;
    value: boolean;
}

export interface MysteryTestHotelSubCategoryAddedPayload {
    parentId: string;
}

export interface MysteryTestHotelSubCategoryDeletedPayload {
    id: string;
    parentId: string;
}

export interface MysteryTestHotelSubCategoryMovedUpPayload {
    id: string;
    parentId: string;
}

export interface MysteryTestHotelSubCategoryMovedDownPayload {
    id: string;
    parentId: string;
}

export interface MysteryTestHotelSubCategoryLabelChangedPayload {
    id: string;
    parentId: string;
    label: string;
}

export interface MysteryTestHotelSubCategoryDescriptionChangedPayload {
    id: string;
    parentId: string;
    description: string;
}

export interface MysteryTestHotelQuestionAddedPayload {
    parentId: string;
}

export interface MysteryTestHotelQuestionDeletedPayload {
    id: string;
    parentId: string;
}

export interface MysteryTestHotelQuestionMovedUpPayload {
    id: string;
    parentId: string;
    parentParentId: string;
}

export interface MysteryTestHotelQuestionMovedDownPayload {
    id: string;
    parentId: string;
    parentParentId: string;
}

export interface MysteryTestHotelQuestionChangedPayload {
    id: string;
    label: string;
}

export interface MysteryTestHotelQuestionTypeChangedPayload {
    id: string;
    type: string;
}

export interface MysteryTestHotelQuestionRequiredChangedPayload {
    id: string;
    required: boolean;
}

export interface MysteryTestHotelQuestionDependencyQuestionChangedPayload {
    id: string;
    questionId: string;
}

export interface MysteryTestHotelQuestionDependencyQuestionValueChangedPayload {
    id: string;
    valueId: string;
}

export interface MysteryTestHotelQuestionVisibleForPayload {
    id: string;
    valueArray: string[];
}

export interface MysteryTestHotelAnswerAddedPayload {
    parentId: string;
    type: string;
}

export interface MysteryTestHotelAnswerDeletedPayload {
    id: string;
    parentId: string;
}

export interface MysteryTestHotelAnswerMovedUpPayload {
    id: string;
    pId: string;
    ppId: string;
    pppId: string;
}

export interface MysteryTestHotelAnswerMovedDownPayload {
    id: string;
    pId: string;
    ppId: string;
    pppId: string;
}

export interface MysteryTestHotelAnswerLabelChangedPayload {
    id: string;
    parentId: string;
    label: string;
}

export interface MysteryTestHotelAnswerPointsChangedPayload {
    id: string;
    parentId: string;
    points: number;
}

/* eslint-disable max-len */
const MysteryTestEvent = {
    questionListFetched: createQuestionListEvent<QuestionListFetchedPayload>('QUESTION_LIST_FETCHED'),
    questionConfigurationFetched: createQuestionListEvent<QuestionConfigurationFetchedPayload>('QUESTION_CONFIGURATION_FETCHED'),
    mysteryTestImportQuestions: createQuestionListEvent<MysteryTestImportQuestionsPayload>('MYSTERY_TEST_IMPORT_QUESTIONS'),

    mysteryTestHotelCategoryAdded: createQuestionListEvent<MysteryTestHotelCategoryAddedPayload>('MYSTERY_TEST_HOTEL_CATEGORY_ADDED'),
    mysteryTestHotelCategoryDeleted: createQuestionListEvent<MysteryTestHotelCategoryDeletedPayload>('MYSTERY_TEST_HOTEL_CATEGORY_DELETED'),
    mysteryTestHotelCategoryMovedUp: createQuestionListEvent<MysteryTestHotelCategoryMovedUpPayload>('MYSTERY_TEST_HOTEL_CATEGORY_MOVED_UP'),
    mysteryTestHotelCategoryMovedDown: createQuestionListEvent<MysteryTestHotelCategoryMovedDownPayload>('MYSTERY_TEST_HOTEL_CATEGORY_MOVED_DOWN'),
    mysteryTestHotelCategoryLabelChanged: createQuestionListEvent<MysteryTestHotelCategoryLabelChangedPayload>('MYSTERY_TEST_HOTEL_CATEGORY_LABEL_CHANGED'),
    mysteryTestHotelCategoryDescriptionChanged: createQuestionListEvent<MysteryTestHotelCategoryDescriptionChangedPayload>('MYSTERY_TEST_HOTEL_CATEGORY_DESCRIPTION_CHANGED'),
    mysteryTestHotelCategoryAdditionalPointsFlagChanged: createQuestionListEvent<MysteryTestHotelCategoryAdditionalPointsFlagChangedPayload>('MYSTERY_TEST_HOTEL_CATEGORY_ADDITIONAL_POINTS_FLAG_CHANGED'),

    mysteryTestHotelSubCategoryAdded: createQuestionListEvent<MysteryTestHotelSubCategoryAddedPayload>('MYSTERY_TEST_HOTEL_SUBCATEGORY_ADDED'),
    mysteryTestHotelSubCategoryDeleted: createQuestionListEvent<MysteryTestHotelSubCategoryDeletedPayload>('MYSTERY_TEST_HOTEL_SUBCATEGORY_DELETED'),
    mysteryTestHotelSubCategoryMovedUp: createQuestionListEvent<MysteryTestHotelSubCategoryMovedUpPayload>('MYSTERY_TEST_HOTEL_SUBCATEGORY_MOVED_UP'),
    mysteryTestHotelSubCategoryMovedDown: createQuestionListEvent<MysteryTestHotelSubCategoryMovedDownPayload>('MYSTERY_TEST_HOTEL_SUBCATEGORY_MOVED_DOWN'),
    mysteryTestHotelSubCategoryLabelChanged: createQuestionListEvent<MysteryTestHotelSubCategoryLabelChangedPayload>('MYSTERY_TEST_HOTEL_SUBCATEGORY_LABEL_CHANGED'),
    mysteryTestHotelSubCategoryDescriptionChanged: createQuestionListEvent<MysteryTestHotelSubCategoryDescriptionChangedPayload>('MYSTERY_TEST_HOTEL_SUBCATEGORY_DESCRIPTION_CHANGED'),

    mysteryTestHotelQuestionAdded: createQuestionListEvent<MysteryTestHotelQuestionAddedPayload>('MYSTERY_TEST_HOTEL_QUESTION_ADDED'),
    mysteryTestHotelQuestionDeleted: createQuestionListEvent<MysteryTestHotelQuestionDeletedPayload>('MYSTERY_TEST_HOTEL_QUESTION_DELETED'),
    mysteryTestHotelQuestionMovedUp: createQuestionListEvent<MysteryTestHotelQuestionMovedUpPayload>('MYSTERY_TEST_HOTEL_QUESTION_MOVED_UP'),
    mysteryTestHotelQuestionMovedDown: createQuestionListEvent<MysteryTestHotelQuestionMovedDownPayload>('MYSTERY_TEST_HOTEL_QUESTION_MOVED_DOWN'),
    mysteryTestHotelQuestionChanged: createQuestionListEvent<MysteryTestHotelQuestionChangedPayload>('MYSTERY_TEST_HOTEL_QUESTION_CHANGED'),
    mysteryTestHotelQuestionTypeChanged: createQuestionListEvent<MysteryTestHotelQuestionTypeChangedPayload>('MYSTERY_TEST_HOTEL_QUESTION_TYPE_CHANGED'),
    mysteryTestHotelQuestionRequiredChanged: createQuestionListEvent<MysteryTestHotelQuestionRequiredChangedPayload>('MYSTERY_TEST_HOTEL_QUESTION_REQUIRED_CHANGED'),
    mysteryTestHotelQuestionDependencyQuestionChanged: createQuestionListEvent<MysteryTestHotelQuestionDependencyQuestionChangedPayload>('MYSTERY_TEST_HOTEL_QUESTION_DEPENTENCY_QUESTION_CHANGED'),
    mysteryTestHotelQuestionDependencyValueChanged: createQuestionListEvent<MysteryTestHotelQuestionDependencyQuestionValueChangedPayload>('MYSTERY_TEST_HOTEL_QUESTION_DEPENDENCY_VALUE_CHANGED'),
    mysteryTestHotelQuestionVisibleForChanged: createQuestionListEvent<MysteryTestHotelQuestionVisibleForPayload>('MYSTERY_TEST_HOTEL_VISIBLE_FOR_CHANGED'),

    mysteryTestHotelAnswerAdded: createQuestionListEvent<MysteryTestHotelAnswerAddedPayload>('MYSTERY_TEST_HOTEL_ANSWER_ADDED'),
    mysteryTestHotelAnswerDeleted: createQuestionListEvent<MysteryTestHotelAnswerDeletedPayload>('MYSTERY_TEST_HOTEL_ANSWER_DELETED'),
    mysteryTestHotelAnswerMovedUp: createQuestionListEvent<MysteryTestHotelAnswerMovedUpPayload>('MYSTERY_TEST_HOTEL_ANSWER_MOVED_UP'),
    mysteryTestHotelAnswerMovedDown: createQuestionListEvent<MysteryTestHotelAnswerMovedDownPayload>('MYSTERY_TEST_HOTEL_ANSWER_MOVED_DOWN'),
    mysteryTestHotelAnswerLabelChanged: createQuestionListEvent<MysteryTestHotelAnswerLabelChangedPayload>('MYSTERY_TEST_HOTEL_ANSWER_LABEL_CHANGED'),
    mysteryTestHotelAnswerPointsChanged: createQuestionListEvent<MysteryTestHotelAnswerPointsChangedPayload>('MYSTERY_TEST_HOTEL_ANSWER_POINTS_CHANGED'),
};

export default MysteryTestEvent;
