import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import {CreateCompanyPayload} from '../../actions/companyCommand';
import Event from '../../actions/event';
import Api from '../../../api';
import {v4 as uuidv4} from 'uuid';

const onCreateCompany = function*(data: CreateCompanyPayload) {
    const companyId = uuidv4();

    try {
        yield call(Api.Command.createCompany, {
            companyId,
            companyName: data.name,
            email: data.email,
        });

        yield put(Event.Company.companyCreated({
            company: {
                id: companyId,
                name: data.name,
                email: data.email,
                billingStreet: '',
                billingZip: '',
                billingCity: '',
                contactPerson: '',
                street: '',
                zip: '',
                city: '',
                phone: '',
                restaurantName: '',
                web: '',
                employees: [],
                testCategory: null,
                tester: null,
                hidden: false,
                travelRegion: '',
                bookedQualityCheck: false,
                bookedQualityCheckDate: '',
                bookedMarketing: false,
                bookedMarketingDate: '',
            },
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Das Unternehmen konnte nicht erstellt werden.',
        }));
    }
};

export function* createCompanyFlow() {
    while (true) {
        const action: Action<CreateCompanyPayload> = yield take(Command.Company.createCompany.toString());
        yield fork(onCreateCompany, action.payload);
    }
}
