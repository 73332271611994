import {Divider, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import React from 'react';
import {useNavigate} from 'react-router';
import {Company} from '../../state/types/Company';

const CompanySelector = () => {
    const navigate = useNavigate();
    const companyList = useSelector(Selector.Company.companyList);
    const selectedCompanyId = useSelector(Selector.Company.selectedCompanyId);

    if (!selectedCompanyId) {
        return null;
    }

    const updateSelectedCompany = (event: SelectChangeEvent) => {
        navigate(`/companies/${event.target.value}/base-data`);
    };

    return (<>
        <Divider sx={{ marginBottom: 2 }} />
        <Select
            fullWidth={true}
            value={selectedCompanyId}
            onChange={updateSelectedCompany}
            MenuProps={{
                sx: {
                    '& .MuiPaper-root': {
                        maxHeight: 'calc(100vh - 100px) !important',
                    },
                },
            }}
        >
            {companyList.filter(company => !company.hidden).map((company: Company) => (
                <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
            ))}
        </Select>
        <Divider sx={{ marginTop: 2 }} />
    </>);
};

export default CompanySelector;
