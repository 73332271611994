import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {SelfAssessment} from '../../types/SelfAssessment';
import {Action} from 'redux-actions';
import {FetchSelfAssessmentPayload} from '../../actions/selfAssessmentCommand';

const onFetchSelfAssessment = function*(data: FetchSelfAssessmentPayload) {
    try {
        const selfAssessment: SelfAssessment = yield call(Api.Query.selfAssessment, data.companyId);

        yield put(Event.SelfAssessment.selfAssessmentFetched({ selfAssessment }));
    } catch (e) {
        if (e.response?.status === 404) {
            yield put(Event.SelfAssessment.selfAssessmentNotFound({}));
            return;
        }

        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Selbstauskunft konnte nicht geladen werden.',
        }));
    }
};

export function* fetchSelfAssessmentFlow() {
    while (true) {
        const action: Action<FetchSelfAssessmentPayload> = yield take(
            Command.SelfAssessment.fetchSelfAssessment.toString(),
        );

        yield fork(onFetchSelfAssessment, action.payload);
    }
}
