import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import {UpdateBasicCompanyDataPayload} from '../../actions/companyCommand';
import Event from '../../actions/event';
import Api from '../../../api';

const onUpdateBasicCompanyData = function*(data: UpdateBasicCompanyDataPayload) {
    try {
        yield call(Api.Command.updateBasicCompanyData, {
            companyId: data.companyId,
            restaurantName: data.restaurantName,
            companyName: data.companyName,
            billingStreet: data.billingStreet,
            billingZip: data.billingZip,
            billingCity: data.billingCity,
            contactPerson: data.contactPerson,
            street: data.street,
            zip: data.zip,
            city: data.city,
            phone: data.phone,
            email: data.email,
            web: data.web,
            hidden: Boolean(data.hidden),
            travelRegion: data.travelRegion,
            bookedQualityCheck: Boolean(data.bookedQualityCheck),
            bookedQualityCheckDate: data.bookedQualityCheckDate,
            bookedMarketing: Boolean(data.bookedMarketing),
            bookedMarketingDate: data.bookedMarketingDate,
        });

        if (data.testCategory) {
            yield call(Api.Command.setCompanyTestCategory, {
                companyId: data.companyId,
                testCategory: data.testCategory,
            });
        }

        yield put(Event.Company.basicCompanyDataUpdated({
            ...data,
        }));

        if (data.hidden) {
            yield put(Command.Notification.enqueueNotification({
                severity: 'success',
                message: 'Das Unternehmen wurde ausgeblendet.',
            }));
        } else {
            yield put(Command.Notification.enqueueNotification({
                severity: 'success',
                message: 'Die Stammdaten wurden aktualisiert.',
            }));
        }
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Stammdaten konnten nicht aktualisiert werden.',
        }));
    }
};

export function* updateBasicCompanyDataFlow() {
    while (true) {
        const action: Action<UpdateBasicCompanyDataPayload> = yield take(
            Command.Company.updateBasicCompanyData.toString(),
        );

        yield fork(onUpdateBasicCompanyData, action.payload);
    }
}
