import {CompanyStatus} from '../../../state/types/Company';
import {Box, Tooltip, Typography} from '@mui/material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import LanguageIcon from '@mui/icons-material/Language';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';
import {useSelector} from 'react-redux';
import {Selector} from '../../../state/selector';
import Assets from '../../../assets/assets';
import LatestTestDate from './LatestTestDate';

interface CompanyStatusOverviewProps {
    status: CompanyStatus;
    version: number;
}

const CompanyStatusOverview = (props: CompanyStatusOverviewProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);

    const selfAssessmentColor = !props.status.selfAssessment[props.version] ? 'gray'
        : props.status.selfAssessment[props.version]?.submitted ? 'green' : 'orange';

    const websiteCheckColor = !props.status.websiteCheck[props.version] ? 'gray'
        : props.status.websiteCheck[props.version]?.submitted ? 'green' : 'orange';

    const mysteryTestColor = !props.status.mysteryTest[props.version] ? 'gray'
        : props.status.mysteryTest[props.version]?.submitted ? 'green' : 'orange';

    const selfAssessmentScoreAvailable = Boolean(
        props.status.selfAssessment[props.version]?.score &&
        props.status.selfAssessment[props.version]?.achievableScore,
    );

    const websiteCheckScoreAvailable = Boolean(
        props.status.websiteCheck[props.version]?.score &&
        props.status.websiteCheck[props.version]?.achievableScore,
    );

    const mysteryTestScoreAvailable = Boolean(
        props.status.mysteryTest[props.version]?.score &&
        props.status.mysteryTest[props.version]?.achievableScore,
    );

    const attempt = Number(props.status.mysteryTest[props.version]?.attempt);

    const trustYouScoreAvailable = typeof props.status.trustYouScore[props.version]?.score === 'number';

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {(isAdmin && mysteryTestScoreAvailable && attempt > 1) && (
                <Tooltip
                    title={(
                        <div>
                            <div>{attempt} Versuche</div>
                        </div>
                    )}
                    children={(
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ErrorOutlineIcon sx={{ color: 'red', marginRight: 0.5 }} />
                            </Box>
                        </Box>
                    )}
                />
            )}
            <Tooltip
                title={(
                    <div>
                        <div>Selbstauskunft</div>
                        {(isAdmin && selfAssessmentScoreAvailable) && (
                            <div>
                                Punkte: {props.status.selfAssessment[props.version].score}
                                {' /'} {props.status.selfAssessment[props.version].achievableScore}
                            </div>
                        )}
                        {(isAdmin && props.status.selfAssessment[props.version]) && (
                            <LatestTestDate data={props.status.selfAssessment[props.version]} useStyle={false} />
                        )}
                    </div>
                )}
                children={(
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <RecordVoiceOverIcon sx={{ color: selfAssessmentColor, marginRight: 0.5 }} />
                        {(isAdmin && selfAssessmentScoreAvailable) && (
                            <Typography variant={'body2'} component={'span'} fontSize={11}>
                                {(props.status.selfAssessment[props.version].score!
                                    / props.status.selfAssessment[props.version].achievableScore! * 100).toFixed(0)}%
                            </Typography>
                        )}
                        {(isAdmin && !selfAssessmentScoreAvailable) && (
                            <Typography variant={'body2'} component={'span'} fontSize={11}>—</Typography>
                        )}
                    </Box>
                )}
            />
            <Tooltip
                title={(
                    <div>
                        <div>Website-Check</div>
                        {(isAdmin && websiteCheckScoreAvailable) && (
                            <div>
                                Punkte: {props.status.websiteCheck[props.version].score}
                                {' /'} {props.status.websiteCheck[props.version].achievableScore}
                            </div>
                        )}
                        {(isAdmin && props.status.websiteCheck[props.version]) && (
                            <LatestTestDate data={props.status.websiteCheck[props.version]} useStyle={false} />
                        )}
                    </div>
                )}
                children={(
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <LanguageIcon sx={{ color: websiteCheckColor, marginRight: 0.5 }} />
                        {(isAdmin && websiteCheckScoreAvailable) && (
                            <Typography variant={'body2'} component={'span'} fontSize={11}>
                                {(props.status.websiteCheck[props.version].score!
                                    / props.status.websiteCheck[props.version].achievableScore! * 100).toFixed(0)}%
                            </Typography>
                        )}
                        {(isAdmin && !websiteCheckScoreAvailable) && (
                            <Typography variant={'body2'} component={'span'} fontSize={11}>—</Typography>
                        )}
                    </Box>
                )}
            />
            <Tooltip
                title={(
                    <div>
                        <div>Mysterytest</div>
                        {(isAdmin && mysteryTestScoreAvailable) && (
                            <div>
                                Versuch: {props.status.mysteryTest[props.version].attempt}<br />
                                Punkte: {props.status.mysteryTest[props.version].score}
                                {/* TO-DO : unschärfe(0) / Gesamteindruck(0) für die Berechnung berücksichtigen */}
                                {' /'} {props.status.mysteryTest[props.version].achievableScore!}
                            </div>
                        )}
                        {(isAdmin && props.status.mysteryTest[props.version]) && (
                            <LatestTestDate data={props.status.mysteryTest[props.version]} useStyle={false} />
                        )}
                    </div>
                )}
                children={(
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <PlaylistAddCheckIcon sx={{ color: mysteryTestColor }} />
                        {(isAdmin && mysteryTestScoreAvailable) && (
                            <Typography variant={'body2'} component={'span'} fontSize={11}>

                                {/* TO-DO : unschärfe(0) / Gesamteindruck(0) für die Berechnung berücksichtigen */}
                                {(props.status.mysteryTest[props.version].score!
                                    / (props.status.mysteryTest[props.version].achievableScore!) * 100).toFixed(0)}%
                            </Typography>
                        )}
                        {(isAdmin && !mysteryTestScoreAvailable) && (
                            <Typography variant={'body2'} component={'span'} fontSize={11}>—</Typography>
                        )}
                    </Box>
                )}
            />
            <Tooltip
                title={(
                    <div>
                        <div>TrustYou - Score</div>
                        {(isAdmin && trustYouScoreAvailable) && (
                            <div>
                                Punkte: {props.status.trustYouScore[props.version].score.toFixed(4)}
                                {' / 5.0'}
                            </div>
                        )}
                    </div>
                )}
                children={(
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img
                            src={Assets.trustYouBubbles}
                            width={24}
                            height={24}
                            style={{
                                objectFit: 'contain',
                                filter: Boolean(props.status.trustYouScore[props.version]) ?
                                    undefined : 'grayscale(1)',
                            }}
                        />
                        {(isAdmin && trustYouScoreAvailable) && (
                            <Typography variant={'body2'} component={'span'} fontSize={11}>
                                {(props.status.trustYouScore[props.version].score / 5 * 100).toFixed(0)}%
                            </Typography>
                        )}
                        {(isAdmin && !trustYouScoreAvailable) && (
                            <Typography variant={'body2'} component={'span'} fontSize={11}>—</Typography>
                        )}
                    </Box>
                )}
            />
        </Box>
    );
};

export default CompanyStatusOverview;
