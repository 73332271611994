import {createAction} from 'redux-actions';
import {Company, CompanyStatus} from '../types/Company';

const createCompanyEvent = <T>(type: string) => createAction<T>('_E/CO/' + type);

export interface CompanyCreatedPayload {
    company: Company;
}

export interface CompanyListFetchedPayload {
    companyList: Company[];
}

export interface CompanyStatusListFetchedPayload {
    companyStatusList: CompanyStatus[];
}

export interface CompanyEmployeeAssignedPayload {
    companyId: string;
    userId: string;
}

export interface CompanyEmployeeUnassignedPayload {
    companyId: string;
    userId: string;
}

export interface CompanySelectedPayload {
    companyId: string|null;
}

export interface BasicCompanyDataUpdatedPayload {
    companyId: string;
    restaurantName: string;
    companyName: string;
    billingStreet: string;
    billingZip: string;
    billingCity: string;
    contactPerson: string;
    street: string;
    zip: string;
    city: string;
    phone: string;
    email: string;
    web: string;
    travelRegion: string;
}

export interface CompanyTesterSetPayload {
    companyId: string;
    userId: string;
}

export interface CompanyTesterRemovedPayload {
    companyId: string;
}

const CompanyEvent = {
    companyCreated: createCompanyEvent<CompanyCreatedPayload>('COMPANY_CREATED'),
    companyListFetched: createCompanyEvent<CompanyListFetchedPayload>('COMPANY_LIST_FETCHED'),
    companyStatusListFetched: createCompanyEvent<CompanyStatusListFetchedPayload>('COMPANY_STATUS_LIST_FETCHED'),
    companyEmployeeAssigned: createCompanyEvent<CompanyEmployeeAssignedPayload>('COMPANY_EMPLOYEE_ASSIGNED'),
    companyEmployeeUnassigned: createCompanyEvent<CompanyEmployeeUnassignedPayload>('COMPANY_EMPLOYEE_UNASSIGNED'),
    companySelected: createCompanyEvent<CompanySelectedPayload>('COMPANY_SELECTED'),
    basicCompanyDataUpdated: createCompanyEvent<BasicCompanyDataUpdatedPayload>('BASIC_COMPANY_DATA_UPDATED'),
    companyTesterSet: createCompanyEvent<CompanyTesterSetPayload>('COMPANY_TESTER_SET'),
    companyTesterRemoved: createCompanyEvent<CompanyTesterRemovedPayload>('COMPANY_TESTER_REMOVED'),
};

export default CompanyEvent;
