import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {SubmitMysteryTestHotelChangesPayload} from '../../actions/questionListCommand';
import {Selector} from '../../selector';

const onSubmitMysteryTestHotelChanges = function*(data: SubmitMysteryTestHotelChangesPayload) {
    try {
        const mysteryTestHotelId: string = yield select(Selector.QuestionList.mysteryTestHotelConfigurationId);
        yield call(Api.Command.submitMysteryTestHotelChanges, {
            questionListData: JSON.stringify(data.data),
            questionConfigurationId: mysteryTestHotelId,
        });

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Die Änderungen wurden gespeichert.',
        }));

    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Fragen konnten nicht geupdatet werden.',
        }));
    }
};

export function* submitMysteryTestHotelChangesFlow() {
    while (true) {
        const action: Action<SubmitMysteryTestHotelChangesPayload> = yield take(
            Command.QuestionList.submitMysteryTestHotelChanges.toString(),
        );

        yield fork(onSubmitMysteryTestHotelChanges, action.payload);
    }
}
