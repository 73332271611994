import {DateTime} from 'luxon';
import {CompanyStatus} from '../state/types/Company';

export const formatDateTime = (dateTime: DateTime): string => {
    return dateTime.toFormat('dd.MM.yyyy HH:mm');
};

export const formatDateTimeReadable = (dateTime: DateTime): string => {
    return dateTime.toFormat('dd.MM.yyyy') + ' um ' + dateTime.toFormat(' HH:mm') + ' Uhr';
};

export const getDateStringFromCompanyStatus = (
    id: string,
    companyStatusMap: Record<string, CompanyStatus>,
    testType: string,
    submitted: boolean,
    version: number,
): string | undefined => {
    // @ts-ignore
    if(!id || !companyStatusMap[id][testType][version]) {
        return undefined;
    }
    // @ts-ignore
    return companyStatusMap[id][testType][version][submitted ? 'finishedAt' : 'startedAt'];
};
