import BaseData from './BaseData';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import BaseDataOverview from './BaseDataOverview';

const BaseDataPage = () => {
    const hasNormalCompanyAccess = useSelector(Selector.User.hasNormalCompanyAccess);
    const hasTesterCompanyAccess = useSelector(Selector.User.hasTesterCompanyAccess);

    if (hasNormalCompanyAccess) {
        return <BaseData />;
    } else if (hasTesterCompanyAccess) {
        return <BaseDataOverview />;
    } else {
        return null;
    }
};

export default BaseDataPage;
