import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {Selector} from '../../selector';
import {ReopenWebsiteCheckPayload} from '../../actions/websiteCheckCommand';

const onReopenWebsiteCheck = function*(data: ReopenWebsiteCheckPayload) {
    const websiteCheckId: string|null = yield select(Selector.WebsiteCheck.websiteCheckId);

    if (!websiteCheckId) {
        return;
    }

    try {
        yield call(Api.Command.reopenWebsiteCheck, {
            websiteCheckId,
        });

        yield put(Event.WebsiteCheck.websiteCheckReopened({}));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Der Website-Check wurde erneut geöffnet.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Website-Check konnte nicht erneut geöffnet werden.',
        }));
    }
};

export function* reopenWebsiteCheckFlow() {
    while (true) {
        const action: Action<ReopenWebsiteCheckPayload> = yield take(
            Command.WebsiteCheck.reopenWebsiteCheck.toString(),
        );

        yield fork(onReopenWebsiteCheck, action.payload);
    }
}
