import {WebsiteCheckQuestionType} from '../../data/website-check';
import {Box, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import React from 'react';
import WebsiteCheckQuestionConditionals from './WebsiteCheckQuestionConditionals';

interface WebsiteCheckQuestionProps {
    question: WebsiteCheckQuestionType;
    value: string|null;
    onChange: (newValue: string) => void;
    conditionalsValue: Record<string, string>;
    onChangeConditionalsValue: (newConditionalsValue: Record<string, string>) => void;
    disabled?: boolean;
}

const WebsiteCheckQuestion = (props: WebsiteCheckQuestionProps) => {
    return (
        <Box>
            <h3>{props.question.label}</h3>
            <p>{props.question.description}</p>
            <Box>
                <RadioGroup row={true} value={props.value} onChange={event => props.onChange(event.target.value)}>
                    {props.question.answers.map(answer => (
                        <FormControlLabel
                            key={answer.id}
                            value={answer.id}
                            control={<Radio disabled={props.disabled} />}
                            label={answer.label}
                        />
                    ))}
                </RadioGroup>
            </Box>
            <WebsiteCheckQuestionConditionals
                question={props.question}
                value={props.value}
                conditionalsValue={props.conditionalsValue}
                onChangeConditionalsValue={props.onChangeConditionalsValue}
                disabled={props.disabled}
            />
        </Box>
    );
};

export default WebsiteCheckQuestion;
