import {Box} from '@mui/material';

const DashboardPage = () => {
    return (
        <Box>
            Dashboard
        </Box>
    );
};

export default DashboardPage;
