import {createAction} from 'redux-actions';

const createUserCommand = <T>(type: string) => createAction<T>('_C/US/' + type);

export interface FetchUserPayload {
    userId: string;
}
export interface StartKeepAlivePayload {
}
export interface StopKeepAlivePayload {
}

const UserCommand = {
    fetchUser: createUserCommand<FetchUserPayload>('FETCH_USER'),
    startKeepAlive: createUserCommand<StartKeepAlivePayload>('START_KEEP_ALIVE'),
    stopKeepAlive: createUserCommand<StopKeepAlivePayload>('STOP_KEEP_ALIVE'),
};

export default UserCommand;
