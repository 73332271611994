import {createAction} from 'redux-actions';
import {MysteryTest, MysteryTestType} from '../types/MysteryTest';

const createMysteryTestEvent = <T>(type: string) => createAction<T>('_E/MT/' + type);

export interface MysteryTestFetchedPayload {
    mysteryTest: MysteryTest;
}

export interface MysteryTestNotFoundPayload {
}

export interface MysteryTestStartedPayload {
    mysteryTestId: string;
    type: MysteryTestType;
}

export interface MysteryTestDataUpdatedPayload {
    data: object;
}

export interface MysteryTestFinalizedPayload {
}

export interface MysteryTestReopenedPayload {
}

export interface NewMysteryTestAttemptStartedPayload {
}

/* eslint-disable max-len */
const MysteryTestEvent = {
    stateCleared: createMysteryTestEvent<{}>('STATE_CLEARED'),
    mysteryTestFetched: createMysteryTestEvent<MysteryTestFetchedPayload>('MYSTERY_TEST_FETCHED'),
    mysteryTestNotFound: createMysteryTestEvent<MysteryTestNotFoundPayload>('MYSTERY_TEST_NOT_FOUND'),
    mysteryTestStarted: createMysteryTestEvent<MysteryTestStartedPayload>('MYSTERY_TEST_STARTED'),
    mysteryTestDataUpdated: createMysteryTestEvent<MysteryTestDataUpdatedPayload>('MYSTERY_TEST_DATA_UPDATED'),
    mysteryTestFinalized: createMysteryTestEvent<MysteryTestFinalizedPayload>('MYSTERY_TEST_FINALIZED'),
    mysteryTestReopened: createMysteryTestEvent<MysteryTestReopenedPayload>('MYSTERY_TEST_REOPENED'),
    newMysteryTestAttemptStarted: createMysteryTestEvent<NewMysteryTestAttemptStartedPayload>('NEW_MYSTERY_TEST_ATTEMPT_STARTED'),
};

export default MysteryTestEvent;
