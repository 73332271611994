import {createAction} from 'redux-actions';

const createSelfAssessmentCommand = <T>(type: string) => createAction<T>('_C/SA/' + type);

export interface StartSelfAssessmentPayload {
}

export interface FetchSelfAssessmentPayload {
    companyId: string;
}

export interface AnswerSelfAssessmentQuestionPayload {
    data: object;
}

export interface FinalizeSelfAssessmentPayload {
}

export interface ReopenSelfAssessmentPayload {
}

/* eslint-disable max-len */
const SelfAssessmentCommand = {
    startSelfAssessment: createSelfAssessmentCommand<StartSelfAssessmentPayload>('START_SELF_ASSESSMENT'),
    fetchSelfAssessment: createSelfAssessmentCommand<FetchSelfAssessmentPayload>('FETCH_SELF_ASSESSMENT'),
    answerSelfAssessmentQuestion: createSelfAssessmentCommand<AnswerSelfAssessmentQuestionPayload>('ANSWER_SELF_ASSESSMENT_QUESTION'),
    finalizeSelfAssessment: createSelfAssessmentCommand<FinalizeSelfAssessmentPayload>('FINALIZE_SELF_ASSESSMENT'),
    reopenSelfAssessment: createSelfAssessmentCommand<ReopenSelfAssessmentPayload>('REOPEN_SELF_ASSESSMENT'),
};

export default SelfAssessmentCommand;
