import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import {ReduxState} from '../../state/reducer';
import Api from '../../api';

interface SetCompanyHiddenDialogProps {
    open: boolean;
    companyId?: string;
    onClose: () => void;
}

const SetCompanyHiddenDialog = (props: SetCompanyHiddenDialogProps) => {
    const company = useSelector((state: ReduxState) => Selector.Company.company(state, props.companyId || ''));

    const setCompanyHidden = () => {
        if (!company) {return;}

        Api.Command.generateBookingContract({
            companyId: company.id,
            email: 'noValid@e-mail.insecia',
            rerenderContract: true,
        });

        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <DialogTitle>Vertrag erneut generieren!</DialogTitle>
            <DialogContent>
                <Typography>
                    Sie Sie sicher, dass Sie den Vertrag für das Unternehmen {company?.name} neu generieren wollen?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button
                    children={'Neu generieren'}
                    variant={'contained'}
                    onClick={setCompanyHidden}
                />
            </DialogActions>
        </Dialog>
    );
};

export default SetCompanyHiddenDialog;
