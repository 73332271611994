import {createAction} from 'redux-actions';


const createTrustYouScoreEvent = <T>(type: string) => createAction<T>('_E/TY/' + type);

export interface TrustYouScoreSetPayload {
    trustYouId: string;
    score: number;
}

export interface TrustYouScoreUpdatePayload {
    trustYouId: string;
    score: number;
}


/* eslint-disable max-len */
const TrustYouScoreEvent = {
    trustYouScoreSet: createTrustYouScoreEvent<TrustYouScoreSetPayload>('TRUST_YOU_SCORE_SET'),
    trustYouScoreUpdated: createTrustYouScoreEvent<TrustYouScoreUpdatePayload>('TRUST_YOU_SCORE_UPDATED'),
};

export default TrustYouScoreEvent;
