import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

interface ImportMysteryTestConfirmationProps {
    open: boolean;
    type: string;
    onClose: () => void;
    onImport: () => void;
}

const ImportMysteryTestConfirmation = (props: ImportMysteryTestConfirmationProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                {props.type === 'hotel' ? 'MysteryTest-Hotel' :
                    props.type === 'restaurant' ? 'MysteryTest-Restaurant' : ''}
                &nbsp;Fragen überschreiben
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Möchten Sie die Fragen aus dem &nbsp;
                    {props.type === 'restaurant' ? 'MysteryTest-Hotel' :
                        props.type === 'hotel' ? 'MysteryTest-Restaurant' : ''}
                    &nbsp;wirklich übernehmen?
                </DialogContentText>
                <DialogContentText>
                    Dadurch werden alle Änderungen am
                    &nbsp;{props.type === 'hotel' ? 'MysteryTest-Hotel' :
                        props.type === 'restaurant' ? 'MysteryTest-Restaurant' : ''}
                    &nbsp;überschrieben.
                </DialogContentText>
                <br />
                <DialogContentText>
                    Abhängigkeiten werden nicht übernommen!
                </DialogContentText>
                <DialogContentText>
                    Ein erneutes Speichern ist erforderlich um die Änderungen durch den Import zu speichern.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button color={'primary'} onClick={props.onImport} variant={'contained'}>
                    Fragen Übernehmen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImportMysteryTestConfirmation;
