import {call, delay, fork, put, select, take} from 'redux-saga/effects';
import {Selector} from '../../selector';
import Command from '../../actions/command';
import Event from '../../actions/event';
import Keycloak from '../../../keycloak';

const KEEP_ALIVE_DELAY = 1_800_000; /* 5 minutes */

const onStartKeepAlive = function* () {
    const keepAliveRunning: boolean = yield select(Selector.User.keepAliveRunning);
    if (keepAliveRunning) {
        return; /* Prevent multiple simultaneous keep alive loops */
    }

    yield put(Event.User.keepAliveStarted({}));

    try {
        yield call(keepAlive);
    } catch (e) {
        yield put(Event.User.keepAliveStopped({}));
    }
};

const keepAlive = function* (): any {
    while (true) {
        yield delay(KEEP_ALIVE_DELAY);

        const keepAliveRunning: boolean = yield select(Selector.User.keepAliveRunning);
        if (!keepAliveRunning) {
            break;
        }

        yield call(Keycloak.refreshToken, KEEP_ALIVE_DELAY + 10_000);
    }
};

export function* startKeepAliveFlow() {
    while (true) {
        yield take(Command.User.startKeepAlive);
        yield fork(onStartKeepAlive);
    }
}
