import {createAction} from 'redux-actions';
import {SelfAssessment} from '../types/SelfAssessment';

const createSelfAssessmentEvent = <T>(type: string) => createAction<T>('_E/SA/' + type);

export interface SelfAssessmentStartedPayload {
    selfAssessmentId: string;
}

export interface SelfAssessmentFetchedPayload {
    selfAssessment: SelfAssessment;
}

export interface SelfAssessmentNotFoundPayload {
}

export interface SelfAssessmentQuestionAnsweredPayload {
    data: object;
}

export interface SelfAssessmentFinalizedPayload {
}

export interface SelfAssessmentReopenedPayload {
}

/* eslint-disable max-len */
const SelfAssessmentEvent = {
    stateCleared: createSelfAssessmentEvent<{}>('STATE_CLEARED'),
    selfAssessmentStarted: createSelfAssessmentEvent<SelfAssessmentStartedPayload>('SELF_ASSESSMENT_STARTED'),
    selfAssessmentFetched: createSelfAssessmentEvent<SelfAssessmentFetchedPayload>('SELF_ASSESSMENT_FETCHED'),
    selfAssessmentNotFound: createSelfAssessmentEvent<SelfAssessmentNotFoundPayload>('SELF_ASSESSMENT_NOT_FOUND'),
    selfAssessmentQuestionAnswered: createSelfAssessmentEvent<SelfAssessmentQuestionAnsweredPayload>('SELF_ASSESSMENT_QUESTION_ANSWERED'),
    selfAssessmentFinalized: createSelfAssessmentEvent<SelfAssessmentFinalizedPayload>('SELF_ASSESSMENT_FINALIZED'),
    selfAssessmentReopened: createSelfAssessmentEvent<SelfAssessmentReopenedPayload>('SELF_ASSESSMENT_REOPENED'),
};

export default SelfAssessmentEvent;
