import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

interface ReopenSelfAssessmentConfirmationProps {
    open: boolean;
    onClose: () => void;
    onReopen: () => void;
}

const ReopenSelfAssessmentConfirmation = (props: ReopenSelfAssessmentConfirmationProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Self-Assessment erneut öffnen
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Möchten Sie das Self-Assessment wirklich erneut öffnen? Die Daten können anschließend wieder
                    bearbeitet werden, bis das Self-Assessment erneut eingereicht wird.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button color={'primary'} onClick={props.onReopen} variant={'contained'}>
                    Erneut öffnen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReopenSelfAssessmentConfirmation;
