import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

interface NotAllRequiredQuestionsAnsweredInfoProps {
    open: boolean;
    onClose: () => void;
}

const NotAllRequiredQuestionsAnsweredInfo = (props: NotAllRequiredQuestionsAnsweredInfoProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Mystery-Test
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Um zur nächsten Kategorie zu gelangen, müssen Sie zuerst alle&nbsp;
                    'erforderlichen' Fragen beantwortet haben.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Zurück
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotAllRequiredQuestionsAnsweredInfo;
