import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {
    ImportMysteryTestQuestionsPayload,
} from '../../actions/questionListCommand';
import Event from '../../actions/event';

const onImportMysteryTestQuestionFlow = function*(data: ImportMysteryTestQuestionsPayload) {
    try {
        if (data.type !== 'hotel' && data.type !== 'restaurant') {
            yield put(Command.Notification.enqueueNotification({
                severity: 'error',
                message: 'Die Fragen Liste konnte nicht übernommen worden.',
            }));
            return;
        }
        const questionConfiguration: object =
            yield call(
                Api.Query.questionConfiguration,
                data.type === 'hotel' ? 'mysteryTestRestaurant' : 'mysteryTestHotel',
            );

        yield put(Event.QuestionList.mysteryTestImportQuestions(
            {type: data.type, data: questionConfiguration},
        ));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Die Fragen wurden erfolgreich übernommen.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Fragen Liste konnte nicht übernommen worden.',
        }));
    }
};

export function* importMysteryTestQuestionFlow() {
    while (true) {
        const action: Action<ImportMysteryTestQuestionsPayload> = yield take(
            Command.QuestionList.importMysteryTestQuestions.toString(),
        );

        yield fork(onImportMysteryTestQuestionFlow, action.payload);
    }
}
