import {WebsiteCheckQuestionConditionalType} from '../../data/website-check';
import {Box, TextField} from '@mui/material';

const styles = {
    root: {
        marginTop: 2,
    },
};

interface WebsiteCheckQuestionConditionalProps {
    conditional: WebsiteCheckQuestionConditionalType;
    value: string|undefined;
    onChange: (value: string) => void;
    disabled?: boolean;
}

const WebsiteCheckQuestionConditional = (props: WebsiteCheckQuestionConditionalProps) => {
    return (
        <Box sx={styles.root}>
            <TextField
                label={props.conditional.label}
                value={props.value || ''}
                onChange={event => props.onChange(event.target.value)}
                multiline={true}
                minRows={3}
                maxRows={5}
                fullWidth={true}
                disabled={props.disabled}
            />
        </Box>
    );
};

export default WebsiteCheckQuestionConditional;
