import {Box, Step, StepLabel, Stepper} from '@mui/material';
import {theme} from '../../mui/theme';

interface WebsiteCheckCategoriesProps {
    activeCategory: number;
    categories: string[];
}

const styles = {
    wrapper: {
        maxWidth: '400px',
    },
    step: {
        stepLabel: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    },
    questionnaire: {
        padding: 4,
        width: '100%',
    },
};

const WebsiteCheckCategories = (props: WebsiteCheckCategoriesProps) => {
    return (
        <Box sx={styles.wrapper}>
            <Stepper
                activeStep={props.activeCategory}
                orientation={'vertical'}
            >
                {props.categories.map(category => (
                    <Step key={category}>
                        <StepLabel>
                            <Box sx={styles.step.stepLabel}>
                                {category}
                            </Box>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default WebsiteCheckCategories;
