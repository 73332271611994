import {Box, Drawer, IconButton, List, Tooltip, Typography} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InfoIcon from '@mui/icons-material/Info';
import WebIcon from '@mui/icons-material/Web';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import SideBarItem from './SideBarItem';
import CompanySelector from './SideBar/CompanySelector';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../state/selector';
import Keycloak from '../keycloak';
import Config from '../util/config';
import Command from '../state/actions/command';

const styles = {
    drawer: (theme: any) => ({
        '& .MuiDrawer-paper': {
            width: 280,
            [theme.breakpoints.up('lg')]: {
                paddingTop: '64px',
            },
            boxSizing: 'border-box',
        },
    }),
    container: {
        padding: 1.5,
        paddingTop: 2.5,
        boxSizing: 'border-box',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    logoutContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: '16px',
    },
    item: {
        display: 'flex',
        paddingTop: 1,
        paddingBottom: 1,
        margin: '0 12px',
        width: 'calc(100% - 24px)',
    },
    icon: {
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        marginRight: 2,
        marginLeft: 1,
    },
    list: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    appInfoContainer: {
        paddingTop: 2,
    },
    appInfo: {
        margin: '0 auto',
        textAlign: 'center',
        fontSize: 10,
    },
};

interface SideBarProps {
    open: boolean;
    isLargeScreen: boolean;
    onClose: () => void;
}

const SideBar = (props: SideBarProps) => {
    const dispatch = useDispatch();
    const selectedCompany = useSelector(Selector.Company.selectedCompany);
    const isAdmin = useSelector(Selector.User.isAdmin);
    const hasNormalCompanyAccess = useSelector(Selector.User.hasNormalCompanyAccess);
    const hasTesterCompanyAccess = useSelector(Selector.User.hasTesterCompanyAccess);
    const hasAnyCompanyAccess = hasNormalCompanyAccess || hasTesterCompanyAccess;

    const enableMysteryTestHotel = selectedCompany?.testCategory === 'hotel';
    const enableMysteryTestRestaurant = (
        selectedCompany?.testCategory !== 'hotel'
        && selectedCompany?.testCategory !== null
    );

    return (
        <Drawer
            sx={styles.drawer}
            open={props.open || props.isLargeScreen}
            onClose={props.onClose}
            variant={props.isLargeScreen ? 'persistent' : 'temporary'}
            children={(
                <Box sx={styles.container}>
                    {!props.isLargeScreen && (
                        <Box sx={styles.logoutContainer}>
                            <Tooltip title={'Logout'}>
                                <IconButton
                                    color={'primary'}
                                    onClick={() => {
                                        dispatch(Command.User.stopKeepAlive({}));
                                        Keycloak.logout();
                                    }}
                                >
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    <List disablePadding={true} sx={styles.list}>
                        <Box>
                            {isAdmin && (
                                <SideBarItem url={'/dashboard'} label={'Dashboard'} icon={<DashboardIcon />} />
                            )}
                            <SideBarItem url={'/companies'} label={'Unternehmen'} icon={<AdminPanelSettingsIcon />} />

                            <CompanySelector />

                            {selectedCompany && (<>
                                {hasAnyCompanyAccess && (
                                    <SideBarItem
                                        url={`/companies/${selectedCompany.id}/base-data`}
                                        label={'Stammdaten'}
                                        icon={<TextSnippetIcon />}
                                    />
                                )}
                                {hasAnyCompanyAccess && (
                                    <SideBarItem
                                        url={`/companies/${selectedCompany.id}/self-assessment`}
                                        label={'Selbstauskunft'}
                                        icon={<InfoIcon />}
                                    />
                                )}
                                {(hasTesterCompanyAccess || isAdmin) && (
                                    <SideBarItem
                                        url={`/companies/${selectedCompany.id}/website-check`}
                                        label={'Webseiten Check'}
                                        icon={<WebIcon />}
                                    />
                                )}
                                {((hasTesterCompanyAccess || isAdmin) && enableMysteryTestHotel) && (
                                    <SideBarItem
                                        url={`/companies/${selectedCompany.id}/mysterytest-hotel`}
                                        label={'Mysterytest'}
                                        icon={<WebIcon />}
                                    />
                                )}
                                {((hasTesterCompanyAccess || isAdmin) && enableMysteryTestRestaurant) && (
                                    <SideBarItem
                                        url={`/companies/${selectedCompany.id}/mysterytest-restaurant`}
                                        label={'Mysterytest'}
                                        icon={<WebIcon />}
                                    />
                                )}
                            </>)}

                            {isAdmin && !selectedCompany && (
                                <SideBarItem
                                    url={'/questioneditor/mysterytest-hotel'}
                                    label={'Edit Mystery Test Hotel'}
                                    icon={<WebIcon />}
                                />
                            )}
                            {isAdmin && !selectedCompany && (
                                <SideBarItem
                                    url={'/questioneditor/mysterytest-restaurant'}
                                    label={'Edit Mystery Test Restaurant'}
                                    icon={<WebIcon />}
                                />
                            )}
                        </Box>
                        {isAdmin && (
                            <Box sx={styles.appInfoContainer}>
                                <Typography variant={'body2'} sx={styles.appInfo}>
                                    {Config.app.name} @ v{Config.app.version}<br />({Config.environment})
                                </Typography>
                            </Box>
                        )}
                    </List>
                </Box>
            )}
        />
    );
};

export default SideBar;
