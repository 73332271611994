import React, {useState} from 'react';
import {
    Box,
    Button,
    Card, CardContent, Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel, List, ListItem, Radio, RadioGroup,
    TextField, Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../state/actions/command';
import {useNavigate} from 'react-router';
import {v4 as uuidv4} from 'uuid';
import {Selector} from '../../state/selector';

const styles = {
    wrapperBox: {
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'auto',
    },
    inputFieldSpacing: {
        marginTop: 2.5,
    },
    zipInputField: {
        marginTop: 2.5,
        width: '140px',
    },
    cityInputField: {
        marginTop: 2.5,
        marginLeft: 2,
        width: 'calc(100% - 156px)',
    },
    sectionDivider: {
        marginTop: 4,
        marginBottom: 2.5,
    },
    firstSectionDivider: {
        marginTop: 0,
        marginBottom: 2.5,
    },
    saveButton: {
        marginTop: 2,
    },
    testCategories: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 2.5,
    },
    baseData: {
        marginTop: 2.5,
    },
    packetContainer: {
        marginTop: 2.5,
        ListItem: {
            paddingLeft: 1.5,
        },
    },
};

const TRAVEL_REGION_DATA: string[] = [
    'Prignitz',
    'Ruppiner Seenland',
    'Uckermark',
    'Barnimer Land',
    'Havelland',
    'Potsdam',
    'Seenland Oder-Spree',
    'Dahme-Seenland',
    'Fläming',
    'Cottbus/Spreewald',
    'Elbe-Elster-Land',
    'Lausitzer Seenland',
];

const RegisterPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userId = useSelector(Selector.User.userId);

    const [dataCompanyName, setDataCompanyName] = useState<string>('');
    const [dataRestaurantName, setDataRestaurantName] = useState<string>('');

    const [dataBillingStreet, setDataBillingStreet] = useState<string>('');
    const [dataBillingZip, setDataBillingZip] = useState<string>('');
    const [dataBillingCity, setDataBillingCity] = useState<string>('');

    const [dataContactPerson, setDataContactPerson] = useState<string>('');

    const [dataStreet, setDataStreet] = useState<string>('');
    const [dataZip, setDataZip] = useState<string>('');
    const [dataCity, setDataCity] = useState<string>('');
    const [dataPhone, setDataPhone] = useState<string>('');
    const [dataEmail, setDataEmail] = useState<string>('');
    const [dataWeb, setDataWeb] = useState<string>('');

    const [testCategory, setTestCategory] = useState<string|undefined>(undefined);
    const [travelRegion, setTravelRegion] = useState<string|undefined>(undefined);

    const [bookedQualityCheck, setBookedQualityCheck] = useState<boolean>(false);
    const [bookedMarketing, setBookedMarketing] = useState<boolean>(false);

    const registerCompany = () => {
        const companyId = uuidv4();
        const date = (new Date()).toLocaleDateString();

        dispatch(Command.Company.registerNewCompany({
            userId: userId,
            companyId: companyId,
            restaurantName: dataRestaurantName,
            companyName: dataCompanyName,
            billingStreet: dataBillingStreet,
            billingZip: dataBillingZip,
            billingCity: dataBillingCity,
            contactPerson: dataContactPerson,
            street: dataStreet,
            zip: dataZip,
            city: dataCity,
            phone: dataPhone,
            email: dataEmail,
            web: dataWeb,
            testCategory: testCategory!,
            hidden: false,
            travelRegion: travelRegion!,
            bookedQualityCheck: bookedQualityCheck,
            bookedQualityCheckDate: bookedQualityCheck ? date : '',
            bookedMarketing: bookedMarketing,
            bookedMarketingDate: bookedMarketing ? date : '',
        }));

        // TODO better handling for successfully or failed registration
        navigate('/companies');
    };

    const validInput =
        dataCompanyName.length > 0 &&
        dataRestaurantName.length > 0 &&
        dataContactPerson.length > 0 &&
        dataPhone.length > 0 &&
        dataEmail.length > 0 &&
        dataWeb.length > 0 &&
        dataCity.length > 0 &&
        dataStreet.length > 0 &&
        dataZip.length > 0 &&
        testCategory !== undefined &&
        travelRegion !== undefined &&
        bookedQualityCheck;

    return (
        <Box sx={styles.wrapperBox}>
            <Typography variant={'h1'}>
                Registrieren Sie ihr Unternehmen bei der Brandenburger Gastlichkeit
            </Typography>
            <Box>
                <Card sx={styles.baseData}>
                    <CardContent>
                        <Typography variant={'body1'} sx={styles.firstSectionDivider}>
                            Betrieb
                        </Typography>
                        <TextField
                            label={'Gastroname'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            value={dataRestaurantName}
                            onChange={event => setDataRestaurantName(event.target.value)}
                        />
                        <TextField
                            label={'Name des Betriebes (Betreiber)'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataCompanyName}
                            onChange={event => setDataCompanyName(event.target.value)}
                        />

                        <Typography variant={'body1'} sx={styles.sectionDivider}>
                            Rechnungsadresse
                        </Typography>
                        <TextField
                            label={'Straße'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            value={dataBillingStreet}
                            onChange={event => setDataBillingStreet(event.target.value)}
                        />
                        <TextField
                            label={'Postleitzahl'}
                            variant={'outlined'}
                            size={'small'}
                            sx={styles.zipInputField}
                            value={dataBillingZip}
                            onChange={event => setDataBillingZip(event.target.value)}
                        />
                        <TextField
                            label={'Stadt'}
                            variant={'outlined'}
                            size={'small'}
                            sx={styles.cityInputField}
                            value={dataBillingCity}
                            onChange={event => setDataBillingCity(event.target.value)}
                        />

                        <Typography variant={'body1'} sx={styles.sectionDivider}>
                            Kontaktdaten
                        </Typography>
                        <TextField
                            label={'Kontaktperson'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            value={dataContactPerson}
                            onChange={event => setDataContactPerson(event.target.value)}
                        />
                        <TextField
                            label={'Straße'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataStreet}
                            onChange={event => setDataStreet(event.target.value)}
                        />
                        <TextField
                            label={'Postleitzahl'}
                            variant={'outlined'}
                            size={'small'}
                            sx={styles.zipInputField}
                            value={dataZip}
                            onChange={event => setDataZip(event.target.value)}
                        />
                        <TextField
                            label={'Stadt'}
                            variant={'outlined'}
                            size={'small'}
                            sx={styles.cityInputField}
                            value={dataCity}
                            onChange={event => setDataCity(event.target.value)}
                        />
                        <TextField
                            label={'Telefon'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataPhone}
                            onChange={event => setDataPhone(event.target.value)}
                        />
                        <TextField
                            label={'E-Mail'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataEmail}
                            onChange={event => setDataEmail(event.target.value)}
                        />
                        <TextField
                            label={'Internet'}
                            variant={'outlined'}
                            fullWidth={true}
                            size={'small'}
                            sx={styles.inputFieldSpacing}
                            value={dataWeb}
                            onChange={event => setDataWeb(event.target.value)}
                        />
                    </CardContent>
                </Card>
                <Card sx={styles.testCategories}>
                    <CardContent>
                        <FormControl>
                            <FormLabel component={'legend'}>
                                <Typography variant={'body1'}>
                                    Ihre Testkategorie
                                </Typography>
                            </FormLabel>

                            <RadioGroup
                                value={testCategory}
                                onChange={event => setTestCategory(event.target.value)}
                                sx={styles.testCategories}
                            >
                                {/* Dependent on fe-dehoga-app/src/components/baseData/BaseData.tsx */}
                                <FormControlLabel
                                    value={'restaurant'}
                                    control={<Radio color={'primary'} />}
                                    label={'Restaurant'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'excursionRestaurant'}
                                    control={<Radio color={'primary'} />}
                                    label={'Ausflugslokal'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'cafe'}
                                    control={<Radio color={'primary'} />}
                                    label={'Café / Eisdiele'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'countryGuesthouse'}
                                    control={<Radio color={'primary'} />}
                                    label={'Landgasthof'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'pub'}
                                    control={<Radio color={'primary'} />}
                                    label={'Kneipe / Bar'}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={'hotel'}
                                    control={<Radio color={'primary'} />}
                                    label={'Hotel garni / Pension'}
                                    labelPlacement={'end'}
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card><Card sx={styles.testCategories}>
                    <CardContent>
                        <FormControl>
                            <FormLabel component={'legend'}>
                                <Typography variant={'body1'}>
                                    Ihre Region
                                </Typography>
                            </FormLabel>

                            <RadioGroup
                                value={travelRegion}
                                onChange={event => setTravelRegion(event.target.value)}
                                sx={styles.testCategories}
                            >
                                {/* Dependent on fe-dehoga-app travelRegions*/}
                                {TRAVEL_REGION_DATA.map(region => {
                                    return (
                                        <FormControlLabel
                                            value={region}
                                            control={<Radio color={'primary'} />}
                                            label={region}
                                            labelPlacement={'end'}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Card sx={styles.packetContainer}>
                    <CardContent>
                        <FormControl>
                            <FormLabel component={'legend'}>
                                <Typography variant={'h5'}>
                                    Der Paketpreis (je gewählter Kategorie) enthält folgende Leistungen:
                                </Typography>
                                <Typography variant={'body1'}>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Detaillierter Online-Qualitätscheck (Selbstauskunft, Mystery Check und
                                            digitale Reputation) mit schriftlicher Auswertung in der gewählten
                                            Kategorie
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Plakette zu betrieblichen Werbezwecken
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Begleitung der Aktion durch die Medien
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Onlineporträt auf der Website www.brandenburger-gastlichkeit.de
                                            (Adresseintrag, Imagetext, Bildergalerie)
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Teilnahme an der Aktion „Gastgeber des Jahres“
                                            *bei Erreichen der Mindestpunktzahl
                                        </ListItem>
                                    </List>
                                </Typography>
                            </FormLabel>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={bookedQualityCheck}
                                        onChange={event => setBookedQualityCheck(event.target.checked)}
                                        color={'primary'}
                                    />
                                }
                                label={
                                    <Typography variant={'h6'}>
                                        Hiermit buche ich das Paket (Laufzeit 2 Jahre entsprechend der
                                        Gültigkeit des Siegels)
                                        <Typography variant={'body1'}>
                                            <span style={{color: 'red'}}>275,00 €</span> (zzgl. 19 % MwSt.)
                                            für Mitglieder des DEHOGA Brandenburg <br />
                                            <span style={{color: 'red'}}>335,00 €</span> (zzgl. 19 % MwSt.)
                                            für Nichtmitglieder
                                        </Typography>
                                    </Typography>
                                }
                            />

                        </FormControl>
                    </CardContent>
                </Card>
                <Card sx={styles.packetContainer}>
                    <CardContent>
                        <FormControl>
                            <FormLabel component={'legend'}>
                                <Typography variant={'h5'}>
                                    Ergänzen Sie Ihre Zertifizierung um das TOP GASTGEBER-Marketingpaket 2023!
                                </Typography>
                                <Typography variant={'body1'}>
                                    Das Marketingpaket enthält folgende Leistungen:
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Ihr redaktionelles Hausporträt auf einer Startseite unserer thematischen
                                            Empfehlungen <br />
                                            (z.B. Brandenburgs besondere Orte)
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            alternativ Ihr redaktionelles Küchenchef-Porträt auf einer Startseite
                                            unserer Saison-Empfehlungen (z.B. Brandenburger Schlemmer-Sommer)
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Ihre saisonalen Empfehlungen
                                        </ListItem>
                                    </List>
                                </Typography>
                            </FormLabel>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={bookedMarketing}
                                        onChange={event => setBookedMarketing(event.target.checked)}
                                        color={'primary'}
                                    />
                                }
                                label={
                                    <Typography variant={'h6'}>
                                        Hiermit buche ich das Marketingpaket (Laufzeit 12 Monate)
                                        <Typography variant={'body1'}>
                                            <span style={{color: 'red'}}>375,00 €</span> (zzgl. 19 % MwSt.)
                                            für Mitglieder des DEHOGA Brandenburg <br />
                                            <span style={{color: 'red'}}>435,00 €</span> (zzgl. 19 % MwSt.)
                                            für Nichtmitglieder
                                        </Typography>
                                    </Typography>
                                }
                            />

                        </FormControl>
                    </CardContent>
                </Card>
                <Card sx={styles.packetContainer}>
                    <CardContent>
                        <Typography variant={'body1'}>
                            Die Teilnahmegebühr überweise ich nach Erhalt der Rechnung auf das Konto der GEHOGA
                            mbH, IBAN: DE03 1605 0000 3506 0028 39 bei der Mittelbrandenburgischen Sparkasse
                            (BIC: WELADED1PMB). Darüber hinaus verpflichte ich mich zur Einlösung des
                            beiliegenden Gutscheins und zum Ausfüllen der Online-Selbstauskunft für die
                            Durchführung des Qualitätschecks. Meine Mitarbeiter habe ich über diese Aktion und die
                            Einlösung des Gutscheins informiert. Ich bin darüber informiert, dass es sich bei diesem
                            Projekt um eine Werbekampagne handelt und der Rechtsweg in allen Belangen
                            ausgeschlossen ist. Der Test und die Auswertung der Ergebnisse erfolgen nach Eingang
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
            <Box style={{color: validInput ? 'black' : 'grey'}}>
                <br />
                <Typography variant={'h6'}>
                    Gesamtpreis:
                    <Typography variant={'body1'}>
                        <span style={{color: validInput ? 'red' : 'darkgray'}}>
                            {bookedQualityCheck ?
                                (bookedMarketing ? '650,00' : '275,00') :
                                (bookedMarketing ? '375,00' : '0,00')
                            } €
                        </span> (zzgl. 19 % MwSt.) für Mitglieder des DEHOGA Brandenburg <br />
                        <span style={{color: validInput ? 'red' : 'darkgray'}}>
                            {bookedQualityCheck ?
                                (bookedMarketing ? '870,00' : '335,00') :
                                (bookedMarketing ? '435,00' : '0,00')
                            } €
                        </span> (zzgl. 19 % MwSt.) für Nichtmitglieder
                    </Typography>
                </Typography>
            </Box>
            <Button
                variant={'contained'}
                children={'Zahlungspflichtig registrieren'}
                onClick={registerCompany}
                sx={styles.saveButton}
                disabled={!validInput}
            />
        </Box>
    );
};

export default RegisterPage;
