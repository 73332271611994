import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import React, {useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../state/actions/command';
import {Selector} from '../../state/selector';
import {ReduxState} from '../../state/reducer';

interface SetCompanyTrustYouScoreDialogProps {
    open: boolean;
    companyId?: string;
    companyStatus: any;
    version: number;
    onClose: () => void;
}

const validateTrustYouScore = (trustYouScore: number): boolean => {
    return !isNaN(trustYouScore) && 0 <= trustYouScore && trustYouScore <= 5;
};

const SetTrustYouScoreDialog = (props: SetCompanyTrustYouScoreDialogProps) => {
    const dispatch = useDispatch();
    const company = useSelector((state: ReduxState) => Selector.Company.company(state, props.companyId || ''));
    const [checkTrustYouScore, setCheckTrustYouScore] = useState<string>(
        props.companyStatus.trustYouScore![props.version] ?
            props.companyStatus.trustYouScore[props.version].score + '' : '0.0',
    );

    const trustYouScore = parseFloat(checkTrustYouScore.replace(',', '.'));
    const trustYouScoreValid = validateTrustYouScore(trustYouScore);

    const setTrustYouScore = () => {
        if (!trustYouScoreValid || !props.companyId) {
            return;
        }

        if (props.companyStatus.trustYouScore![props.version]) {
            dispatch(Command.TrustYou.updateTrustYouScore({
                trustYouScoreId: props.companyStatus.trustYouScore![props.version].trustYouId,
                companyId: props.companyId,
                score: trustYouScore,
            }));
        } else {
            dispatch(Command.TrustYou.setTrustYouScore({
                companyId: props.companyId,
                score: trustYouScore,
            }));
        }

        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <DialogTitle>TrustYou Score: "{company?.name}" </DialogTitle>
            <DialogContent>
                <Grid container={true} spacing={5}>

                    <Grid style={{marginTop: 'auto', marginBottom: 'auto'}} item={true} xs={3}>
                        <Box sx={{ marginTop: 1 }}>
                            <TextField
                                value={checkTrustYouScore}
                                onChange={event => setCheckTrustYouScore(event.target.value)}
                                variant={'outlined'}
                                sx={{ marginTop: 1 }}
                            />
                        </Box>
                    </Grid>

                    <Grid style={{marginTop: 'auto', marginBottom: 'auto'}} item={true} xs={5}>
                        <Typography variant={'body1'} component={'span'}>von 5.0</Typography>
                    </Grid>

                    <Grid style={{marginTop: 'auto', marginBottom: 'auto'}} item={true} xs={3}>
                        {trustYouScoreValid &&
                            <Typography variant={'h4'} component={'span'}>
                                {Math.round(100 * trustYouScore / 5)} %
                            </Typography>
                        }
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button
                    children={'Speichern'}
                    startIcon={<CheckIcon />}
                    variant={'contained'}
                    onClick={setTrustYouScore}
                    disabled={!trustYouScoreValid}
                />
            </DialogActions>
        </Dialog>
    );
};

export default SetTrustYouScoreDialog;
