import {Box, Chip, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ReopenMysteryTestConfirmation from './ReopenMysteryTestConfirmation';
import Command from '../../state/actions/command';
import {useState} from 'react';
import LatestTestDate from '../Admin/AdminPage/LatestTestDate';
import StartMysteryTestAttemptConfirmation from './StartMysteryTestAttemptConfirmation';

const styles = {
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 2,
    },
    statusChip: {
        marginLeft: 2,
    },
};

interface MysteryTestHeaderProps {
    title: string;
}

const MysteryTestHeader = (props: MysteryTestHeaderProps) => {
    const dispatch = useDispatch();
    const mysteryTestLoaded = useSelector(Selector.MysteryTest.loaded);
    const mysteryTestId = useSelector(Selector.MysteryTest.mysteryTestId);
    const mysteryTestSubmitted = useSelector(Selector.MysteryTest.submitted);
    const isAdmin = useSelector(Selector.User.isAdmin);
    const activeVersion = useSelector(Selector.QuestionList.activeVersion);
    const companyId = useSelector(Selector.Company.selectedCompanyId);
    const companyStatusMap = useSelector(Selector.Company.companyStatusMap);
    const [reopenConfirmationOpen, setReopenConfirmationOpen] = useState<boolean>(false);
    const [startMysteryTestAttemptOpen, setStartMysteryTestAttemptOpen] = useState<boolean>(false);

    const reopenMysteryTest = () => {
        dispatch(Command.MysteryTest.reopenMysteryTest({}));
        setReopenConfirmationOpen(false);
    };

    const startMysteryTestAttempt = () => {
        dispatch(Command.MysteryTest.startNewMysteryTestAttempt({}));
        setStartMysteryTestAttemptOpen(false);
    };

    return (
        <Box sx={styles.header}>
            <Typography variant={'h1'}>{props.title}</Typography>
            {(mysteryTestLoaded && mysteryTestId && mysteryTestSubmitted) && (
                <Chip
                    label={'abgeschlossen'}
                    size={'small'}
                    color={'primary'}
                    sx={styles.statusChip}
                    onClick={isAdmin ? () => setReopenConfirmationOpen(true) : undefined}
                    icon={isAdmin ? <EditIcon /> : undefined}
                />
            )}
            {(isAdmin && mysteryTestLoaded && mysteryTestId && companyId && companyStatusMap[companyId]) && (
                <>
                    <LatestTestDate data={companyStatusMap[companyId]?.mysteryTest?.[activeVersion]} useStyle={true} />
                </>
            )}
            {(isAdmin && mysteryTestLoaded && mysteryTestId && mysteryTestSubmitted) && (
                <Chip
                    label={'zurücksetzen'}
                    size={'small'}
                    color={'warning'}
                    sx={styles.statusChip}
                    onClick={() => setStartMysteryTestAttemptOpen(true)}
                    icon={<RestartAltIcon />}
                />
            )}
            <ReopenMysteryTestConfirmation
                open={reopenConfirmationOpen}
                onClose={() => setReopenConfirmationOpen(false)}
                onReopen={reopenMysteryTest}
            />
            <StartMysteryTestAttemptConfirmation
                open={startMysteryTestAttemptOpen}
                onClose={() => setStartMysteryTestAttemptOpen(false)}
                onStart={startMysteryTestAttempt}
            />
        </Box>
    );
};

export default MysteryTestHeader;
