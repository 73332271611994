import {Alert, Box, Button, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import Command from '../../state/actions/command';

const StartMysteryTestInfo = () => {
    const dispatch = useDispatch();

    const startMysteryTest = () => {
        dispatch(Command.MysteryTest.startMysteryTest({}));
    };

    return (
        <Box>
            <Alert severity={'info'}>
                Im Mystery Check wird vor Ort durch Mystery Tester die Servicekette und die Servicequalität überprüft
                und auch mit den Informationen aus der Selbstauskunft und den Informationen aus dem
                Website-Check verglichen.
            </Alert>
            <Typography variant={'body2'} sx={{ marginTop: 3, marginBottom: 3 }}>
                Ihr Arbeitsstand wird zwischengespeichert.
            </Typography>
            <Button variant={'contained'} onClick={startMysteryTest}>
                Mystery Test beginnen
            </Button>
        </Box>
    );
};

export default StartMysteryTestInfo;
