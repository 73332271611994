import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {FinalizeWebsiteCheckPayload} from '../../actions/websiteCheckCommand';
import {Selector} from '../../selector';

const onFinalizeWebsiteCheck = function*(data: FinalizeWebsiteCheckPayload) {
    const websiteCheckId: string|null = yield select(Selector.WebsiteCheck.websiteCheckId);

    if (!websiteCheckId) {
        return;
    }

    try {
        yield call(Api.Command.finalizeWebsiteCheck, {
            websiteCheckId,
        });

        yield put(Event.WebsiteCheck.websiteCheckFinalized({}));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Website-Check konnte nicht finalisiert werden.',
        }));
    }
};

export function* finalizeWebsiteCheckFlow() {
    while (true) {
        const action: Action<FinalizeWebsiteCheckPayload> = yield take(
            Command.WebsiteCheck.finalizeWebsiteCheck.toString(),
        );

        yield fork(onFinalizeWebsiteCheck, action.payload);
    }
}
