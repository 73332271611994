import {AppBar, Box, IconButton, Toolbar, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import Keycloak from '../keycloak';
import Command from '../state/actions/command';
import {useDispatch} from 'react-redux';

const styles = {
    appBar: (theme: any) => ({
        [theme.breakpoints.up('lg')]: {
            zIndex: 2000,
        },
        boxShadow: 'none',
    }),
    toolbar: {
        justifyContent: 'space-between',
    },
    logo: {
        height: '100%',
        padding: '16px 0',
        boxSizing: 'border-box' as const,
    },
    profileContainer: {
        display: 'flex',
        padding: 2,
        marginRight: 1,
        alignItems: 'center',
    },
    profilePicture: {
        width: '48px',
        height: '48px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    profileText: {
        textAlign: 'right',
        paddingRight: 1.5,
    },
};

interface TopBarProps {
    isLargeScreen?: boolean;
    onOpenSideBar?: () => void;
}

const TopBar = (props: TopBarProps) => {
    const dispatch = useDispatch();

    return (
        <AppBar position={'fixed'} color={'primary'} sx={styles.appBar}>
            <Toolbar sx={styles.toolbar}>
                <img src={'/dehoga-logo.svg'} alt={'DEHOGA logo'} />
                {(!props.isLargeScreen) && (
                    <IconButton color={'secondary'} onClick={props.onOpenSideBar}>
                        <MenuIcon />
                    </IconButton>
                )}
                {(props.isLargeScreen) && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={'Logout'}>
                            <IconButton
                                color={'secondary'}
                                onClick={() => {
                                    dispatch(Command.User.stopKeepAlive({}));
                                    Keycloak.logout();
                                }}
                            >
                                <LogoutIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
